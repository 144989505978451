import _ from 'lodash';
import { LoadingProps } from 'interfaces/ui';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
export interface Audience {
  id?: string;
  name?: string;
  groupId?: number;
  totalSize?: number;
  createdAt?: number;
  updatedAt?: number;
  totalcount?: number;
  lal_model_type: string;
  inHouseModelingStatus?: any;
  lalAudienceCount?: any;
}

export interface AudienceGroup {
  updated_at: any;
  id: string;
  name: string;
  audiences: Audience['id'][];
}

interface AudienceState {
  currentAudience?: Audience;
  ui: {
    audienceLoading: LoadingProps;
    audienceSaving: LoadingProps;
    notifications: Array<LoadingProps>;
  };
}

const initialState: AudienceState = {
  ui: {
    audienceLoading: { state: 'idle' },
    audienceSaving: { state: 'idle' },
    notifications: [],
  },
};

export const audienceSlice = createSlice({
  name: 'audience',
  initialState,
  reducers: {
    addAudience: (state, action: PayloadAction<Audience>) => {
      state.currentAudience = action.payload;
    },
    clearCurrentCustomAudience: (state) => {
      state.currentAudience = undefined;
    },
    exportAudience: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    saveCustomAudience: () => {
      // skeleton
    },
    updateCurrentCustomAudience: (state, action: PayloadAction<Audience>) => {
      state.currentAudience = action.payload;
    },
    fetchCustomAudience: (state, action: PayloadAction<Audience>) => {
      // skeleton
    },
    setAudienceLoadingState: (state, action: PayloadAction<LoadingProps>) => {
      state.ui.audienceLoading = action.payload;
    },
    addNotification: (state, action: PayloadAction<LoadingProps>) => {
      state.ui.notifications.push(action.payload);
    },
  },
});

export const {
  addAudience,
  clearCurrentCustomAudience,
  exportAudience,
  fetchCustomAudience,
  saveCustomAudience,
  setAudienceLoadingState,
  updateCurrentCustomAudience,
  addNotification,
} = audienceSlice.actions;

export default audienceSlice.reducer;
