import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { PlacementLabelId } from 'pages/offerWallManager/offerOptions/placementId';

interface PlacementStepperProps {
  placements: string[] | number[];
  renderPlacementPreview: (placementId: number) => JSX.Element;
}

const PlacementStepper: React.FC<PlacementStepperProps> = ({
  placements,
  renderPlacementPreview,
}) => {
  const [activeStep, setActiveStep] = useState(1);
  const maxSteps = placements.length;

  useEffect(() => {
    setActiveStep(0);
  }, [placements?.length]);

  const handleNext = () => {
    setActiveStep((prevStep) => Math.min(prevStep + 1, maxSteps - 1));
  };

  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const placementType = PlacementLabelId[placements[activeStep]];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div>
        {renderPlacementPreview &&
          renderPlacementPreview(placements[activeStep])}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
          </Button>
        </div>
        <div>
          <span>{placementType}</span>
        </div>
        <div>
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            <KeyboardArrowRight />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PlacementStepper;
