import { Offer } from 'pages/offerWallManager/slice';
import { OfferFieldValidationMessage } from '../constants';
function isOfferDisabled(offer: Offer) {
  const {
    offername,
    description,
    logourl,
    ctatext,
    headline,
    backgroundimgurl,
    comparisontext,
    comparisonemoji,
    label,
    fullscreenimageurl,
    category,
  } = offer;

  const errors = [];

  // Validate common fields
  if (!label) errors.push('Offer needs a label');
  if (!logourl) errors.push('Offer does not have a logo');

  const placementIds = Array.isArray(offer.placementid)
    ? offer.placementid
    : [offer.placementid ?? '1']; // Default to '1' if undefined

  // Define required fields for each placement type
  const placementValidations = {
    '1': [
      { field: offername, message: OfferFieldValidationMessage.OFFER_NAME },
      {
        field: backgroundimgurl,
        message: OfferFieldValidationMessage.BG_IMAGE_URL,
      },
      { field: headline, message: OfferFieldValidationMessage.HEADLINE },
      { field: description, message: OfferFieldValidationMessage.BODY },
      { field: ctatext, message: OfferFieldValidationMessage.CTA_TEXT },
      { field: headline, message: OfferFieldValidationMessage.SUBLINE },
      {
        field: comparisonemoji ? comparisontext : description,
        message: comparisonemoji
          ? OfferFieldValidationMessage.COM_TEXT
          : OfferFieldValidationMessage.DESCRIPTION,
      },
      {
        field: fullscreenimageurl,
        message: OfferFieldValidationMessage.FULL_SCREEN_IMAGE_URL,
      },
      {
        field: category,
        fieldName: 'category',
        message: OfferFieldValidationMessage.CATEGORY,
      },
    ],
    '2': [
      { field: offername, message: OfferFieldValidationMessage.OFFER_NAME },
      { field: headline, message: OfferFieldValidationMessage.SUBLINE },
      {
        field: backgroundimgurl,
        message: OfferFieldValidationMessage.BG_IMAGE_URL,
      },
      {
        field: comparisonemoji ? comparisontext : description,
        message: comparisonemoji
          ? OfferFieldValidationMessage.COM_TEXT
          : OfferFieldValidationMessage.DESCRIPTION,
      },
      { field: ctatext, message: OfferFieldValidationMessage.CTA_TEXT },
    ],
    '3': [
      { field: headline, message: OfferFieldValidationMessage.SUBLINE },
      { field: description, message: OfferFieldValidationMessage.DESCRIPTION },
      { field: ctatext, message: OfferFieldValidationMessage.CTA_TEXT },
    ],
    '4': [
      {
        field: backgroundimgurl,
        message: OfferFieldValidationMessage.BG_IMAGE_URL,
      },
      { field: headline, message: OfferFieldValidationMessage.SUBLINE },
      { field: offername, message: OfferFieldValidationMessage.HEADLINE },
      { field: description, message: OfferFieldValidationMessage.BODY },
      { field: ctatext, message: OfferFieldValidationMessage.CTA_TEXT },
    ],
  };

  // Validate fields for all given placements
  let validPlacement = false;

  placementIds.forEach((id) => {
    if (placementValidations[id]) {
      validPlacement = true;

      placementValidations[id].forEach(({ field, fieldName, message }) => {
        if (
          fieldName == 'category' &&
          Array.isArray(field) &&
          field.length === 0
        ) {
          errors.push(message);
        } else if (!field) errors.push(message);
      });
    }
  });

  // If no valid placement was found, add an error
  if (!validPlacement) {
    errors.push('Not a valid placement');
  }

  return errors.length > 0;
}

export default isOfferDisabled;
