/**
 * List of Offer categories
 */
export const OfferCategories = {
  1: 'Games',
  2: 'Finance',
  3: 'Lifestyle',
  4: 'Entertainment',
  5: 'Education',
  6: 'Food and Beverage',
  7: 'News',
  8: 'Services',
  9: 'Surveys',
  10: 'Link to App Store',
};
