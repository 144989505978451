import { Chip, Dialog, Grid, Switch } from '@klover/attain-design-system';
import { useEffect, useMemo, useState } from 'react';

// Material UI Components
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import FilterPopup from './FilterPopup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import ReactJson from 'react-json-view';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@material-ui/core/Typography';
import useChangeLogs from '../../graphql/hooks/useChangeLogs';
import {
  CHANGE_LOG,
  CHANGE_LOG_CHANGED_BY,
  CHANGE_LOG_CHANGED_FIELDS,
  CHANGE_LOG_DATE,
  FIELD_NAME,
  JSON_VIEW,
  NEW_VALUE,
  NO_DATA,
  OLD_VALUE,
} from '../../content';
import { CHANGE_LOG_ARRAY_FIELDS } from '../../constants/index';
import { CaretUp, ListMagnifyingGlass } from '@phosphor-icons/react';
import GhostLoader from 'react-ghost-loader';
import { Differ, Viewer } from 'json-diff-kit';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  convertToDateTime,
  getChangedFields,
  getFieldMapping,
} from './changeLogUtils';
import 'json-diff-kit/dist/viewer.css';
import { isJsonString } from 'utils/normalizedObject';

/**
 * Define custom styles using makeStyles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close: {
      height: 24,
      width: 24,
    },
    columnTitles: {
      color: 'black',
      display: 'flex',
      fontSize: 18,
      padding: 5,
      paddingBottom: 0,
      width: '95%',
      fontFamily: 'Calibre-Regular,sans-serif',
    },
    header: {
      padding: '10px 12px 10px 24px',
    },
    noLogs: {
      display: 'flex',
      justifyContent: 'center',
      color: 'black',
      fontSize: '22px',
      fontWeight: 500,
    },
    root: {
      margin: theme.spacing(3),
    },
    lineDivider: {
      margin: '2px 0px',
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
    },
    titleText: {
      fontFamily: 'Calibre-Semibold,sans-serif',
      fontWeight: 600,
      fontSize: 22,
      lineHeight: '32px',
    },
    titleDate: {
      paddingLeft: 33,
      fontWeight: 400,
    },
    titleChangedBy: {
      paddingLeft: 29,
    },
    titleChangedFields: {
      paddingLeft: 16,
    },
    histroryTitleContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 16,
      padding: '5px 24px 5px 0px',
      paddingBottom: 0,
      fontFamily: 'Calibre-Regular,sans-serif',
    },
    histroryDetailContainer: {
      marginTop: '10px',
      display: 'flex',
      marginLeft: '18px',
      marginRight: '18px',
    },
    changeLogDateTime: {
      marginLeft: '25px',
    },
    dialogContent: {
      margin: '24px 0px 4px 24px',
      overflowY: 'auto',
      paddingRight: '24px',
      paddingBottom: '8px',
    },
    jsonViewText: {
      fontFamily: 'Calibre-Regular,sans-serif',
      fontWeight: 500,
      fontSize: 20,
      lineHeight: '32px',
    },
    chip: {
      fontFamily: 'Calibre-Regular,sans-serif',
      fontWeight: 600,
      fontSize: 16,
      background: '#E9F2FF',
    },
    tableHeader: {
      fontFamily: 'Calibre-SemiBold,sans-serif',
      fontWeight: 600,
      fontSize: 16,
      background: '#E9F2FF',
    },
  })
);

/**
 * ChangeLogDialog component definition
 */
function ChangeLogDialog(props: any) {
  const { isOpen = false, setOpenDialog, systemName } = props || {};
  let { entityId } = props || {};
  if (!entityId) {
    const currentUrl = window.location.href;
    entityId = currentUrl.split('/').pop();
  }
  const classes = useStyles();
  const [isJsonViewEnabled, setIsJsonViewEnabled] = useState(false);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const {
    loading: changeLogLoading,
    error,
    changeLogs,
    refetch,
  } = useChangeLogs({
    entityId: entityId,
    systemName: systemName,
    changedFields: [],
  });

  useEffect(() => {
    refetch();
  }, [isOpen]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const updateFilters = (data: string[]) => {
    setCheckedItems(data);
  };

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="lg"
      onClose={handleClose}
      open={isOpen}
      disableEnforceFocus
      headerID="changelogHeader"
      header={
        <Grid container alignItems="center">
          <Grid item xs={9}>
            <Title />
          </Grid>
          <Grid item container justifyContent="flex-end" xs={2}>
            <FormControlLabel
              checked={isJsonViewEnabled}
              control={<Switch color="primary" />}
              label={<span className={classes.jsonViewText}>{JSON_VIEW}</span>}
              labelPlacement="start"
              onChange={() => setIsJsonViewEnabled(!isJsonViewEnabled)}
            />
          </Grid>
        </Grid>
      }
      bodyID="changelogBody"
      body={
        <ChangeLogWithDetails
          changeLogs={changeLogs}
          changeLogLoading={changeLogLoading}
          isJsonViewEnabled={isJsonViewEnabled}
          checkedItems={checkedItems}
          setCheckedItems={updateFilters}
          systemName={systemName}
        />
      }
    />
  );
}

/**
 * Change Log History component defination to define individual UI of Change Log History
 */
const ChangeLogHistory = (props: any) => {
  const { changeLogs, itemIndex, isJsonViewEnabled, systemName } = props || {};
  const classes = useStyles();
  const fieldMapping = getFieldMapping(systemName);
  const tableDetails = getChangedFields(
    changeLogs.previousData,
    changeLogs.newData,
    changeLogs.changedFields
  );
  const differ = new Differ({
    detectCircular: true, // default `true`
    maxDepth: Infinity, // default `Infinity`
    showModifications: true, // default `true`
    arrayDiffMethod: 'lcs', // default `"normal"`, but `"lcs"` may be more useful
  });
  const diff = differ.diff(
    JSON.parse(changeLogs.previousData),
    JSON.parse(changeLogs.newData)
  );
  return (
    <Accordion key={`accordion_${itemIndex}`}>
      <AccordionSummary
        expandIcon={<CaretUp size={24} />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Grid className={classes.histroryTitleContainer} xs={12}>
          <Grid item xs={2} className={classes.changeLogDateTime}>
            {convertToDateTime(changeLogs.createdAt, true)}
          </Grid>
          <Grid item xs={4}>
            {changeLogs.changedByEmail}
          </Grid>
          <Grid item xs={6}>
            {changeLogs.changedFields
              .map(
                (fieldName: string) =>
                  fieldMapping[fieldName as keyof typeof fieldMapping]
              )
              .join(', ')}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <>
          <Grid className={classes.histroryDetailContainer}>
            <Grid item xs={12}>
              {tableDetails.length > 0 && !isJsonViewEnabled && (
                <ChangeLogDetailTable
                  details={tableDetails}
                  labelXS={8}
                  valueXS={4}
                  type="change_details"
                  fieldMapping={fieldMapping}
                />
              )}
              {isJsonViewEnabled && (
                <Viewer
                  diff={diff}
                  indent={4}
                  lineNumbers={true}
                  highlightInlineDiff={true}
                  inlineDiffOptions={{
                    mode: 'char',
                    wordSeparator: ' ',
                  }}
                />
              )}
            </Grid>
          </Grid>
        </>
      </AccordionDetails>
    </Accordion>
  );
};

/**
 * ChangeLogDetailTable component defination to show changed value in table
 */
const ChangeLogDetailTable = (props: any) => {
  const classes = useStyles();
  const { details, fieldMapping } = props || {};

  const fetchFormattedData = (row, value = '{}') => {
    if (CHANGE_LOG_ARRAY_FIELDS.includes(row.field)) {
      if (isJsonString(value)) {
        return value ? JSON.parse(value) : [];
      } else {
        return value ? [value] : [];
      }
    }

    return value;
  };
  return (
    <TableContainer
      component={Paper}
      style={{
        background: 'rgba(13, 8, 135, 0.02)',
        fontFamily: 'Calibre-Regular,sans-serif',
        fontSize: 16,
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="Change log table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>{FIELD_NAME}</TableCell>
            <TableCell className={classes.tableHeader}>{OLD_VALUE}</TableCell>
            <TableCell className={classes.tableHeader}>{NEW_VALUE}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {details.map((row: any) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                td: { color: 'grey' },
              }}
            >
              <TableCell style={{ verticalAlign: 'top' }}>
                {fieldMapping[row.field as keyof typeof fieldMapping]}
              </TableCell>
              <TableCell>
                {CHANGE_LOG_ARRAY_FIELDS.includes(row.field) ? (
                  <ReactJson
                    src={fetchFormattedData(row, row.previousValue)}
                    name={null}
                    enableClipboard={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                  />
                ) : (
                  row.previousValue
                )}
              </TableCell>
              <TableCell>
                {CHANGE_LOG_ARRAY_FIELDS.includes(row.field) ? (
                  <ReactJson
                    src={fetchFormattedData(row, row.newValue)}
                    name={null}
                    enableClipboard={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                  />
                ) : (
                  row.newValue
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

/**
 * Title component defination to show Title of modal
 */
const Title = (props: any) => {
  const classes = useStyles();

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid item className={classes.title}>
        <ListMagnifyingGlass size={24} />
        <Typography component="h2" className={classes.titleText}>
          {CHANGE_LOG}
        </Typography>
      </Grid>
    </Grid>
  );
};

function filterItems(changeLogs: any, changedFields: any) {
  return changeLogs?.filter((item: any) =>
    item.changedFields.some((element: string) =>
      changedFields.includes(element)
    )
  );
}

const ChangeLogWithDetails = (props: any) => {
  const classes = useStyles();
  const {
    changeLogs,
    changeLogLoading,
    isJsonViewEnabled,
    checkedItems,
    setCheckedItems,
    systemName,
  } = props || {};
  const [filteredChangeLogs, setFilteredChangeLogs] = useState(
    filterItems(changeLogs, checkedItems)
  );
  useMemo(() => {
    if (checkedItems.length > 0) {
      setFilteredChangeLogs(filterItems(changeLogs, checkedItems));
    } else {
      setFilteredChangeLogs(changeLogs);
    }
  }, [checkedItems, changeLogs]);
  const isChangeLogData = filteredChangeLogs?.length > 0;
  const fieldMapping = getFieldMapping(systemName);

  const header = (
    <Grid className={classes.columnTitles} xs={12}>
      <Grid item xs={2} className={classes.titleDate}>
        {CHANGE_LOG_DATE}
      </Grid>
      <Grid item xs={4} className={classes.titleChangedBy}>
        {CHANGE_LOG_CHANGED_BY}
      </Grid>
      <Grid item xs={6} className={classes.titleChangedFields}>
        {CHANGE_LOG_CHANGED_FIELDS}
        <FilterPopup
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          systemName={systemName}
          fieldMapping={fieldMapping}
        />
        {checkedItems.length > 0 && (
          <span>
            <Chip
              label={checkedItems.length + ' filters applied'}
              className={classes.chip}
              onDelete={() => setCheckedItems([])}
            />
          </span>
        )}
      </Grid>
    </Grid>
  );

  return (
    <Box className={classes.dialogContent}>
      {header}
      <Divider className={classes.lineDivider} />
      <div style={{ marginLeft: 1 }}>
        {changeLogLoading ? (
          <Box
            display="flex"
            flexDirection="column"
            style={{ paddingTop: 10, gap: 5 }}
          >
            <GhostLoader
              height="48px"
              heightRandom={0}
              width="100%"
              widthRandom={0}
            />
            <Box display="flex" style={{ gap: 5 }}>
              <GhostLoader
                height="180px"
                heightRandom={0}
                width="20%"
                widthRandom={0}
              />
              <GhostLoader
                height="180px"
                heightRandom={0}
                width="40%"
                widthRandom={0}
              />
              <GhostLoader
                height="180px"
                heightRandom={0}
                width="100%"
                widthRandom={0}
              />
            </Box>
            <GhostLoader
              height="48px"
              heightRandom={0}
              width="100%"
              widthRandom={0}
            />
          </Box>
        ) : (
          <>
            {!isChangeLogData && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ padding: '120px' }}
              >
                <h3 className={classes.noLogs}>{NO_DATA}</h3>
              </Box>
            )}
            {filteredChangeLogs?.map((logItem: any, i: number) => (
              <ChangeLogHistory
                changeLogs={logItem}
                itemIndex={i}
                key={`history_${i}`}
                isJsonViewEnabled={isJsonViewEnabled}
                systemName={systemName}
              />
            ))}
          </>
        )}
      </div>
    </Box>
  );
};

export default ChangeLogDialog;
