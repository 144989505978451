import styled from 'styled-components';
import { Grid, Typography, theme } from '@klover/attain-design-system';

export const InformationalText = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  color: red;
  margin-bottom: 8px;
`;

export const CardHeader = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xxs};
  width: 100%;
`;

export const CardTitle = styled.h2`
  ${theme.typography.headingSM};
  flex-grow: 1;
`;

export const StyledBodyContainer = styled(Grid)`
  span {
    ${theme.typography.headingXS};
  }
`;

export const BodyHeader = styled.div`
  ${theme.typography.headingXS};
  margin-bottom: ${theme.spacing.xs};
`;

export const BodyText = styled.p`
  ${theme.typography.bodyLG};
  color: ${theme.colors.text};
  margin-top: 0;
`;

export const ModelType = styled.div`
  position: absolute;
  left: 240px;
  top: 14px;
`;
