import Chip from '@material-ui/core/Chip';
import React from 'react';
import { Autocomplete, TextFieldProps } from '@klover/attain-design-system';
import { CaretDown } from '@phosphor-icons/react';
import { nanoid } from 'nanoid';
import * as Styled from './index.styles';

interface Option {
  id: number;
  label: string;
}

interface MultiSelectAutocompleteProps {
  options: Option[];
  value: Option[];
  onChange: (value: number[]) => void;
  placeholder: string;
  deleteSingleItem: Boolean;
}

const MultiSelectAutocomplete: React.FC<MultiSelectAutocompleteProps> = ({
  options,
  value,
  onChange,
  placeholder,
  deleteSingleItem = true,
}) => {
  return (
    <Autocomplete
      multiple
      id={nanoid()}
      limitTags={2}
      options={options}
      getOptionLabel={(option) => option.label}
      value={value || []}
      isOptionEqualToValue={(option, value) => option.id == value.id}
      onChange={(_, value) => {
        if (value.length === 0 && !deleteSingleItem) {
          return; // Prevent deletion if it's the last item
        }
        const values = value?.map((x) => x.id);
        onChange(values);
      }}
      popupIcon={<CaretDown />}
      style={{ width: '100%' }}
      renderInput={(params) => (
        <TextFieldSearch
          {...params}
          placeholder={placeholder}
          variant="outlined"
        />
      )}
      renderTags={(value, getTagProps) => {
        const numTags = value.length;
        const limitTags = 2;

        return (
          <>
            {value.slice(0, limitTags).map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={option.id}
                label={option.label}
              />
            ))}
            {numTags > limitTags && ` +${numTags - limitTags}`}
          </>
        );
      }}
    />
  );
};

export const TextFieldSearch = ({ ...props }: TextFieldProps) => {
  return (
    <Styled.Wrapper
      {...props}
      InputProps={{
        ...props.InputProps,
      }}
    />
  );
};
export default MultiSelectAutocomplete;
