import styled from 'styled-components';
import { TextField, theme } from '@klover/attain-design-system';

export const Wrapper = styled(TextField)`
  && {
    .MuiInputBase-input {
      padding-inline-start: ${theme.spacing.sm};
    }
  }
`;
