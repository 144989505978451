import moment from 'moment';
import { CHANGE_LOG_BOOLEAN_FIELDS, CHANGE_LOG_TIMESTAMP_FIELDS, OFFER_FIELD_MAPPING, QUESTION_FIELD_MAPPING, AUDIENCE_MANAGER_FIELD_MAPPING, CHANGE_LOG_ARRAY_FIELDS, SYSTEM_NAME } from '../../constants/index';
import { OfferCategories } from 'pages/offerWallManager/offerOptions/offerGoals';

/**
 * Convert current createdAt to specific 'MM/DD/YYYY HH:mm A' format along with split (date-time) functionality
 * @param createdAt : string
 * @param isSplitTime : boolean
 * @returns : render object
 */
export const convertToDateTime = (createdAt: string, isSplitTime = false) => {
  if (!createdAt) return '';
  const utcMoment = moment.utc(createdAt);
  const localMoment = utcMoment.local();
  const dateTime = moment(localMoment).format('MM/DD/YYYY HH:mm A');
  if (dateTime) {
    if (!isSplitTime) return dateTime;
    const splitedTime = dateTime.split(' ');
    return (
      <>
        <span>{splitedTime[0]}</span>
        <br />
        <span>{`${splitedTime[1]} ${splitedTime[2]}`}</span>
      </>
    );
  }

  return '';
};

const toPascalCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

const PlacementId: { [key: string]: string } = {
  '1': 'Offer Wall',
  '2': 'Dashboard',
  '3': 'Klover Plus',
  '4': 'On Boarding',
};

const RevenueConversionEvents: { [key: string]: string } = {
  CLICK_THROUGH: 'Click Through',
  ACTION_CONV: 'Action Conversion',
  SUCCESS_URL: 'Success Url',
  IMPRESSION: 'Impression',
};

const PricingModel: { [key: string]: string } = {
  CPA: 'CPA',
  CPC: 'CPC',
  CPM: 'CPM',
  FLAT_FEE: 'Flat Fee',
};

const KloverPointsConversionEvents: { [key: string]: string } = {
  CLICK_THROUGH: 'Click',
  ACTION_CONV: 'Postback Event',
  SUCCESS_URL: 'Success Url',
};

const AnswerType: { [key: string]: string } = {
  SINGLE: 'Single',
  MULTI: 'Multiple',
};

const formatBoolean = (value: any) => (value === 1 || value === true ? "Yes" : "No");
const formatDate = (value: any) => (value ? new Date(value).toLocaleDateString() : "N/A");
const formatArray = (value: any) => (Array.isArray(value) ? JSON.stringify(value, null, 2) : value);
const formatAudienceType = (value: string) => (value === 'CUSTOM' ? "Custom Audience" : "Audience");
const formatDevices = (value: any) => (!value ? "All Devices" : toPascalCase(value));

const prepareChangedFields = (previousData: any, newData: any, changedFields: any) => {
  try {
    return changedFields.map((field: any) => {
      let previousValue, newValue;

      switch (true) {
        case CHANGE_LOG_BOOLEAN_FIELDS.includes(field):
          previousValue = formatBoolean(previousData[field]);
          newValue = formatBoolean(newData[field]);
          break;
        case CHANGE_LOG_TIMESTAMP_FIELDS.includes(field):
          previousValue = formatDate(previousData[field]);
          newValue = formatDate(newData[field]);
          break;
        case field === 'audience_type':
          previousValue = formatAudienceType(previousData[field]);
          newValue = formatAudienceType(newData[field]);
          break;
        case field === 'targetting_devices':
          previousValue = formatDevices(previousData[field]);
          newValue = formatDevices(newData[field]);
          break;
        case field === 'placement': {
          const mappedPlacements = (placements) => {
            if (Array.isArray(placements)) {
              return JSON.stringify(placements?.map((x) => PlacementId[x]));
            } else {
              return PlacementId[placements];
            }
          };
          previousValue = mappedPlacements(previousData[field]);
          newValue = mappedPlacements(newData[field]);
          break;
        }
        case field === 'pricing_model':
          previousValue = PricingModel[previousData[field]] || "N/A";
          newValue = PricingModel[newData[field]] || "N/A";
          break;
        case field === 'revenue_conversion_event':
          previousValue = RevenueConversionEvents[previousData[field]] || "N/A";
          newValue = RevenueConversionEvents[newData[field]] || "N/A";
          break;
        case field === 'klover_points_conversion_event':
          previousValue = KloverPointsConversionEvents[previousData[field]] || "N/A";
          newValue = KloverPointsConversionEvents[newData[field]] || "N/A";
          break;
        case field === 'answer_type':
          previousValue = AnswerType[previousData[field]];
          newValue = AnswerType[newData[field]];
          break;
        case field === 'category':
          {
            const mappedCategories = (categories) => {
              if (Array.isArray(categories)) {
                return JSON.stringify(
                  categories?.map((x) => OfferCategories[x])
                );
              } else {
                return OfferCategories[categories];
              }
            };

            previousValue = mappedCategories(previousData[field]);
            newValue = mappedCategories(newData[field]);
            break;
          }
          break;
        default:
          previousValue = formatArray(previousData[field]);
          newValue = formatArray(newData[field]);
      }

      return { field, previousValue, newValue };
    });
  } catch (e) {
    console.log('error in prepareChangedFields:', e);
    throw e;
  }
};


export const getChangedFields = (previousData: string, newData: string, changedFields: any) => {
  try {
    const prevObj = JSON.parse(previousData);
    const newObj = JSON.parse(newData);
    return prevObj === null || newObj === null ? [] : prepareChangedFields(prevObj, newObj, changedFields);
  } catch (e) {
    console.log('error in getChangedFields:', e);
    throw e;
  }
};

export const getFieldMapping = (systemName: string) => {
  if (systemName === SYSTEM_NAME.offer_wall_manager) {
    return OFFER_FIELD_MAPPING;
  } else if (systemName === SYSTEM_NAME.quick_questions_manager) {
    return QUESTION_FIELD_MAPPING;
  }
  return AUDIENCE_MANAGER_FIELD_MAPPING;
};