import Field from 'components/Field';
import FileInput from 'components/FileInput';
import React, { useState } from 'react';
import getAuthorizedAxiosInstance from 'utils/getAuthorizedAxiosInstance';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import {
  Alert,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  theme,
} from '@klover/attain-design-system';
import { AxiosResponse } from 'axios';
import { Box, CircularProgress } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { KDSPageNames } from 'constants/index';
import { X } from '@phosphor-icons/react';
import { updateCurrentCustomAudience } from '../slice';
import { useAppDispatch } from 'redux/hooks';
import { validateCustomAudienceCsv } from '../../../helper/helper';
import * as Styled from './index.styles';

type formikVals = {
  audienceName: string;
  file: File | null;
};
interface Props {
  audienceId: number;
  name: string;
  selectedGroupId: number;
  lalModelType: string;
  fileName: string;
  currentUserEmail: string;
}

const CreateCustomAudience = ({
  audienceId,
  fileName,
  selectedGroupId,
  lalModelType,
  currentUserEmail,
  name,
}: Props) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const initialVals: formikVals = { audienceName: '', file: null };

  const [loaded, setLoaded] = useState(0);
  const [fileSize, setFileSize] = useState(0);
  const [validationDetails, setValidationDetails] = useState(null);
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: initialVals,
    onSubmit: async (values) => {
      handleSubmit(values);
    },
  });

  const handleFileSelected = (file: File) => {
    validateCustomAudienceCsv(file, setValidationDetails);
    formik.setFieldValue('file', file);
    setFileSize(file?.size || 0);
  };

  const handleSubmit = async (values: formikVals) => {
    try {
      setIsProcessing(true);
      if (values.file && name) {
        const file = values.file;
        const groupId = selectedGroupId.toString() || '';
        const email = currentUserEmail || '';
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', name);
        formData.append('email', email);
        formData.append('group_id', groupId);
        formData.append('lal_model_type', lalModelType);
        formData.append('enctype', 'multipart/form-data');
        const authorizedAxios = await getAuthorizedAxiosInstance();
        const config = {
          onUploadProgress: (progressEvent: ProgressEvent) => {
            setLoaded(progressEvent.loaded);
          },
        };
        if (authorizedAxios) {
          const res: AxiosResponse<any> = await authorizedAxios.post(
            `${API_BASE_ADDRESS}/${KDSPageNames.CUSTOMAUDIENCES}/audiences`,
            formData,
            config
          );
          dispatch(updateCurrentCustomAudience(res.data));
          window.history.pushState(
            null,
            document.title,
            `${window.location.href}/${res.data.id}`
          );
        }
        setValidationDetails(null);
      }
    } catch (e) {
      console.log('the error', e);
      setValidationDetails(null);
    }
  };
  const { file, audienceName } = formik.values;
  const isError = audienceName?.length > 175 || false;

  if (audienceId && fileName) {
    return (
      <Box
        sx={{
          flex: 3,
          border: '1px solid #ccc',
          borderRadius: 2,
          margin: '2rem 2rem 2rem 2rem',
          padding: '2rem',
          background: 'white',
        }}
      >
        <Styled.CardHeader>
          <Styled.CardTitle>Your audience file</Styled.CardTitle>
        </Styled.CardHeader>

        <>File name: {fileName}</>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flex: 3,
        border: '1px solid #ccc',
        borderRadius: 2,
        margin: '2rem 2rem 2rem 2rem',
        padding: '2rem',
        background: 'white',
      }}
    >
      <Styled.CardHeader>
        <Styled.CardTitle>Upload your audience file</Styled.CardTitle>
      </Styled.CardHeader>

      <Styled.StyledBodyContainer
        container
        spacing={theme.spacing.lg}
        direction="column"
        style={{ padding: 20 }}
      >
        {isProcessing ? (
          <Grid container direction="column" rowGap={2}>
            <Grid item>
              <LinearProgress
                variant="determinate"
                value={(loaded * 100) / fileSize}
              />
            </Grid>
            <Grid item>{loaded < fileSize ? 'Uploading' : 'Processing'}</Grid>
          </Grid>
        ) : (
          <FormikProvider value={formik}>
            <Grid container direction="column" rowGap={2}>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  columnGap={1}
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Field
                      disabled={true}
                      value={
                        formik.values.file
                          ? formik.values.file.name
                          : 'No file selected'
                      }
                      name="file"
                      label={
                        <span
                          style={{
                            fontFamily: 'Calibre-Regular,sans-serif',
                            fontSize: 16,
                          }}
                        >
                          Custom audience Ids file
                        </span>
                      }
                    ></Field>
                  </Grid>
                  <Grid item>
                    {file ? (
                      <IconButton
                        onClick={() => {
                          formik.setFieldValue('file', null);
                          setValidationDetails(null);
                        }}
                      >
                        <X size={22} />
                      </IconButton>
                    ) : (
                      <FileInput
                        setFile={handleFileSelected}
                        isDisabled={isProcessing}
                      />
                    )}
                  </Grid>
                </Grid>
                {validationDetails !== null && !validationDetails?.valid && (
                  <Grid item style={{ marginTop: 16 }}>
                    <Alert variant="outlined" severity="error">
                      {validationDetails?.message}
                    </Alert>
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <Alert severity="warning">
                  Please remember to remove header before uploading.
                </Alert>
              </Grid>
            </Grid>
          </FormikProvider>
        )}
      </Styled.StyledBodyContainer>

      <>
        <span>
          <Button
            variant="contained"
            type="submit"
            onClick={formik.handleSubmit}
            disabled={
              isProcessing ||
              !file ||
              !name ||
              isError ||
              (validationDetails && !validationDetails?.valid)
            }
          >
            {isProcessing ? (
              <>
                <CircularProgress size={25} id="circular-process" />
              </>
            ) : (
              'Create'
            )}
          </Button>
        </span>
      </>
    </Box>
  );
};

export default CreateCustomAudience;
