import styled from 'styled-components';
import tokens from 'tokens';
import { Grid, theme } from '@klover/attain-design-system';

export const GridContainer = styled(Grid)`
  padding: 0 !important;
`;

export const ButtonSpacing = styled.div`
  display: flex;

  & > * + * {
    margin-left: ${tokens.spacing.sm};
  }
`;

export const Label = styled.div`
  ${tokens.typography.label};
  margin-bottom: ${tokens.spacing.xs};
  font-size: 16px;
  font-family: Calibre-Regular, sans-serif;
`;

export const ResponseLabel = styled.div`
  ${tokens.typography.label};
  margin-top: ${tokens.spacing.md};
  margin-bottom: ${tokens.spacing.xs};
`;

export const Row = styled.div`
  display: flex;

  & > button {
    margin-left: ${tokens.spacing.sm};
  }
`;

export const SubHeader = styled.h3`
  ${tokens.typography.subheaderSm};
  margin: ${tokens.spacing.lg} 0 ${tokens.spacing.sm};
`;

export const PickerSpace = styled.div`
  position: absolute;
  z-index: ${(props: { changeEmoji: boolean }) =>
    props.changeEmoji ? 10000 : 1};
  padding-bottom: ${tokens.spacing.sm};
`;

export const RadioSelectionSpace = styled.div`
  margin: ${tokens.spacing.md} -${tokens.spacing.xs};
  padding: 0px ${tokens.spacing.xs};
  overflow-y: scroll;
  height: 150px;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const CardHeader = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xxs};
  width: 100%;
`;

export const CardTitle = styled.h2`
  ${theme.typography.headingSM};
  flex-grow: 1;
`;

export const StyledBodyContainer = styled(Grid)`
  span {
    ${theme.typography.headingXS};
  }
`;

export const BodyHeader = styled.div`
  ${theme.typography.headingXS};
  margin-bottom: ${theme.spacing.xs};
`;

export const BodyText = styled.p`
  ${theme.typography.bodyLG};
  color: ${theme.colors.text};
  margin-top: 0;
`;

export const FieldLabel = styled.label`
  font-size: 16px;
  font-family: Calibre-Regular, sans-serif;
`;

export const LoadingWrapper = styled.div`
  ${theme.mixins.card};
  overflow: hidden;
  gap: 5px;
  display: flex;
  flex-direction: column;
`;

export const LoadingBody = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.xxs};
`;

export const AudienceNameContainer = styled.div`
  display: flex;
  font-size: 15px;
  align-items: center;
`;

export const AudienceName = styled.div`
  margin-right: 12px;
`;