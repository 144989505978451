import _ from 'lodash';
import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';

import backgroundImageUrlConfig from './configurations/backgroundImageUrlInputConfig';
import bodyConfig from './configurations/bodyInputConfig';
import callToActionConfig from './configurations/callToActionInputConfig';
import clickthroughUrlConfig from './configurations/clickthroughUrlInputConfig';
import comparisonEmojiConfig from './configurations/comparisonEmojiInputConfig';
import comparisonTextConfig from './configurations/comparisonTextInputConfig';
import fullScreenImgUrlConfig from './configurations/fullScreenImageUrlInputConfig';
import headlineConfig from './configurations/headlineInputConfig';
import imgUrlConfig from './configurations/imageUrlInputConfig';
import offerNameConfig from './configurations/offerNameInputConfig';
import priceConfig from './configurations/priceInputConfig';
import sublineConfig from './configurations/sublineInputConfig';
import validateOfferInputs from './utils/validateOfferInputs';
import zIndexConfig from './configurations/zIndexInputConfig';
import { LoadingProps, LoadingPropsObject, LoadingState } from 'interfaces/ui';
import {
  NormalizedObjects,
  initialNormalizedObject,
} from 'interfaces/entities';
import { SalesLiftStudy } from '../salesLiftStudyManager/slice';
import { UploadStates, ValidationErrorMessages } from 'constants/index';

interface AudienceElement {
  audienceid: number;
  audiencename: string;
}

const placementConfigMap = {
  '1': [
    offerNameConfig,
    imgUrlConfig,
    backgroundImageUrlConfig,
    fullScreenImgUrlConfig,
    headlineConfig,
    bodyConfig,
    comparisonEmojiConfig,
    comparisonTextConfig,
    callToActionConfig,
    clickthroughUrlConfig,
    zIndexConfig,
  ],
  '2': [
    offerNameConfig,
    imgUrlConfig,
    backgroundImageUrlConfig,
    fullScreenImgUrlConfig,
    headlineConfig,
    bodyConfig,
    comparisonEmojiConfig,
    comparisonTextConfig,
    priceConfig,
    callToActionConfig,
    clickthroughUrlConfig,
    zIndexConfig,
  ],
  '3': [
    imgUrlConfig,
    fullScreenImgUrlConfig,
    headlineConfig,
    bodyConfig,
    comparisonEmojiConfig,
    comparisonTextConfig,
    callToActionConfig,
    clickthroughUrlConfig,
    zIndexConfig,
  ],
  '4': [
    imgUrlConfig,
    backgroundImageUrlConfig,
    fullScreenImgUrlConfig,
    { inputLabel: 'headline', key: 'offername', inputType: 'textblock' },
    sublineConfig,
    bodyConfig,
    callToActionConfig,
    clickthroughUrlConfig,
  ],
};

export interface Offer {
  audiences: Array<AudienceElement>;
  clicks: number;
  offerid?: string;
  offername: string;
  isactive: boolean;
  impressions: number;
  label: string;
  offergroupid: number;
  description: string;
  pricingmodel: string;
  category: number;
  isnew: boolean;
  isfeatured: boolean;
  logourl: string;
  backgroundimgurl: string;
  fullscreenimageurl: string;
  comparisontext: string;
  comparisonemoji: number;
  ctaurl: string;
  ctatext: string;
  headline: string;
  startdate: number;
  enddate: number;
  flightdates: number;
  gid: string | null;
  placementid: [string];
  budget: number;
  actionforpoints: string;
  revconversionevent: string;
  tuneofferid: string;
  adconversionevent: string;
  successurl: string;
  points: number;
  states: string[];
  zips: string[];
  zipCount: number;
  devices: string;
  mediarate: number | null;
  cap: number;
  price: number;
  zip_upload_status: UploadStates;
  offergroupname: string;
}

export interface OfferGroup {
  id: number;
  name: string;
  offers: Offer['offerid'][];
  studies: SalesLiftStudy['salesliftstudyid'][];
}

interface OfferIsSaveable {
  [key: string]: boolean;
}

export const OfferPlacementOptions: {
  [key: string]: number;
} = {
  dashboard: 2,
  offerWall: 1,
  Onboarding: 4,
  'klover+': 3,
};

export interface ToggleDuplicateOfferDialogAction {
  open: boolean;
}

export interface ToggleGroupDeleteDialogAction {
  open: boolean;
}

export interface ToggleGroupNameDialogAction {
  open: boolean;
}

export interface ZipUploadPayload {
  file: File;
  offerId: string;
  userEmail: string;
}

interface OfferWallState {
  currentActiveOffers: string[];
  allOffers: Offer[];
  currentGroup?: OfferGroup;
  currentOffer?: Offer;
  currentOfferDimension?: any;
  currentTargeting: any;
  placementsandoffers: any;
  entities: {
    groups: NormalizedObjects<OfferGroup>;
    offers: NormalizedObjects<Offer>;
    searchGroups: NormalizedObjects<OfferGroup>;
  };
  originalOffer?: any;
  ui: {
    editOffer: boolean;
    groupsLoading: LoadingProps;
    groupDeleting: LoadingProps;
    groupLoading: LoadingProps;
    groupSaving: LoadingProps;
    notifications: Array<LoadingProps>;
    offerDeleteDialog: boolean;
    offerDimensionDialog: boolean;
    errorFetching: boolean;
    offerIsSaveable: OfferIsSaveable;
    offersLoading: LoadingProps;
    offerLoading: LoadingProps;
    openCreateOfferDialog: boolean;
    openDraftStateDialog: any;
    openDuplicateOfferDialog: boolean;
    openGroupDeleteDialog: boolean;
    openGroupNameDialog: boolean;
    searchQuery: string;
    states: LoadingPropsObject;
  };
}

export const initialState: OfferWallState = {
  currentActiveOffers: [],
  currentTargeting: {},
  placementsandoffers: [],
  entities: {
    groups: initialNormalizedObject,
    offers: initialNormalizedObject,
    searchGroups: initialNormalizedObject,
  },
  ui: {
    editOffer: false,
    groupsLoading: { state: 'idle' },
    groupDeleting: { state: 'idle' },
    groupLoading: { state: 'idle' },
    groupSaving: { state: 'idle' },
    notifications: [],
    offerDeleteDialog: false,
    offerDimensionDialog: false,
    errorFetching: false,
    offersLoading: { state: 'idle' },
    offerLoading: { state: 'idle' },
    offerIsSaveable: {},
    openCreateOfferDialog: false,
    openDraftStateDialog: { open: false },
    openDuplicateOfferDialog: false,
    openGroupDeleteDialog: false,
    openGroupNameDialog: false,
    searchQuery: '',
    states: {},
    zipsFileUploading: { state: 'idle' },
  },
};

export const offerWallSlice = createSlice({
  name: 'offerWall',
  initialState,
  reducers: {
    addOffer: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    addSalesLiftStudyToCurrentGroup: (state, action) => {
      if (state.currentGroup && state.currentGroup.studies) {
        state.currentGroup.studies = _.clone(state.currentGroup.studies).concat(
          [action.payload]
        );
      }
    },
    removeSalesLiftStudyFromCurrentGroup: (state, action) => {
      if (state.currentGroup && state.currentGroup.studies) {
        state.currentGroup.studies = state.currentGroup.studies
          .filter((x) => x != action.payload)
          .filter((x) => x);
      }
    },
    addAudience: (state, action) => {
      if (state.currentOffer && _.isArray(state.currentOffer.audiences)) {
        state.currentOffer.audiences.push(action.payload);
      }
    },
    removeAudience: (state, action) => {
      if (state.currentOffer && state.currentOffer.audiences) {
        state.currentOffer.audiences = state.currentOffer.audiences.filter(
          ({ audienceid }) => audienceid != action.payload
        );
      }
    },
    addNotification: (state, action) => {
      state.ui.notifications.push(action.payload);
    },
    applyOfferDimension: (state, action) => {
      const dimensionName = action.payload;
      const currentOffer = current(state.currentOffer);
      let updatedCurrentOfferDimension;
      if (dimensionName === 'offerDetails') {
        updatedCurrentOfferDimension = {
          dimensionName,
          inputs: [
            {
              inputLabel: 'status',
              key: 'isactive',
              inputType: 'switch',
              disabledTooltip:
                ValidationErrorMessages.CURRENT_DATE_NOT_IN_RANGE,
            },
            {
              inputLabel: 'placement',
              key: 'placementid',
              inputType: 'multiselect',
            },
            {
              inputLabel: 'flightDates',
              key: 'flightdates',
              inputType: 'flightpicker',
              multipleDimensions: ['enddate', 'startdate'],
            },
            {
              inputLabel: 'advertiserGID',
              key: 'gid',
              inputType: 'textfield',
            },
            {
              inputLabel: 'payout',
              key: 'mediarate',
              inputType: 'number',
            },
            {
              inputLabel: 'revenueConversionEvent',
              key: 'revenueconversionevent',
              inputType: 'select',
            },
            {
              inputLabel: 'pricingModel',
              key: 'pricingmodel',
              inputType: 'select',
            },
            {
              inputLabel: 'category',
              key: 'category',
              inputType: 'multiselect',
            },
            {
              inputLabel: 'newFlag',
              key: 'isnew',
              inputType: 'switch',
            },
            {
              inputLabel: 'featuredFlag',
              key: 'isfeatured',
              inputType: 'switch',
            },
            // Reason to comment : https://klover.atlassian.net/browse/ACT-1882?focusedCommentId=28472
            // {
            //   alternativeLabel: 'Conversion Capping (per day)',
            //   inputLabel: 'conversionCapping',
            //   key: 'cap',
            //   inputType: 'number',
            // },
          ],
        };
      } else if (dimensionName === 'creativeDetails') {
        const placementIds = Array.isArray(currentOffer?.placementid)
          ? currentOffer.placementid
          : [currentOffer?.placementid];

        const mergedInputs = [
          ...new Map(
            placementIds
              .flatMap((id) => placementConfigMap[id] || [])
              .map((item) => [item.key, item])
          ).values(),
        ];

        updatedCurrentOfferDimension = {
          dimensionName,
          inputs: mergedInputs,
        };
      } else if (dimensionName === 'kloverPoints') {
        updatedCurrentOfferDimension = {
          dimensionName,
          inputs: [
            {
              inputLabel: 'points',
              key: 'points',
              inputType: 'textfield',
            },
            {
              inputLabel: 'kloverPointsConversionEvent',
              key: 'actionforpoints',
              inputType: 'select',
            },
            {
              inputLabel: 'successUrls',
              key: 'successurl',
              inputType: 'urlarray',
            },
            {
              inputLabel: 'multipleConversionEventOverride',
              key: 'multipleconversionsenabled',
              inputType: 'switch',
            },
            {
              key: 'offertunegoals',
              inputType: 'goals',
              child: [
                {
                  inputLabel: 'Goal Completion Order',
                  key: 'goalcompletionorder',
                },
                {
                  inputLabel: 'Tune Goal ID',
                  key: 'goalid',
                },
                {
                  inputLabel: 'Goal Completion Instructions',
                  key: 'goalcompletioninstructions',
                },
                {
                  inputLabel: 'Klover Points Award for Goal ID',
                  key: 'pointsawarded',
                },
                {
                  inputLabel: 'Conversion Cap for Goal ID',
                  key: 'isconversioncapactive',
                  inputType: 'switch',
                },
                {
                  inputLabel: 'Cap Limit for Goal ID',
                  key: 'conversioncapvalue',
                },
                {
                  inputLabel: 'Cap Date Range for Goal ID',
                  key: 'conversiontimerange',
                  inputType: 'select',
                },
              ],
            },
          ],
        };
      } else if (dimensionName === 'tracking') {
        updatedCurrentOfferDimension = {
          dimensionName,
          inputs: [
            {
              inputLabel: 'impressionEvents',
              key: 'onofferseenurltofire',
              inputType: 'urlarray',
            },
          ],
        };
      } else if (dimensionName === 'targeting') {
        updatedCurrentOfferDimension = {
          dimensionName,
          inputs: [
            {
              inputLabel: 'zipCodesFile',
              key: 'zipsFile',
              inputType: 'file',
            },
            {
              inputLabel: 'zipCodes',
              key: 'zips',
              inputType: 'textblock',
            },
            {
              inputLabel: 'states',
              key: 'states',
              inputType: 'textblock',
            },
            {
              inputLabel: 'devices',
              key: 'devices',
              inputType: 'select',
            },
            {
              inputLabel: 'audiences',
              key: 'audiences',
              inputType: 'audienceSelector',
            },
          ],
        };
      } else if (dimensionName === 'addDisclosure') {
        updatedCurrentOfferDimension = {
          dimensionName,
          inputs: [
            {
              inputLabel: 'isDisclosure',
              key: 'disclosure_required',
              inputType: 'switch',
            },
            {
              inputLabel: 'header',
              key: 'disclosure_header',
              inputType: 'textfield',
            },
            {
              inputLabel: 'body',
              key: 'disclosure_body',
              inputType: 'textblock',
            },
          ],
        };
      }
      state.currentOfferDimension = updatedCurrentOfferDimension;
    },
    clearChanges: (state, action) => {
      const defaultValues = action.payload;
      const defaultOffer = {
        label: '',
        offername: '',
        successurl: [null],
        onofferseenurltofire: [null],
        actionforpoints: 'ACTION_CONV',
        placementid: ['1'],
        devices: 'ALL_DEVICES',
      };
      const originalOffer = state.originalOffer
        ? _.clone(current(state.originalOffer))
        : defaultOffer;
      for (const defaultValue of defaultValues) {
        state.currentOffer[defaultValue] = originalOffer[defaultValue];
      }
    },
    clearCurrentTargeting: (state) => {
      state.currentTargeting = {};
    },
    clearOffers: (state) => {
      state.entities.offers = initialNormalizedObject;
    },
    clearOfferIsSaveable: (state) => {
      state.ui.offerIsSaveable = {};
    },
    clearGroups: (state) => {
      state.entities.groups = initialNormalizedObject;
    },
    clearSearchGroups: (state) => {
      state.entities.searchGroups = initialNormalizedObject;
    },
    createGroup: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    createOffer: () => {
      //
    },
    deleteGroup: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    deleteOffer: () => {
      //skeleton
    },
    duplicateOffer: () => {
      // Skeleton
    },
    fetchSomeAudiences: () => {
      //wrapper
    },
    fetchGroup: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    fetchGroups: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    fetchOffer: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    fetchOffers: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    loadOriginalOffer: (state, action) => {
      state.originalOffer = action.payload;
    },
    saveGroup: () => {
      // Skeleton to get helpers to build actions
      // Refer saga.ts
    },
    saveOffer: () => {
      // skeleteon
    },
    searchOffers: () => {
      // Skeleton
    },
    moveOffer: () => {
      // skeleton
    },
    uploadZipsFile: () => {
      //Skeleton to get helpers to build actons
      //Refer sagas.ts
    },
    deleteZipCodes: () => {
      //Skeleton
    },
    setZipCount: (state, action: PayloadAction<number>) => {
      if (state.currentOffer) {
        state.currentOffer.zipCount = action.payload;
      }
    },
    setZipsFileUploadingState: (state, action: PayloadAction<UploadStates>) => {
      if (state.currentOffer) {
        state.currentOffer.zip_upload_status = action.payload;
      }
    },
    setGroupDeletingState: (state, action: PayloadAction<LoadingProps>) => {
      state.ui.groupDeleting = action.payload;
    },
    setGroupLoadingState: (state, action: PayloadAction<LoadingProps>) => {
      state.ui.groupLoading = action.payload;
    },
    setGroupsLoadingState: (state, action: PayloadAction<LoadingProps>) => {
      state.ui.groupsLoading = action.payload;
    },
    setGroupSavingState: (state, action: PayloadAction<LoadingProps>) => {
      state.ui.groupSaving = action.payload;
    },
    setInitialPlacementOffers: () => {
      // wrapper
    },
    setOffersLoadingState: (state, action) => {
      state.ui.offersLoading = action.payload;
    },
    setOfferLoadingState: (state, action) => {
      state.ui.offerLoading = action.payload;
    },
    setPlacementsAndOffers: (state, action) => {
      state.placementsandoffers = action.payload;
    },
    setUIState: (state, action) => {
      const { value, uiPropertyName } = action.payload;
      state.ui[uiPropertyName] = value;
    },
    toggleCreateOfferDialog: (state, action) => {
      state.ui.openCreateOfferDialog = action.payload;
    },
    toggleDraftStateDialog: (state, action) => {
      state.ui.openDraftStateDialog = action.payload;
    },
    toggleDuplicateOfferDialog: (
      state,
      action: PayloadAction<ToggleDuplicateOfferDialogAction>
    ) => {
      state.ui.openDuplicateOfferDialog = action.payload.open;
    },
    toggleEditOffer: (state, action) => {
      state.ui.editOffer = action.payload;
    },
    toggleGroupDeleteDialog: (
      state,
      action: PayloadAction<ToggleGroupDeleteDialogAction>
    ) => {
      state.ui.openGroupDeleteDialog = action.payload.open;
      if (action.payload.open) {
        state.ui.groupDeleting = { state: 'idle' };
      }
    },
    toggleGroupNameDialog: (
      state,
      action: PayloadAction<ToggleGroupNameDialogAction>
    ) => {
      state.ui.openGroupNameDialog = action.payload.open;
      if (action.payload.open) {
        state.ui.groupSaving = { state: 'idle' };
      }
    },
    toggleOfferDeleteDialog: (state, action) => {
      state.ui.offerDeleteDialog = action.payload;
    },
    toggleOfferDimensionDialog: (state, action) => {
      state.ui.offerDimensionDialog = action.payload;
    },
    updateCurrentActiveOffers: (state, action: PayloadAction<any[]>) => {
      state.currentActiveOffers = action.payload;
    },
    setAllOffers: (state, action: PayloadAction<any[]>) => {
      state.allOffers = action.payload;
    },
    updateCurrentAudiences: (state, action) => {
      const { id, selected, name } = action.payload;
      if (state.currentOffer) {
        let newAudiences = state.currentOffer.audiences
          ? _.clone(state.currentOffer.audiences)
          : [];
        if (selected) {
          newAudiences = newAudiences.filter(
            ({ audienceid }) => audienceid != id
          );
        } else {
          newAudiences.push({ audienceid: id, audiencename: name });
        }
        state.currentOffer.audiences = newAudiences;
      }
    },
    updateCurrentGroup: (
      state,
      action: PayloadAction<OfferGroup | undefined>
    ) => {
      state.currentGroup = action.payload;
    },
    updateCurrentGroupProperty: (state, action) => {
      const { key, value } = action.payload;
      if (state.currentGroup) state.currentGroup[key] = value;
    },
    updateCurrentOffer: (state, action) => {
      state.currentOffer = action.payload;
    },
    updateCurrentOfferProperty: (state, action) => {
      const { updatedOffer, propertyName } = action.payload;
      if (updatedOffer) {
        const returnObject = validateOfferInputs(
          updatedOffer[propertyName],
          propertyName
        );
        if (returnObject.isValid) {
          delete state.ui.offerIsSaveable[propertyName];
        } else {
          state.ui.offerIsSaveable[propertyName] = false;
        }
        if (propertyName === 'startdate') {
          if (updatedOffer.enddate) {
            delete state.ui.offerIsSaveable.enddateExists;
          } else {
            state.ui.offerIsSaveable.enddateExists = false;
          }
        }
        if (propertyName === 'enddate') {
          delete state.ui.offerIsSaveable.enddateExists;
        }
      }
      state.currentOffer = updatedOffer;
    },
    updateCurrentTargeting: (state, action) => {
      state.currentTargeting = action.payload;
    },
    updateGroups: (state, action: PayloadAction<OfferGroup>) => {
      const groupsEntity = _.clone(current(state.entities.groups));
      const newAllIds = _.union(groupsEntity.allIds, [action.payload.id]);
      const newById = _.clone(groupsEntity.byId);
      newById[action.payload.id] = action.payload;
      state.entities.groups.byId = newById;
      state.entities.groups.allIds = newAllIds;
    },
    updateOfferCreativeRotation: (state, action) => {
      const { children } = action.payload;
      children.forEach(({ offerid }, index) => {
        if (offerid && state.entities.offers.byId[offerid]) {
          state.entities.offers.byId[offerid].creativerotationenabled = true;
          if (index !== 0)
            state.entities.offers.byId[offerid].parentofferid =
              children[0].offerid;
        }
      });
    },
    updateSearchGroups: (state, action: PayloadAction<OfferGroup>) => {
      if (!(state.entities && state.entities.searchGroups))
        state.entities.searchGroups = initialNormalizedObject;
      const searchGroupsEntity = _.clone(current(state.entities.searchGroups));
      const newAllIds = _.union(searchGroupsEntity.allIds, [action.payload.id]);
      const newById = _.clone(searchGroupsEntity.byId);
      newById[action.payload.id] = action.payload;
      state.entities.searchGroups.byId = newById;
      state.entities.searchGroups.allIds = newAllIds;
    },
    updateSearchQuery: (state, action) => {
      state.ui.searchQuery = action.payload;
    },
    updateOffers: (state, action) => {
      if (Array.isArray(action.payload)) {
        const offers = action.payload;
        const offerIds = offers.map((offer) => {
          state.entities.offers.byId[offer.offerid] = offer;
          return offer.offerid;
        });
        state.entities.offers.allIds = offerIds;
      } else {
        state.entities.offers.byId[action.payload.offerid] = action.payload;
        state.entities.offers.allIds = _.union(state.entities.offers.allIds, [
          action.payload.offerid,
        ]);
      }
    },
    updateOfferZindex: (state, action) => {
      const { offerid, zindex } = action.payload;
      if (state.entities.offers.byId[offerid]) {
        state.entities.offers.byId[offerid].zindex = zindex;
      }
    },
    updateStates: (state, action) => {
      const newStates = _.clone(state.ui.states);
      state.ui.states = _.assign(newStates, { [action.payload]: true });
    },
    removeFromStates: (state, action) => {
      const newStates = _.clone(state.ui.states);
      delete newStates[action.payload];
      state.ui.states = newStates;
    },
    removeNotification: (state) => {
      state.ui.notifications = state.ui.notifications.slice(1);
    },
    removeOffer: (state, action) => {
      const offersEntity = _.clone(current(state.entities.offers));
      const newAllIds = offersEntity.allIds.filter(
        (offerId) => offerId !== action.payload.offerid
      );
      const newById = _.clone(offersEntity.byId);
      delete newById[action.payload.offerid];
      state.entities.offers.byId = newById;
      state.entities.offers.allIds = newAllIds;
    },
    removeOfferGroup: (state, action) => {
      const groupsEntity = _.clone(current(state.entities.groups));
      const newAllIds = groupsEntity.allIds.filter(
        (offerGroup) => offerGroup !== action.payload.id
      );
      const newById = _.clone(groupsEntity.byId);
      delete newById[action.payload.id];
      state.entities.groups.byId = newById;
      state.entities.groups.allIds = newAllIds;
    },
    removeCreativeRotations: (state, action) => {
      const { offers } = action.payload;
      offers.forEach(({ offerid }) => {
        if (offerid && state.entities.offers.byId[offerid]) {
          state.entities.offers.byId[offerid].creativerotationenabled = false;
          state.entities.offers.byId[offerid].weight = null;
          state.entities.offers.byId[offerid].parentofferid = null;
        }
      });
    },
  },
});

export const {
  addAudience,
  addNotification,
  addOffer,
  applyOfferDimension,
  clearChanges,
  clearOfferIsSaveable,
  clearGroups,
  clearSearchGroups,
  clearCurrentTargeting,
  clearOffers,
  createGroup,
  createOffer,
  deleteGroup,
  deleteOffer,
  deleteZipCodes,
  duplicateOffer,
  fetchSomeAudiences,
  fetchGroup,
  fetchGroups,
  fetchOffer,
  fetchOffers,
  loadOriginalOffer,
  moveOffer,
  uploadZipsFile,
  setZipsFileUploadingState,
  setZipCount,
  saveGroup,
  saveOffer,
  searchOffers,
  setGroupDeletingState,
  setGroupLoadingState,
  setGroupsLoadingState,
  setInitialPlacementOffers,
  setGroupSavingState,
  setOffersLoadingState,
  setOfferLoadingState,
  setPlacementsAndOffers,
  setAllOffers,
  setUIState,
  toggleCreateOfferDialog,
  toggleDraftStateDialog,
  toggleOfferDeleteDialog,
  toggleOfferDimensionDialog,
  toggleDuplicateOfferDialog,
  toggleEditOffer,
  toggleGroupDeleteDialog,
  toggleGroupNameDialog,
  updateCurrentActiveOffers,
  updateCurrentAudiences,
  updateCurrentGroup,
  updateCurrentGroupProperty,
  addSalesLiftStudyToCurrentGroup,
  updateCurrentOffer,
  updateCurrentOfferProperty,
  updateCurrentTargeting,
  updateGroups,
  updateOfferCreativeRotation,
  updateSearchGroups,
  updateSearchQuery,
  updateOffers,
  updateOfferZindex,
  updateStates,
  removeAudience,
  removeFromStates,
  removeNotification,
  removeOffer,
  removeOfferGroup,
  removeSalesLiftStudyFromCurrentGroup,
  removeCreativeRotations,
} = offerWallSlice.actions;

export default offerWallSlice.reducer;
