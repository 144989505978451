import {
  AUDIENCE_EXPORT_TYPES,
  EXPORT_MODELED_AUDIENCES,
  LoadingStates,
} from '../../constants';
import { useAppSelector } from 'redux/hooks';

import ExperianIcon from '../../assets/images/experian_icon.png';
import LiveRampIcon from '../../assets/images/liveramp_icon.ico';
import MagniteIcon from '../../assets/images/magnite_icon.png';
import MetaIcon from '../../assets/images/meta_icon.png';
import OpenXIcon from '../../assets/images/openx_icon.png';
import PubmaticIcon from '../../assets/images/pubmatic_icon.ico';
import TTDIcon from '../../assets/images/ttd_icon.png';
import XIcon from '../../assets/images/x_icon.png';
import YahooIcon from '../../assets/images/yahoo_icon.png';

// Material UI Components
import DynamicForm from 'components/Form';
import tokens from 'tokens';
import {
  ADD_AUDIENCE_DETAILS,
  AUDIENCE_EXPORT_FIELD_TITLES,
  LIVERAMP_SEGMENT_FIELD_NAME,
  TRADE_DESK_SEGMENT_FIELD_NAME,
} from '../../content';
import { Grid, TextField } from '@klover/attain-design-system';
import { LoadingAudience } from 'pages/audienceManager/editor';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { isDescriptionValid } from '../../helper/helper';
import { useEffect, useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLiveRampIntigrations } from '../../graphql/hooks/useLiveRampExportData';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    informationText: {
      marginTop: '1rem',
      fontFamily: 'Calibre-Regular,sans-serif',
      fontSize: 16,
    },
    segmentDivider: {
      background: tokens.colorDividerLgBorder,
      height: '1px',
      border: 'none',
    },
    segmentInputFieldsContainer: {
      display: 'flex',
      padding: '1rem 1rem 0rem 1rem',
    },
    segmentInputFieldsLeft: {
      flex: 1,
      borderRight: '1px solid' + tokens.colorDividerLgBorder,
      paddingRight: '1rem',
    },
    segmentInputFieldsRight: {
      flex: 1,
      paddingLeft: '1rem',
    },
    tradeDeskDataSegmentLabel: {
      paddingBottom: '1.5rem',
      fontSize: 20,
      fontWeight: 500,
      fontFamily: 'Calibre-Regular,sans-serif',
    },
    divider: {
      border: '1px solid #e4e4e4',
      marginTop: 20,
      width: '100%',
    },
    exportContainer: {
      backgroundColor: 'rgb(240, 239, 241)',
      padding: 15,
      marginTop: 18,
      borderRadius: 8,
      width: '100%',
    },
    subtitles: {
      paddingTop: 5,
      paddingBottom: '1.5rem',
      fontSize: 18,
      fontWeight: 500,
      fontFamily: 'Calibre-Bold,sans-serif',
    },
    OrLabel: {
      fontSize: 18,
      fontWeight: 500,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Calibre-Regular,sans-serif',
    },
    destinations: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 10,
    },
    destinationChip: {
      fontSize: 16,
      height: 28,
      border: '1px solid black',
      backgroundColor: '#ffffff',
      color: 'black',
      fontFamily: 'Calibre-Regular,sans-serif',
      padding: '15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '16px',
      cursor: 'pointer',
      gap: '5px',
      borderColor: 'rgb(89 89 89)',
    },
    destinationSelectedChip: {
      fontSize: 16,
      height: 28,
      border: '1px solid black',
      backgroundColor: 'rgb(225, 244, 234)',
      color: 'black',
      fontFamily: 'Calibre-Regular,sans-serif',
      padding: '15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '16px',
      cursor: 'pointer',
      gap: '5px',
      borderColor: 'rgb(89 89 89)',
    },
    destinationLabel: {
      fontFamily: 'Calibre-Regular,sans-serif',
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    audienceFieldTitle: {
      fontSize: 18,
      fontWeight: 500,
      fontFamily: 'Calibre-Regular,sans-serif',
    },
  })
);

const otsOptions = [
  { id: 'all', label: 'Toggle All' },
  {
    id: AUDIENCE_EXPORT_TYPES.OTS_SEGMENT,
    label: 'The Trade Desk',
    icon: TTDIcon,
  },
  {
    id: AUDIENCE_EXPORT_TYPES.PUBMATIC_OTS_SEGMENT,
    label: 'Pubmatic',
    icon: PubmaticIcon,
  },
  {
    id: AUDIENCE_EXPORT_TYPES.OPENX_OTS_SEGMENT,
    label: 'OpenX',
    icon: OpenXIcon,
  },
];

const customOptions = [
  {
    id: AUDIENCE_EXPORT_TYPES.CUSTOM_SEGMENT,
    label: 'The Trade Desk Custom',
    icon: TTDIcon,
  },
  {
    id: AUDIENCE_EXPORT_TYPES.PUBMATIC_CUSTOM_SEGMENT,
    label: 'Pubmatic',
    icon: PubmaticIcon,
  },
  {
    id: AUDIENCE_EXPORT_TYPES.OPENX_CUSTOM_SEGMENT,
    label: 'OpenX',
    icon: OpenXIcon,
  },
];

const InAudienceExport = (props: any) => {
  const classes = useStyles();
  const {
    uiAudienceManagerExportLiveRampVisible = false,
    uiAudienceManagerInhouseExportLiveRampOtsVisible = false,
    uiAudienceManagerInhouseExportExperianOtsVisible = false,
    uiAudienceManagerInhouseOtsExportDemoExercise = false,
    uiAudienceManagerInhouseExportModeledOtsAudienceVisible = false,
  } = useFlags();

  const {
    selectedExportType = [],
    handleSelectedExportType,
    segmentName,
    description,
    cpm,
    percentageOfMedia,
    isSegmentNameValid,
    inValidSegmentNameErrorMessage,
    formData,
    onChange,
    exportDynamicFields = [],
    isSelectAudienceVisible,
  } = props;

  const audienceLoading = useAppSelector(
    (state) => state.audienceReducer.ui.audienceLoading
  );

  const {
    liveRampIntegrations = [],
    liveRampDistributionManagers = [],
    refetch: refetchLiveRampIntegrations,
  } = useLiveRampIntigrations({
    distributionManagerId: '',
    distributionManagerName: '',
    integrationId: '',
    integrationName: '',
  });

  useEffect(() => {
    refetchLiveRampIntegrations();
  }, []);

  useEffect(() => {
    updateLaunchDarklyFlags();
  }, [
    uiAudienceManagerExportLiveRampVisible,
    uiAudienceManagerInhouseExportLiveRampOtsVisible,
    uiAudienceManagerInhouseExportExperianOtsVisible,
    uiAudienceManagerInhouseOtsExportDemoExercise,
  ]);

  /**
   * Update LiveRamp options dynamically based on flag visibility
   */
  const updateLaunchDarklyFlags = () => {
    // Only add LiveRamp option if the flag is true
    if (
      uiAudienceManagerExportLiveRampVisible &&
      !customOptions.some(
        (x) => x.id === AUDIENCE_EXPORT_TYPES.LIVERAMP_CUSTOM_SEGMENT
      )
    ) {
      customOptions.push({
        id: AUDIENCE_EXPORT_TYPES.LIVERAMP_CUSTOM_SEGMENT,
        label: 'LiveRamp',
        icon: LiveRampIcon,
      });
    }

    // Only add LiveRamp option if the flag is true
    if (
      uiAudienceManagerInhouseExportLiveRampOtsVisible &&
      !otsOptions.some(
        (x) => x.id === AUDIENCE_EXPORT_TYPES.LIVERAMP_OTS_SEGMENT
      )
    ) {
      otsOptions.push({
        id: AUDIENCE_EXPORT_TYPES.LIVERAMP_OTS_SEGMENT,
        label: 'LiveRamp',
        icon: LiveRampIcon,
      });
    }

    // Only add LiveRamp option if the flag is true
    if (
      uiAudienceManagerInhouseExportExperianOtsVisible &&
      !otsOptions.some(
        (x) => x.id === AUDIENCE_EXPORT_TYPES.EXPERIAN_OTS_SEGMENT
      )
    ) {
      otsOptions.push({
        id: AUDIENCE_EXPORT_TYPES.EXPERIAN_OTS_SEGMENT,
        label: 'Experian',
        icon: ExperianIcon,
      });
    }

    if (
      uiAudienceManagerInhouseOtsExportDemoExercise &&
      !otsOptions.some((x) => x.id === AUDIENCE_EXPORT_TYPES.X_OTS_SEGMENT)
    ) {
      otsOptions.push({
        id: AUDIENCE_EXPORT_TYPES.X_OTS_SEGMENT,
        label: 'X',
        icon: XIcon,
      });
    }

    if (
      uiAudienceManagerInhouseOtsExportDemoExercise &&
      !otsOptions.some((x) => x.id === AUDIENCE_EXPORT_TYPES.META_OTS_SEGMENT)
    ) {
      otsOptions.push({
        id: AUDIENCE_EXPORT_TYPES.META_OTS_SEGMENT,
        label: 'Meta',
        icon: MetaIcon,
      });
    }

    if (
      uiAudienceManagerInhouseOtsExportDemoExercise &&
      !otsOptions.some(
        (x) => x.id === AUDIENCE_EXPORT_TYPES.MAGNITE_OTS_SEGMENT
      )
    ) {
      otsOptions.push({
        id: AUDIENCE_EXPORT_TYPES.MAGNITE_OTS_SEGMENT,
        label: 'Magnite',
        icon: MagniteIcon,
      });
    }

    if (
      uiAudienceManagerInhouseOtsExportDemoExercise &&
      !otsOptions.some(
        (x) => x.id === AUDIENCE_EXPORT_TYPES.YAHOO_DSP_OTS_SEGMENT
      )
    ) {
      otsOptions.push({
        id: AUDIENCE_EXPORT_TYPES.YAHOO_DSP_OTS_SEGMENT,
        label: 'Yahoo DSP',
        icon: YahooIcon,
      });
    }

    if (
      uiAudienceManagerInhouseOtsExportDemoExercise &&
      !customOptions.some(
        (x) => x.id === AUDIENCE_EXPORT_TYPES.X_CUSTOM_SEGMENT
      )
    ) {
      customOptions.push({
        id: AUDIENCE_EXPORT_TYPES.X_CUSTOM_SEGMENT,
        label: 'X',
        icon: XIcon,
      });
    }

    if (
      uiAudienceManagerInhouseOtsExportDemoExercise &&
      !customOptions.some(
        (x) => x.id === AUDIENCE_EXPORT_TYPES.META_CUSTOM_SEGMENT
      )
    ) {
      customOptions.push({
        id: AUDIENCE_EXPORT_TYPES.META_CUSTOM_SEGMENT,
        label: 'Meta',
        icon: MetaIcon,
      });
    }

    if (
      uiAudienceManagerInhouseOtsExportDemoExercise &&
      !customOptions.some(
        (x) => x.id === AUDIENCE_EXPORT_TYPES.MAGNITE_CUSTOM_SEGMENT
      )
    ) {
      customOptions.push({
        id: AUDIENCE_EXPORT_TYPES.MAGNITE_CUSTOM_SEGMENT,
        label: 'Magnite',
        icon: MagniteIcon,
      });
    }

    if (
      uiAudienceManagerInhouseOtsExportDemoExercise &&
      !customOptions.some(
        (x) => x.id === AUDIENCE_EXPORT_TYPES.YAHOO_DSP_CUSTOM_SEGMENT
      )
    ) {
      customOptions.push({
        id: AUDIENCE_EXPORT_TYPES.YAHOO_DSP_CUSTOM_SEGMENT,
        label: 'Yahoo DSP',
        icon: YahooIcon,
      });
    }
  };

  /**
   * Handle click on destination chip (either select or deselect)
   */
  const toggleDestinationChip = (chipId: string) => {
    const otsOptionNames = otsOptions
      .map((x) => x.id)
      .filter((x) => x !== 'all');

    // Check if the "Toggle All" option is selected
    if (chipId === 'all') {
      // Determine if all OTS options are currently selected
      const selectedOTS =
        selectedExportType?.filter((x) => otsOptionNames.includes(x)) || [];
      const isAllOTSSelected = selectedOTS.length === otsOptionNames.length;

      if (isAllOTSSelected) {
        // If all OTS options are selected, deselect all OTS options except 'all'
        const updatedSelection = selectedExportType
          .filter(
            (selectedChip: string) =>
              !otsOptions.some((option) => option.id === selectedChip)
          )
          .filter((selectedChip) => selectedChip); // Ensure there are no empty values

        // Update the selected export types without the OTS options
        handleSelectedExportType(updatedSelection);
      } else {
        // If not all OTS options are selected, select all OTS options (except 'all' itself)
        // Add all OTS options to the selection (ensure no duplicates)
        handleSelectedExportType([
          ...new Set([...selectedExportType, ...otsOptionNames]),
        ]);
      }
      return;
    }

    // Handle selecting/unselecting specific destination options
    const isDestinationSelected = selectedExportType.find(
      (selectedChip: string) => selectedChip === chipId
    );

    if (isDestinationSelected) {
      // If the chip is already selected, deselect it by filtering it out
      handleSelectedExportType(
        selectedExportType.filter(
          (selectedChip: string) => selectedChip !== chipId
        )
      );
    } else {
      // If the chip is not selected, we need to ensure only one custom option is active at a time
      const customOpt = customOptions.map((x) => x.id);
      const currentlySelectedCustomExport = selectedExportType.find(
        (selectedChip) => customOpt.includes(selectedChip)
      );

      let updatedExportTypes = selectedExportType;

      // If a custom export type is already selected, remove it before adding the new one
      if (currentlySelectedCustomExport) {
        updatedExportTypes = updatedExportTypes.filter(
          (selectedChip: string) =>
            selectedChip !== currentlySelectedCustomExport
        );
      }

      // Add the new destination chip to the selected export types
      handleSelectedExportType([...updatedExportTypes, chipId]);
    }
  };

  /**
   * Retrive className if a destination chip is selected or not
   */
  const getDestinationChipClass = (id: string) => {
    if (id === 'all') {
      const otsOpt = otsOptions.map((x) => x.id).filter((x) => x !== 'all');
      const selectedOTS =
        selectedExportType?.filter((x) => otsOpt.includes(x)) || [];
      const isAllOTSSelected = selectedOTS.length === otsOpt.length;

      return isAllOTSSelected
        ? classes.destinationSelectedChip
        : classes.destinationChip;
    }
    return selectedExportType.includes(id)
      ? classes.destinationSelectedChip
      : classes.destinationChip;
  };

  /**
   * Map formData value with the corresponding json eliment
   */
  const mapSegmentFormData = (sagmentId, formJson, exportType) => {
    const mappedFormJson = formJson;
    const mappedFormData = formData?.find((x) => x.id === sagmentId);
    for (const formField of mappedFormJson) {
      if (formField.column) {
        formField.value = mappedFormData[formField.column] || '';
      }

      switch (formField.column) {
        case 'partnerID': {
          formField.disabled = mappedFormData.advertiserID ? true : false;
          break;
        }
        case 'advertiserID': {
          formField.disabled = mappedFormData.partnerID ? true : false;
          break;
        }
        case 'platformDestination': {
          formField.options = liveRampIntegrations;
          break;
        }
        case 'advertiserDestination': {
          if (mappedFormData.platformDestination) {
            const distributors = liveRampDistributionManagers
              .filter(
                (manager: any) =>
                  manager.integrationId ===
                  mappedFormData.platformDestination.id
              )
              .map((x) => {
                return {
                  label: x.distributionManagerName,
                  id: x.distributionManagerId,
                };
              });

            formField.options = distributors;
            formField.disabled = false;
          } else {
            formField.options = liveRampDistributionManagers;
            formField.disabled = true;
          }
          break;
        }
        default:
          break;
      }
    }

    return JSON.parse(JSON.stringify(mappedFormJson));
  };

  /**
   * Check control's visibility based on exprort_type
   */
  const { isCPM, isDesciption, isPercentageOfMedia } = useMemo(() => {
    const {
      OTS_SEGMENT,
      CUSTOM_SEGMENT,
      LIVERAMP_CUSTOM_SEGMENT,
      PUBMATIC_OTS_SEGMENT,
      PUBMATIC_CUSTOM_SEGMENT,
      OPENX_OTS_SEGMENT,
      OPENX_CUSTOM_SEGMENT,
    } = AUDIENCE_EXPORT_TYPES;
    if (
      selectedExportType.includes(OTS_SEGMENT) ||
      selectedExportType.includes(CUSTOM_SEGMENT) ||
      selectedExportType.includes(LIVERAMP_CUSTOM_SEGMENT)
    )
      return { isCPM: true, isPercentageOfMedia: true, isDesciption: true };
    else if (
      selectedExportType.includes(PUBMATIC_OTS_SEGMENT) ||
      selectedExportType.includes(PUBMATIC_CUSTOM_SEGMENT)
    )
      return { isCPM: true, isPercentageOfMedia: false, isDesciption: true };
    else if (
      selectedExportType.includes(OPENX_OTS_SEGMENT) ||
      selectedExportType.includes(OPENX_CUSTOM_SEGMENT)
    )
      return { isCPM: false, isPercentageOfMedia: false, isDesciption: false };

    return { isCPM: true, isPercentageOfMedia: true, isDesciption: true };
  }, [selectedExportType]);

  const {
    isValid: isDesciptionValid,
    isRequired: isDesciptionRequied,
    validationMessage: descriptionErrorMessage,
  } = isDescriptionValid(selectedExportType, description);

  const { perOfMediaLabel } = useMemo(() => {
    if (
      (selectedExportType.includes(
        AUDIENCE_EXPORT_TYPES.LIVERAMP_CUSTOM_SEGMENT
      ) ||
        selectedExportType.includes(
          AUDIENCE_EXPORT_TYPES.LIVERAMP_OTS_SEGMENT
        )) &&
      (selectedExportType.includes(AUDIENCE_EXPORT_TYPES.OTS_SEGMENT) ||
        selectedExportType.includes(AUDIENCE_EXPORT_TYPES.CUSTOM_SEGMENT))
    ) {
      return {
        perOfMediaLabel: `${TRADE_DESK_SEGMENT_FIELD_NAME.PERCENT_OF_MEDIA} / ${LIVERAMP_SEGMENT_FIELD_NAME.PROGRAMMATIC_PERCENT_OF_MEDIA} *`,
      };
    }
    if (
      selectedExportType.includes(
        AUDIENCE_EXPORT_TYPES.LIVERAMP_CUSTOM_SEGMENT
      ) ||
      selectedExportType.includes(AUDIENCE_EXPORT_TYPES.LIVERAMP_OTS_SEGMENT)
    ) {
      return {
        perOfMediaLabel:
          LIVERAMP_SEGMENT_FIELD_NAME.PROGRAMMATIC_PERCENT_OF_MEDIA,
      };
    }

    return {
      perOfMediaLabel: TRADE_DESK_SEGMENT_FIELD_NAME.PERCENT_OF_MEDIA,
    };
  }, [selectedExportType]);

  const isAudienceLoading = audienceLoading?.state === LoadingStates.LOADING;
  if (isAudienceLoading) return <LoadingAudience />;

  return (
    <>
      <Grid container spacing={0} display="flex">
        <Grid item xs={12} className={classes.tradeDeskDataSegmentLabel}>
          {ADD_AUDIENCE_DETAILS}
        </Grid>
        <Grid
          container
          xs={6}
          display="flex"
          flexDirection="column"
          className={classes.segmentInputFieldsLeft}
        >
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <TextField
                onChange={(e) => onChange('segmentName', e.target.value)}
                value={segmentName}
                fullWidth
                id="segmentName"
                label="Segment Name"
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={!isSegmentNameValid}
                helperText={inValidSegmentNameErrorMessage}
                required
              />
            </Grid>
          </Grid>

          <Grid container spacing={0} style={{ marginTop: 30 }}>
            {isCPM && (
              <>
                <Grid item xs={5}>
                  <TextField
                    onChange={(e) => onChange('cpm', e.target.value)}
                    value={cpm}
                    fullWidth
                    id="cpm"
                    label={TRADE_DESK_SEGMENT_FIELD_NAME.CPM}
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={2} />
              </>
            )}
            {isPercentageOfMedia && (
              <Grid item xs={5}>
                <TextField
                  onChange={(e) =>
                    onChange('percentageOfMedia', e.target.value)
                  }
                  value={percentageOfMedia}
                  fullWidth
                  id="percentageOfMedia"
                  label={perOfMediaLabel}
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required={
                    !(
                      selectedExportType.includes(
                        AUDIENCE_EXPORT_TYPES.LIVERAMP_CUSTOM_SEGMENT
                      ) ||
                      selectedExportType.includes(
                        AUDIENCE_EXPORT_TYPES.LIVERAMP_OTS_SEGMENT
                      )
                    )
                  }
                />
              </Grid>
            )}
          </Grid>
          {isDesciption && (
            <Grid container spacing={0} style={{ marginTop: 30 }}>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => onChange('description', e.target.value)}
                  value={description}
                  fullWidth
                  id="description"
                  label={TRADE_DESK_SEGMENT_FIELD_NAME.DESCRIPTION}
                  type="text"
                  required={isDesciptionRequied}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!isDesciptionValid}
                  helperText={descriptionErrorMessage}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          xs={6}
          display="flex"
          flexDirection="column"
          className={classes.segmentInputFieldsRight}
        >
          {exportDynamicFields
            ?.filter((x) => x?.formJSON?.length > 0)
            .map((segment, i) => {
              return (
                <>
                  <p
                    className={classes.audienceFieldTitle}
                    style={{
                      borderTop: i == 0 ? '' : '1px solid lightGray',
                      marginTop: i == 0 ? 0 : 22,
                      paddingTop: i == 0 ? 0 : 12,
                    }}
                  >
                    {`Additional ${
                      AUDIENCE_EXPORT_FIELD_TITLES[segment.exportType]
                    } Requirements`}
                  </p>
                  <Grid container spacing={0}>
                    <DynamicForm
                      config={mapSegmentFormData(
                        segment.id,
                        segment.formJSON,
                        segment.exportType
                      )}
                      onChange={(id, value) => onChange(id, value, segment.id)}
                    />
                  </Grid>
                </>
              );
            })}
        </Grid>
        {isSelectAudienceVisible && (
          <>
            <div className={classes.divider} />
            <div className={classes.exportContainer}>
              <Grid item xs={12} className={classes.subtitles}>
                {EXPORT_MODELED_AUDIENCES}
              </Grid>
              <Grid container spacing={0}>
                {uiAudienceManagerInhouseExportModeledOtsAudienceVisible && (
                  <Grid item xs={12} className={classes.destinations}>
                    <div className={classes.destinationLabel}>
                      <b>OTS:</b>
                    </div>
                    {otsOptions.map((x) => (
                      <div
                        key={x.id}
                        onClick={() => toggleDestinationChip(x.id)}
                        className={getDestinationChipClass(x.id)}
                      >
                        {x.icon && (
                          <img
                            src={x.icon}
                            alt="ttd-icon"
                            style={{ height: 15, width: 15 }}
                          />
                        )}
                        <span>{x.label}</span>
                      </div>
                    ))}
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  className={classes.destinations}
                  style={{
                    marginTop:
                      uiAudienceManagerInhouseExportModeledOtsAudienceVisible
                        ? 25
                        : 0,
                  }}
                >
                  <div className={classes.destinationLabel}>
                    <b>Custom:</b>
                  </div>
                  {customOptions.map((x) => (
                    <div
                      key={x.id}
                      onClick={() => toggleDestinationChip(x.id)}
                      className={getDestinationChipClass(x.id)}
                    >
                      <img
                        src={x.icon}
                        alt="liveramp-icon"
                        style={{ height: 15, width: 15 }}
                        className=""
                      />

                      <span>{x.label}</span>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </div>
          </>
        )}
      </Grid>
    </>
  );
};

export default InAudienceExport;
