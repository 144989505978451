import { NormalizedObjects } from 'interfaces/entities';
import { getAllIds } from './allIds';
import { getById } from './byId';

export function getNormalizedObject(
  arrayOfObjects: Array<any>,
  idIdentifier: string,
  previousNormalizedObject?: NormalizedObjects<any> | null | undefined
) {
  const normalizedObject = {
    byId: {},
    allIds: [],
  };
  normalizedObject.byId = getById(
    arrayOfObjects,
    idIdentifier,
    previousNormalizedObject
  );
  normalizedObject.allIds = getAllIds(
    arrayOfObjects,
    idIdentifier,
    previousNormalizedObject
  );
  return normalizedObject;
}

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
