import AudienceManagerSaga from 'pages/audienceManager/saga';
import AudienceSaga from 'pages/audienceManager/sagas/audiences';
import CustomAudienceManagerSaga from 'pages/customAudiences/saga';
import GroupSaga from 'pages/audienceManager/sagas/groups';
import LoginSaga from 'pages/auth/saga';
import NotificationsSaga from 'pages/notifications/sagas/notifications';
import OfferAudiencesSaga from 'pages/offerWallManager/sagas/audiences';
import OfferGroupSaga from 'pages/offerWallManager/sagas/groups';
import OffersSaga from 'pages/offerWallManager/sagas/offers';
import SalesLiftStudiesGroupsSaga from 'pages/salesLiftStudyManager/sagas/groups';
import SalesLiftStudiesSaga from 'pages/salesLiftStudyManager/sagas/salesLifts';
import SearchSaga from 'pages/audienceManager/searchSaga';
import { all, fork } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([
    fork(AudienceManagerSaga),
    fork(AudienceSaga),
    fork(CustomAudienceManagerSaga),
    fork(GroupSaga),
    fork(LoginSaga),
    fork(NotificationsSaga),
    fork(OfferAudiencesSaga),
    fork(OfferGroupSaga),
    fork(OffersSaga),
    fork(SalesLiftStudiesGroupsSaga),
    fork(SalesLiftStudiesSaga),
    fork(SearchSaga),
  ]);
}
