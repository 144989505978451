import CreateAudienceDropdown from '../../components/CreateAudienceDropdown';
import Field from 'components/Field';
import React, { useEffect, useMemo, useState } from 'react';
import _debounce from 'lodash/debounce';
import nanoid from 'utils/nanoid';
import {
  Audience,
  ToggleGroupNameDialogAction,
  addAudience,
  fetchEmails,
  fetchGroups,
  loadOriginalAudience,
  searchAudiences,
  setGroupDeletingState,
  setGroupSavingState,
  toggleGroupNameDialog,
  updateCurrentAudience,
  updateCurrentGroup,
} from './slice';
import { Button, IconButton } from '@klover/attain-design-system';
import { Formik } from 'formik';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory, useLocation } from 'react-router-dom';
import * as Styled from './index.styles';

// Material UI Components
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import GroupIcon from '@material-ui/icons/Group';
import HistoryIcon from '@mui/icons-material/History';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@material-ui/core/Typography';
import { CaretDown, Plus, Users } from '@phosphor-icons/react';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

// Page Components
import AudienceStatusHeader from './audienceStatus';
import CircleIcon from '@mui/icons-material/Circle';
import CopyPopover from 'components/CopyPopover';
import DataTable from 'components/DataTable/DataTable';
import ExportHistroryDialog from './exportHistoryDialog';
import GroupDeleteDialog from './groupDeleteDialog';
import GroupName from './groupName';
import GroupNameDialog from './groupNameDialog';
import Header from 'components/Header';
import Link from 'components/Link';
import TextFieldSearch from 'components/TextFieldSearch/TextFieldSearch';
import moment from 'moment';
import tokens from 'tokens';
import {
  AUDIENCE_EST_SIZE_TOOLTIP_HEADER,
  AUDIENCE_LAL_MODAL_TYPE,
  AUDIENCE_SELECT_EMAIL_AUTO_COMPLETE,
  AudienceDefaultExports,
  AudienceEstSizeLabel,
  AudienceSizeHistoryLabel,
  ENUM_SYSTEM_USED,
} from '../../constants';
import {
  AUDIENCE_MANAGER,
  AUDIENCE_MANAGER_COLUMN_TITLES,
  CREATE_AUDIENCE,
  CREATE_GROUP,
  SELECT_GROUP,
} from '../../content';
import {
  ENUM_AUDIENCE_EXPORT_STATUS,
  getAudienceAndPlatformTypeStatus,
} from './exportHistoryDialog/exportHistoryUtils';
import { Popover } from '@mui/material';
import { RowingOutlined } from '@mui/icons-material';
import { getAudienceExportStatus } from 'utils/audienceExportStatus';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    createGroup: {
      backgroundColor: tokens.colorButtonLight,
      bottom: 0,
      height: 68,
      padding: 16,
      position: 'fixed',
      width: 248,
    },
    divider: {
      height: 2,
    },
    groupList: {
      marginBottom: 'auto',
      maxHeight: '500px',
      minHeight: '400px',
      overflow: 'scroll',
    },
    header: {
      display: 'flex',
      height: '80px',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: '100%',
    },
    noAudiences: {
      minHeight: '100%',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      height: '80vh',
    },
    searchAudience: {
      margin: 16,
    },
    searchGroup: {
      margin: 8,
      marginTop: 16,
    },
    verticalDivider: {
      borderRight: '2px solid rgb(232 232 232)',
    },
    audienceName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      cursor: 'pointer',
    },
    audienceStatusCol: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minWidth: 50,
      display: 'inline-block',
      fontSize: 16,
      fontFamily: 'Calibre-Regular, sans-serif',
    },
    createdBy: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 200,
      display: 'inline-block',
      fontSize: 16,
    },
    audienceStatus: {
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
      top: '1px',
      position: 'relative',
    },
    tableCellText: {
      fontSize: '14px',
    },
    createAudienceButton: {
      fontSize: '16px !important',
    },
    audienceType: {
      fontSize: 16,
      color: '#000000d9',
      lineHeight: '20px',
      fontFamily: 'Calibre-Regular, sans-serif',
    },
    platformType: {
      fontSize: 14,
      color: '#0000008c',
      fontFamily: 'Calibre-Regular, sans-serif',
    },
    audienceSizeStatus: {
      fontSize: 14,
      color: '#0000008c',
      lineHeight: '20px',
      fontWeight: 500,
    },
    audienceSizeIcon: {
      color: 'gray',
      padding: '2px',
      marginTop: '-1px',
    },
    totalSize: {
      fontWeight: 500,
      lineHeight: '20px',
    },
    infoIcon: {
      color: 'gray',
      padding: '2px',
      marginTop: '1px',
      cursor: 'pointer',
    },
    tooltipContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '5px 15px 0px',
      border: '1px solid black',
      backgroundColor: 'white',
      borderRadius: '5px',
      color: 'black',
    },
    tooltipHeader: {
      fontWeight: 'bold',
      fontSize: '16px',
      paddingBottom: '5px',
      lineHeight: '18px',
      fontFamily: 'Calibre-Regular, sans-serif',
    },
    tooltipItemHeader: {
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '16px',
      fontFamily: 'Calibre-Regular, sans-serif',
    },
    tooltipItemText: {
      color: 'gray',
      fontSize: '14px',
      lineHeight: '16px',
      fontFamily: 'Calibre-Regular, sans-serif',
    },
    popover: {
      '& > div': {
        boxShadow: 'none !important',
      },
    },
    popoverFooter: {
      fontSize: '12px',
      fontStyle: 'italic',
      color: 'gray',
      fontFamily: 'Calibre-Regular, sans-serif',
      textAlign: 'end',
    },
    noAudience: {
      fontSize: 20,
      fontFamily: 'Calibre-Regular, sans-serif',
    },
    noAudienceSubText: {
      fontSize: 16,
      fontFamily: 'Calibre-Regular, sans-serif',
    },
  })
);

const inputs = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        maxHeight: 48,
      },
    },
    MuiFormLabel: {
      root: {
        maxHeight: 48,
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: -5,
        '&$focused': {
          marginTop: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: 48,
      },
      input: {
        borderRadius: 10,
        alignSelf: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
      input: {
        padding: '12px 14px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 10,
      },
    },
    MuiPopover: {
      overrides: {
        Paper: {
          boxShadow: 'none',
        },
      },
    },
  },
});

const AudienceManager = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [allGroups, setAllGroups] = useState(false);
  const [oprations, setOprations] = useState({
    page: 0,
    rowsPerPage: 25,
    sortBy: 'updatedAt',
    sortOrder: 'DESC',
    search: '',
    email: searchParams.get('email') || 'all',
    groupId: searchParams.get('groupId') || 'all',
  });
  const { groupId } = oprations;

  useEffect(() => {
    dispatch(fetchEmails());
    dispatch(fetchGroups());
  }, []);

  const debouncedSearch = _debounce((searchParams) => {
    dispatch(searchAudiences(searchParams));
  }, 500);

  useEffect(() => {
    debouncedSearch(oprations);
    return () => {
      debouncedSearch.cancel();
    };
  }, [
    oprations.page,
    oprations.rowsPerPage,
    oprations.sortBy,
    oprations.sortOrder,
    oprations.groupId,
    oprations.search,
    oprations.email,
  ]);

  useEffect(() => {
    if (openGroupNameDialog) return;
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('groupId');
    const currGroup = audienceReducer.entities.groups.byId[id];
    if (currGroup) {
      dispatch(updateCurrentGroup(currGroup));
    } else {
      setAllGroups(true);
    }
  }, [openGroupNameDialog]);

  useEffect(() => {
    if (groupId && groupId !== 'all') {
      const currentGroup = audienceReducer.entities.groups.byId[groupId];
      if (currentGroup) {
        dispatch(updateCurrentGroup(currentGroup));
        setAllGroups(false);
      }
    } else {
      setAllGroups(true);
      dispatch(updateCurrentGroup(null));
    }

    window.history.replaceState(
      null,
      '',
      `${location.pathname}?groupId=${groupId}&email=${oprations.email}`
    );
  }, [groupId]);

  const audienceReducer = useAppSelector((state) => {
    return state.audienceReducer;
  });

  const currentGroup = useAppSelector(
    (state) => state.audienceReducer.currentGroup
  );
  const openGroupDeleteDialog = useAppSelector(
    (state) => state.audienceReducer.ui.openGroupDeleteDialog
  );
  const openGroupNameDialog = useAppSelector(
    (state) => state.audienceReducer.ui.openGroupNameDialog
  );

  const audiences = useAppSelector((state) => {
    return state.audienceReducer.entities.audiences.allIds.map((id) => {
      return state.audienceReducer.entities.audiences.byId[id];
    });
  });

  const groups = useAppSelector((state) => {
    return state.audienceReducer.entities.groups.allIds
      .map((id) => state.audienceReducer.entities.groups.byId[id])
      .filter((group) => {
        if (name !== '') {
          return group.name.toLowerCase().includes(name);
        } else {
          return true;
        }
      });
  });

  const emails = useAppSelector(
    (state) => state.audienceReducer.entities?.emails
  );

  const groupLoading = useAppSelector(
    (state) => state.audienceReducer.ui.groupLoading
  );

  const groupsLoading = useAppSelector(
    (state) => state.audienceReducer.ui.groupsLoading
  );

  const isEmailLoading = useAppSelector(
    (state) => state.audienceReducer.ui.emailIsLoading.state === 'loading'
  );

  const addAudienceHandler = (option) => {
    dispatch(loadOriginalAudience(null));
    dispatch(setGroupDeletingState({ state: 'idle' }));
    dispatch(setGroupSavingState({ state: 'idle' }));
    dispatch(addAudience({ ...newAudience, lalModelType: option }));
  };

  const handleCreateGroup = () => {
    const actionProps: ToggleGroupNameDialogAction = {
      open: true,
    };
    dispatch(toggleGroupNameDialog(actionProps));
  };

  const handleShowGroup = (id) => {
    setOprations({ ...oprations, groupId: id, page: 0 });
    setAllGroups(id ? false : true);
  };

  const isAudiencesLoading = groupLoading.state === 'loading';
  const isGroupsLoading = groupsLoading.state === 'loading';

  const newAudience: Audience = {
    id: nanoid(),
    name: '',
    totalSize: 0,
    dimensions: [],
    dimensionGroups: [],
    createdAt: 0,
    updatedAt: 0,
  };

  const groupsOptions = useMemo(() => {
    const data = [
      { label: 'All Groups', id: 'all' },
      ...groups
        ?.sort((a, b) => b.updated_at - a.updated_at)
        ?.map((a) => {
          return { label: a.name, id: `${a.id}` };
        }),
    ];
    return data;
  }, [groups]);

  const handleChangePage = (newPage: number) => {
    setOprations({
      ...oprations,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (value: number) => {
    const newPageSize = parseInt(value, 10);
    setOprations({
      ...oprations,
      page: 0,
      rowsPerPage: newPageSize,
    });
  };

  const handleOnSortChange = (sortInfo) => {
    let sortOrder = 'DESC';
    if (oprations.sortBy === sortInfo.column) {
      sortOrder = oprations.sortOrder == 'ASC' ? 'DESC' : 'ASC';
    }

    setOprations({
      ...oprations,
      sortBy: sortInfo.column,
      sortOrder: sortOrder,
    });
  };

  const handleMenuItemClick = (option: string) => {
    history.push(`/main/audience-manager/editor`);
    addAudienceHandler(option);
  };

  return (
    <>
      <Box className={classes.root}>
        <Header
          title={AUDIENCE_MANAGER}
          centerBorder={true}
          center={!allGroups ? currentGroup && <GroupName /> : null}
          action={
            <>
              {currentGroup && !allGroups ? (
                <CreateAudienceDropdown
                  handleMenuItemClick={handleMenuItemClick}
                  audienceType={ENUM_SYSTEM_USED.STANDARD_AUDIENCE}
                />
              ) : null}
            </>
          }
        />

        <Box height="100%">
          <Styled.SearchArea>
            <>
              <ThemeProvider theme={inputs}>
                <Button
                  variant="contained"
                  onClick={handleCreateGroup}
                  startIcon={<Plus />}
                  style={{ fontSize: '16px' }}
                >
                  {CREATE_GROUP}
                </Button>
              </ThemeProvider>

              <Formik>
                {() => (
                  <Styled.Form onSubmit={(e) => e.preventDefault()}>
                    <Field
                      name="searchAudiences"
                      placeholder="Search Audiences"
                      value={oprations.search}
                      style={{ height: 44, width: 415 }}
                      onChange={(e) => {
                        setOprations({
                          ...oprations,
                          search: e.target.value,
                          page: 0,
                        });
                      }}
                    />
                  </Styled.Form>
                )}
              </Formik>
            </>

            <Styled.SelectAutoCompleteGroup
              disablePortal
              id="select-audience-group"
              options={groupsOptions}
              disabled={isGroupsLoading}
              popupIcon={<CaretDown />}
              value={
                groupsOptions.find((a) => `${a?.id}` === `${groupId}`)?.label
              }
              renderInput={(params) => (
                <TextFieldSearch
                  {...params}
                  placeholder={SELECT_GROUP}
                  variant="outlined"
                />
              )}
              onChange={(e, newValue) => {
                if (newValue?.id) {
                  setOprations({
                    ...oprations,
                    page: 0,
                    groupId: newValue?.id?.toString(),
                  });
                  setAllGroups(newValue?.id ? false : true);
                }
              }}
              renderOption={(props, option) => (
                <Styled.SelectAutoCompleteGroupOptions>
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                </Styled.SelectAutoCompleteGroupOptions>
              )}
            />
            <Styled.SelectAutoCompleteEmail
              disablePortal
              id="select-audience-email"
              options={emails}
              disabled={isEmailLoading}
              popupIcon={<CaretDown />}
              value={emails.find((a) => a?.id === `${oprations.email}`)?.label}
              renderInput={(params) => (
                <TextFieldSearch
                  {...params}
                  placeholder={AUDIENCE_SELECT_EMAIL_AUTO_COMPLETE}
                  variant="outlined"
                />
              )}
              onChange={(e, newValue: any) => {
                if (newValue?.id) {
                  setOprations({
                    ...oprations,
                    page: 0,
                    email: newValue?.id,
                  });
                  if (newValue?.id !== oprations.email) {
                    window.history.replaceState(
                      null,
                      '',
                      groupId
                        ? `${location.pathname}?groupId=${groupId}&email=${newValue?.id}`
                        : `${location.pathname}?email=${newValue?.id}`
                    );
                  }
                }
              }}
              renderOption={(props, option) => (
                <Styled.SelectAutoCompleteGroupOptions>
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                </Styled.SelectAutoCompleteGroupOptions>
              )}
            />
          </Styled.SearchArea>
          <AudienceTable
            loading={isAudiencesLoading}
            audiences={audiences}
            selectedGroupId={groupId}
            groups={groups}
            handleShowGroup={handleShowGroup}
            oprations={oprations}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleOnSortChange={handleOnSortChange}
          />
        </Box>
      </Box>

      <GroupDeleteDialog open={openGroupDeleteDialog} />
      <GroupNameDialog open={openGroupNameDialog} />
    </>
  );
};

interface Props {
  audiences: Audience[];
  groups: any;
  handleShowGroup: any;
  selectedGroupId: any;
  loading: boolean;
  oprations: {
    page: number;
    rowsPerPage: number;
    sortBy: string;
    sortOrder: string;
  };
  handleChangePage: () => {};
  handleChangeRowsPerPage: () => {};
  handleOnSortChange: () => {};
}

/**
 * Est Audience size details popup which helps to check audience count with respective export stage
 */
export const AudienceEstSizePopup = ({ audience, exportHistory }) => {
  const classes = useStyles();
  let exportedCount = 'Pending',
    platformUsersCount = 'Pending',
    platformUsersCountActiveIds = 'Pending',
    uuid2Count = 'Pending',
    modelCount = 'Pending',
    updatedAt = moment(
      new Date(Number(audience.updatedAt || audience.updatedat)) //updatedAt - Audience Manager | updatedat - Custom Audience
    ).format('MM/DD/YYYY HH:mm:ss');
  const audienceSize =
    audience?.totalSize?.toLocaleString() || //Audience Manager
    audience?.size?.toLocaleString() || //Custom Audience
    'Pending';
  let seedCount = audienceSize;
  let platformIntegrated = '';

  if (exportHistory) {
    const latestExport = exportHistory;
    platformIntegrated = latestExport?.platformIntegrated;
    const seededCount =
      latestExport?.seededUsersCount?.toLocaleString() || 'Pending';
    exportedCount =
      latestExport?.exportedUsersCount?.toLocaleString() || 'Pending';
    modelCount =
      latestExport?.lookalikeUsersCount?.toLocaleString() || 'Pending';
    platformUsersCount =
      latestExport?.platformUsersCount?.toLocaleString() || 'Pending';
    platformUsersCountActiveIds =
      latestExport?.platformUsersCountActiveIds?.toLocaleString() || 'Pending';
    uuid2Count = latestExport?.uid2UsersCount?.toLocaleString() || 'Pending';
    switch (latestExport.exportStatus) {
      case ENUM_AUDIENCE_EXPORT_STATUS.SENT:
        {
          seedCount = seededCount;
          if (latestExport?.timestampStatusExportStarted?.value) {
            const time = latestExport?.timestampStatusExportStarted?.value;
            updatedAt = moment(time).format('MM/DD/YYYY HH:mm:ss');
          }
        }
        break;
      case ENUM_AUDIENCE_EXPORT_STATUS.MODELING_COMPLETED:
        {
          seedCount = seededCount;
          if (latestExport?.timestampStatusReceivedFromTransunion?.value) {
            const time =
              latestExport?.timestampStatusReceivedFromTransunion?.value;
            updatedAt = moment(time).format('MM/DD/YYYY HH:mm:ss');
          }
        }
        break;
      case ENUM_AUDIENCE_EXPORT_STATUS.LIVE_IN_TTD:
        {
          seedCount = seededCount;
          if (latestExport?.timestampPlatformUsers?.value) {
            const time = latestExport?.timestamp_platform_users?.value;
            updatedAt = moment(time).format('MM/DD/YYYY HH:mm:ss');
          } else if (latestExport?.timestampStatusSentToTransunion?.value) {
            const time = latestExport?.timestampStatusSentToTransunion?.value;
            updatedAt = moment(time).format('MM/DD/YYYY HH:mm:ss');
          }
        }
        break;
      case ENUM_AUDIENCE_EXPORT_STATUS.LATE:
        seedCount = seededCount;
        break;
      default:
        break;
    }
  }

  let data = [];
  if (platformIntegrated === 'trade_desk') {
    data = [
      {
        label: AudienceSizeHistoryLabel.SEED_AUDIENCE,
        value: seedCount,
      },
      {
        label: AudienceSizeHistoryLabel.EXPORTED_AUDIENCE,
        value: exportedCount,
      },
      {
        label: AudienceSizeHistoryLabel.TU_MODEL,
        value: modelCount,
      },
      {
        label: AudienceSizeHistoryLabel.UUID,
        value: uuid2Count,
      },
      {
        label: AudienceSizeHistoryLabel.TREAD_DESK,
        subItems: [
          {
            label: AudienceSizeHistoryLabel.RECEIVED_IDS,
            value: platformUsersCount,
          },
          {
            label: AudienceSizeHistoryLabel.ACTIVE_IDS,
            value: platformUsersCountActiveIds,
          },
        ],
      },
    ];
  } else {
    data = [
      {
        label: AudienceSizeHistoryLabel.SEED_AUDIENCE,
        value: seedCount,
      },
      {
        label: AudienceSizeHistoryLabel.EXPORTED_AUDIENCE,
        value: exportedCount,
      },
      {
        label: AudienceSizeHistoryLabel.TU_MODEL,
        value: modelCount,
      },
      {
        label: AudienceSizeHistoryLabel.TREAD_DESK,
        subItems: [
          {
            label: AudienceSizeHistoryLabel.RECEIVED_IDS,
            value: platformUsersCount,
          },
          {
            label: AudienceSizeHistoryLabel.ACTIVE_IDS,
            value: platformUsersCountActiveIds,
          },
        ],
      },
    ];
  }

  return (
    <div className={classes.tooltipContainer}>
      <div className={classes.tooltipHeader}>
        {AUDIENCE_EST_SIZE_TOOLTIP_HEADER}
      </div>
      <ul style={{ marginLeft: '-25px' }}>
        {data.map((x) => {
          return (
            <li key={x.label}>
              <span className={classes.tooltipItemHeader}>{x.label}</span>
              {x.value ? ' - ' : ''}
              {x.value && (
                <span className={classes.tooltipItemText}>
                  <CopyPopover value={x.value} />
                </span>
              )}

              {x?.subItems &&
                x?.subItems?.length > 0 && ( // Check for sub-items
                  <ul style={{ marginLeft: '-15px' }}>
                    {x.subItems.map((subItem) => (
                      <li key={subItem.label}>
                        <span className={classes.tooltipItemHeader}>
                          {subItem.label}
                        </span>
                        {' - '}
                        <span className={classes.tooltipItemText}>
                          <CopyPopover value={subItem.value} />
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
            </li>
          );
        })}
      </ul>
      <div className={classes.popoverFooter}>
        <span>Last Updated: {updatedAt} </span>
      </div>
    </div>
  );
};

/**
 * Helps to get estimated audience size and relative labels
 */
export const getAudienceEstSizeDetails = (
  audience: any,
  exportHistory: any
) => {
  let exportedCount = 'Pending',
    modelCount = 'Pending',
    uuid2Count = 'Pending',
    platformUsersCountActiveIds = 'Pending';
  const audienceSize =
    audience?.totalSize?.toLocaleString() ||
    audience?.size?.toLocaleString() ||
    'Pending';

  if (exportHistory) {
    const latestExport = exportHistory;
    const platformIntegrated = latestExport?.platformIntegrated;
    exportedCount =
      latestExport?.exportedUsersCount?.toLocaleString() || 'Pending';
    modelCount =
      latestExport?.lookalikeUsersCount?.toLocaleString() || 'Pending';
    uuid2Count = latestExport?.uid2UsersCount?.toLocaleString() || 'Pending';
    platformUsersCountActiveIds =
      latestExport?.platformUsersCountActiveIds?.toLocaleString() || 'Pending';

    switch (latestExport.exportStatus) {
      case ENUM_AUDIENCE_EXPORT_STATUS.SENT:
      case ENUM_AUDIENCE_EXPORT_STATUS.LATE:
        return {
          audinceSize: exportedCount,
          audienceExportLabel: AudienceEstSizeLabel.EXPORTED_AUDIENCE,
        };
      case ENUM_AUDIENCE_EXPORT_STATUS.MODELING_COMPLETED:
        if (platformIntegrated === 'trade_desk') {
          return {
            audinceSize: uuid2Count ? uuid2Count : modelCount,
            audienceExportLabel: uuid2Count
              ? AudienceEstSizeLabel.TTD_UUID2
              : AudienceEstSizeLabel.TU_MODEL,
          };
        } else {
          return {
            audinceSize: modelCount,
            audienceExportLabel: AudienceEstSizeLabel.TU_MODEL,
          };
        }
      case ENUM_AUDIENCE_EXPORT_STATUS.LIVE_IN_TTD:
        return {
          audinceSize: platformUsersCountActiveIds,
          audienceExportLabel: AudienceEstSizeLabel.TTD_AUDIENCE,
        };
      default:
        return {
          audinceSize: audienceSize,
          audienceExportLabel: AudienceEstSizeLabel.SEED_AUDIENCE,
        };
    }
  }

  return {
    audinceSize: audienceSize,
    audienceExportLabel: AudienceEstSizeLabel.SEED_AUDIENCE,
  };
};

const AudienceTable = ({
  audiences,
  handleShowGroup,
  oprations,
  handleChangePage,
  handleChangeRowsPerPage,
  handleOnSortChange,
  loading = true,
}: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { sortBy, sortOrder } = oprations;
  const [openExportHistoryDialog, setOpenExportHistoryDialog] = useState(false);
  const [currentAudienceHistory, setCurrentAudienceHistory] = useState([]);
  const [currentAudience, setCurrentAudience] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const states = useAppSelector((state) => {
    return state.audienceReducer.ui.states;
  });

  const handleShowAudience = (audience: Audience) => {
    dispatch(updateCurrentAudience(audience));
    dispatch(setGroupDeletingState({ state: 'idle' }));
    dispatch(setGroupSavingState({ state: 'idle' }));
  };

  const data = useMemo(
    () => audiences && audiences.filter((x) => x),
    [audiences]
  );

  const count =
    data.length > 0
      ? typeof data[0].totalcount === 'string'
        ? Number(data[0].totalcount)
        : data[0].totalcount
      : 1;

  const columns = [
    {
      title: AUDIENCE_MANAGER_COLUMN_TITLES.GROUP,
      column: 'groupId',
      isSorted: sortBy === 'groupId',
      sortOrder: sortOrder,
    },
    {
      title: AUDIENCE_MANAGER_COLUMN_TITLES.NAME,
      column: 'name',
      isSorted: sortBy === 'name',
      sortOrder: sortOrder,
      width: '550px',
    },
    {
      title: <AudienceStatusHeader />,
      column: 'exportStatus',
      width: '15%',
      isSortDisabled: true,
    },
    {
      title: AUDIENCE_MANAGER_COLUMN_TITLES.TYPE,
      column: 'exportHistory',
      width: '12%',
      isSortDisabled: true,
    },
    {
      title: AUDIENCE_MANAGER_COLUMN_TITLES.ESTIMATED_SIZE,
      column: 'totalSize',
      isSorted: sortBy === 'totalSize',
      sortOrder: sortOrder,
      width: '10%',
    },
    {
      title: AUDIENCE_MANAGER_COLUMN_TITLES.LAST_MODIFIED,
      column: 'updatedAt',
      isSorted: sortBy === 'updatedAt',
      sortOrder: sortOrder,
      width: '9%',
    },
  ];

  const formattedRows = data?.map((x) => {
    let exportHistory = [];
    if (x?.exportHistory?.length > 0) {
      exportHistory = x?.exportHistory.filter((x) =>
        AudienceDefaultExports.includes(x.exportType)
      );
    }
    const { audience_type, platform_type } = getAudienceAndPlatformTypeStatus(
      exportHistory || []
    );

    const latestExportHistory =
      exportHistory.length > 0 ? exportHistory[0] : null;

    const { audinceSize, audienceExportLabel } = getAudienceEstSizeDetails(
      x,
      latestExportHistory
    );
    const isInHouseExport =
      latestExportHistory?.lalModelType === AUDIENCE_LAL_MODAL_TYPE.ATTAIN;
    const inHouseModelingStatus = x.inHouseModelingStatus;
    return (
      <>
        <td>
          <Link
            to={`/main/audience-manager?groupId=${x.groupId}&email=all`}
            onClick={() => handleShowGroup(x.groupId)}
            disabled={
              states &&
              RowingOutlined &&
              RowingOutlined.id &&
              states[RowingOutlined.id]
                ? true
                : false
            }
          >
            {x.groupName}
          </Link>
        </td>
        <td>
          <Styled.AudienceName>
            <Link
              to={`/main/audience-manager/editor/${x?.id}`}
              onClick={() => handleShowAudience(x)}
            >
              {x?.name || null}
            </Link>
          </Styled.AudienceName>
        </td>
        <td>
          <Styled.AudienceStatus className={classes.audienceStatusCol}>
            <AudienceExportStatus
              isInHouseExport={isInHouseExport}
              latestExportHistory={latestExportHistory}
              inHouseModelingStatus={inHouseModelingStatus}
            />{' '}
            <IconButton
              onClick={() => {
                setCurrentAudienceHistory(x?.exportHistory || []);
                setCurrentAudience(x || {});
                setOpenExportHistoryDialog(true);
              }}
            >
              <HistoryIcon style={{ fontSize: '20px', cursor: 'pointer' }} />
            </IconButton>
          </Styled.AudienceStatus>
        </td>
        <td>
          <Styled.Type>
            <div className={classes.audienceType}>{audience_type}</div>
            <div className={classes.platformType}>{platform_type}</div>
          </Styled.Type>
        </td>
        <td>
          <Styled.ModeledSize>
            <div className={classes.totalSize}>{audinceSize}</div>
            <div style={{ display: 'flex' }}>
              <div className={classes.audienceSizeStatus}>
                {audienceExportLabel}
              </div>
              <div>
                <InfoIcon
                  aria-describedby="est-audience-size"
                  className={classes.infoIcon}
                  style={{ fontSize: '20px', cursor: 'pointer' }}
                  onClick={(e) => {
                    setCurrentAudience(x);
                    setAnchorEl(e.currentTarget);
                  }}
                />
                {anchorEl && currentAudience.id === x.id && (
                  <Popover
                    id="est-audience-size"
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    className={classes.popover}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                  >
                    <AudienceEstSizePopup
                      audience={x}
                      exportHistory={latestExportHistory}
                    />
                  </Popover>
                )}
              </div>
            </div>
          </Styled.ModeledSize>
        </td>
        <td>
          <Styled.LastModified>
            {x.updatedAt !== undefined && x.updatedAt !== 0 && (
              <>{new Date(Number(x.updatedAt)).toLocaleDateString('en-US')}</>
            )}
          </Styled.LastModified>
        </td>
      </>
    );
  });

  return (
    <>
      <ExportHistroryDialog
        isOpen={openExportHistoryDialog}
        setOpenDialog={setOpenExportHistoryDialog}
        data={currentAudienceHistory}
        normalAudience={currentAudience}
      />
      {loading === false && formattedRows.length === 0 ? (
        <NoAudiences />
      ) : (
        <div style={{ padding: '24px 32px', background: '#F0EFF1' }}>
          <DataTable
            loading={loading}
            columns={columns}
            rows={formattedRows || []}
            showRowsPerPageDropdown
            customRowsPerPageOptions={[25, 50, 100]}
            defaultRowsPerPage={25}
            onPaginationClick={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onSortChange={handleOnSortChange}
            totalRows={count}
          />
        </div>
      )}
    </>
  );
};

const NoAudiences = ({ search }) => {
  const classes = useStyles();

  return (
    <Box className={classes.noAudiences}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>{!search ? <Users size={26} /> : null}</Grid>
        <Grid item>
          <Typography className={classes.noAudience}>
            {search ? (
              'No audiences with this query.'
            ) : (
              <>This group doesn&apos;t have any audiences yet.</>
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            component="h4"
            variant="caption"
            className={classes.noAudienceSubText}
          >
            {!search ? (
              <>Start by clicking &quot;Create Audience&quot;.</>
            ) : null}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const NoCurrentGroup = () => {
  const classes = useStyles();

  return (
    <Box className={classes.noAudiences}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <GroupIcon />
        </Grid>
        <Grid item>
          <Typography component="h3" variant="h6">
            Create or select a group
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h4" variant="caption">
            Start by clicking &quot;Create Group&quot;.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const LoadingAudiences = () => {
  const classes = useStyles();

  return (
    <Box className={classes.noAudiences}>
      <CircularProgress color="primary" size={40} />
    </Box>
  );
};

export const AudienceExportStatus = (props: any) => {
  const {
    latestExportHistory = null,
    inHouseModelingStatus,
    isInHouseExport = false,
  } = props || {};
  const classes = useStyles();
  const isDefaultAudienceExport = AudienceDefaultExports.includes(
    latestExportHistory?.exportType
  );
  let status =
    latestExportHistory?.exportStatus || ENUM_AUDIENCE_EXPORT_STATUS.NOT_SENT;

  if (!latestExportHistory) {
    status = ENUM_AUDIENCE_EXPORT_STATUS.NOT_SENT;
  } else if (isInHouseExport) {
    if (inHouseModelingStatus && inHouseModelingStatus.status === 'ERROR') {
      status = ENUM_AUDIENCE_EXPORT_STATUS.ERROR;
    } else {
      status = ENUM_AUDIENCE_EXPORT_STATUS.SENT;
    }
  } else if (!isDefaultAudienceExport) {
    status =
      status === ENUM_AUDIENCE_EXPORT_STATUS.ERROR
        ? ENUM_AUDIENCE_EXPORT_STATUS.ERROR
        : ENUM_AUDIENCE_EXPORT_STATUS.SENT;
  }
  const { iconColor, tootipText } = getAudienceExportStatus(
    status,
    isInHouseExport
  );

  return iconColor ? (
    <span title={tootipText} className={classes.audienceStatus}>
      <CircleIcon style={{ fontSize: '10px', color: iconColor }} />
      {status || ENUM_AUDIENCE_EXPORT_STATUS.NOT_SENT}
    </span>
  ) : null;
};

export default AudienceManager;
