import _ from 'lodash';
import axios from 'axios';
import fromStringWithBreakOrCommaToArray from 'utils/fromStringWithBreakOrCommaToArray';
import {
  Offer,
  ZipUploadPayload,
  addNotification,
  clearOffers,
  createOffer,
  deleteOffer,
  deleteZipCodes,
  duplicateOffer,
  fetchOffer,
  fetchOffers,
  loadOriginalOffer,
  moveOffer,
  removeFromStates,
  removeOffer,
  saveOffer,
  searchOffers,
  setAllOffers,
  setGroupLoadingState,
  setInitialPlacementOffers,
  setOfferLoadingState,
  setOffersLoadingState,
  setUIState,
  setZipCount,
  setZipsFileUploadingState,
  toggleDuplicateOfferDialog,
  updateCurrentActiveOffers,
  updateCurrentGroup,
  updateCurrentOffer,
  updateCurrentTargeting,
  updateGroups,
  updateOfferZindex,
  updateOffers,
  updateStates,
  uploadZipsFile,
} from '../slice';
import { OfferCategories } from '../offerOptions/offerGoals';
import { PayloadAction } from '@reduxjs/toolkit';
import { PlacementId } from '../offerOptions/placementId';
import { StateNames } from '../validationObjects/states';
import { UploadStates } from 'constants/index';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { auth } from 'utils/firebase';

const API_ENDPOINT = `${process.env.API_BASE_ADDRESS}/offer-manager/offers`;
function handleCreateOffer(offer: Offer) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.post(API_ENDPOINT, {
      offer: offer,
      user: auth.currentUser?.email,
    });
  });
}

function handleDuplicateOffer(body: any) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.post(API_ENDPOINT + `/duplicate`, body);
  });
}

function handleFetchOffer(offer: Offer) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.get(API_ENDPOINT + `/${offer.offerid}`);
  });
}

function handleFetchOffers(isActive: boolean, placementId: number) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.get(
      API_ENDPOINT + `?isactive=${isActive}&placementid=${placementId}`
    );
  });
}

function handleFetchTargetedOffers(body) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.get(
      `${API_ENDPOINT}/target?${Object.keys(body)
        .map((key) => {
          return key + '=' + body[key];
        })
        .join('&')}`
    );
  });
}

function handleSearchOffers(query: any) {
  const {
    page,
    rowsPerPage,
    search,
    sortOrder,
    sortBy,
    groupId,
    isActive = null,
  } = query || {};
  const isStatusFilterApplied = _.isNull(isActive) ? false : true;
  const group = groupId === 'all' ? '' : groupId;
  const isActiveQuery = isStatusFilterApplied ? `&isactive=${isActive}` : '';
  let searchQuery = `${API_ENDPOINT}?search=${encodeURIComponent(
    search
  )}&page=${page}&rowsPerPage=${rowsPerPage}&sortBy=${sortBy}&sortOrder=${sortOrder}&groupId=${group}`;

  searchQuery = searchQuery.concat(isActiveQuery);

  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.get(searchQuery);
  });
}

function handleUpdateOffer(offer: Offer) {
  const filteredOffer = _.omit(offer, ['offerid', 'offergroupid']);
  return auth.currentUser
    .getIdTokenResult()
    .then((res) => {
      const authorizedAxiosInstance = axios.create({
        headers: {
          Authorization: `Bearer ${res.token}`,
        },
      });
      return authorizedAxiosInstance.put(API_ENDPOINT + `/${offer.offerid}`, {
        filteredOffer: filteredOffer,
        user: auth.currentUser?.email,
      });
    })
    .catch((error) => {
      const errorObject = { error };
      if (
        errorObject &&
        errorObject.error &&
        errorObject.error.response &&
        errorObject.error.response.data
      ) {
        throw errorObject.error.response.data;
      }
    });
}

function handleReorderOffers(offerIds: number[]) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.post(API_ENDPOINT + `/reorder`, {
      offerids: offerIds,
    });
  });
}

function handleDeleteOffer(offer: Offer) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.delete(API_ENDPOINT + `/${offer.offerid}`);
  });
}

const handleUploadZipsFile = (data: ZipUploadPayload) => {
  const { file, offerId, userEmail } = data;
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    const formData = new FormData();
    formData.append('file', file);
    formData.append('email', userEmail);
    formData.append('enctype', 'multipart/form-data');
    return authorizedAxiosInstance.post(
      API_ENDPOINT + `/${offerId}/zipsUpload`,
      formData
    );
  });
};

function handleDeleteZipCodes(payload: any) {
  const { offerId, user } = payload;
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.delete(
      API_ENDPOINT + `/${offerId}/zipsUpload?user=${user}`
    );
  });
}

function reformatOfferForAPI(updatedOffer: any) {
  const cloneOfUpdatedOffer = _.clone(updatedOffer);
  if (!cloneOfUpdatedOffer.isactive) {
    cloneOfUpdatedOffer.isactive = 0;
  }
  if (!cloneOfUpdatedOffer.ctaurl) {
    cloneOfUpdatedOffer.ctaurl = null;
    cloneOfUpdatedOffer.tuneofferid = null;
  }
  if (!cloneOfUpdatedOffer.zindex) {
    cloneOfUpdatedOffer.zindex = null;
  }
  const remainingSuccessUrls = cloneOfUpdatedOffer.successurl
    .filter((x) => x)
    .map((x) => x.split(' ').join(''));
  const remainingOnOfferSeenUrlToFire = cloneOfUpdatedOffer.onofferseenurltofire
    .filter((x) => x)
    .map((x) => x.split(' ').join(''));
  if (remainingSuccessUrls.length) {
    cloneOfUpdatedOffer.successurl = JSON.stringify(remainingSuccessUrls);
  } else {
    cloneOfUpdatedOffer.successurl = null;
  }
  if (remainingOnOfferSeenUrlToFire.length) {
    cloneOfUpdatedOffer.onofferseenurltofire = JSON.stringify(
      remainingOnOfferSeenUrlToFire
    );
  } else {
    cloneOfUpdatedOffer.onofferseenurltofire = null;
  }
  cloneOfUpdatedOffer.zips = fromStringWithBreakOrCommaToArray(
    cloneOfUpdatedOffer.zips
  );
  cloneOfUpdatedOffer.states = fromStringWithBreakOrCommaToArray(
    cloneOfUpdatedOffer.states
  );
  if (cloneOfUpdatedOffer.states) {
    cloneOfUpdatedOffer.states = cloneOfUpdatedOffer.states.map(
      (name: string) => {
        if (StateNames[name]) return StateNames[name];
        return name;
      }
    );
  }
  if (cloneOfUpdatedOffer.devices === 'ALL_DEVICES') {
    cloneOfUpdatedOffer.devices = null;
  }
  if (
    cloneOfUpdatedOffer.enddate &&
    typeof cloneOfUpdatedOffer.enddate !== 'number'
  ) {
    cloneOfUpdatedOffer.enddate = Date.parse(cloneOfUpdatedOffer.enddate);
  }
  if (
    cloneOfUpdatedOffer.startdate &&
    typeof cloneOfUpdatedOffer.startdate !== 'number'
  ) {
    cloneOfUpdatedOffer.startdate = Date.parse(cloneOfUpdatedOffer.startdate);
  }
  if (cloneOfUpdatedOffer.logourl) {
    cloneOfUpdatedOffer.logourl = cloneOfUpdatedOffer.logourl
      .split(' ')
      .join('');
  }
  if (cloneOfUpdatedOffer.ctaurl) {
    cloneOfUpdatedOffer.ctaurl = cloneOfUpdatedOffer.ctaurl.split(' ').join('');
  }
  if (cloneOfUpdatedOffer.mediarate) {
    cloneOfUpdatedOffer.mediarate = parseFloat(cloneOfUpdatedOffer.mediarate);
  }
  if (cloneOfUpdatedOffer.backgroundimgurl) {
    cloneOfUpdatedOffer.backgroundimgurl = cloneOfUpdatedOffer.backgroundimgurl
      .split(' ')
      .join('');
  }
  if (cloneOfUpdatedOffer.audiences) {
    cloneOfUpdatedOffer.audiences = cloneOfUpdatedOffer.audiences.map(
      ({ audienceid }) => audienceid
    );
  }

  if (!cloneOfUpdatedOffer.fullscreenimageurl) {
    cloneOfUpdatedOffer.fullscreenimageurl = null;
  }

  if (cloneOfUpdatedOffer.offertunegoals?.length > 0) {
    cloneOfUpdatedOffer.offertunegoals = cloneOfUpdatedOffer.offertunegoals.map(
      (goal) => {
        const currentGoal = JSON.parse(JSON.stringify(goal));
        const propToNumber = [
          'goalid',
          'goalcompletionorder',
          'conversiontimerange',
          'conversioncapvalue',
          'pointsawarded',
        ];
        for (const key of propToNumber) {
          if (currentGoal[key]) currentGoal[key] = Number(currentGoal[key]);
        }

        if (currentGoal?.oldconversiontimerange) {
          if (
            currentGoal?.oldconversiontimerange !==
            currentGoal?.conversiontimerange
          ) {
            currentGoal.currentconversionvalue =
              currentGoal?.isconversioncapactive ? 0 : null;
          }

          delete currentGoal.oldconversiontimerange;
        }

        if (!currentGoal?.isconversioncapactive) {
          return {
            ...currentGoal,
            currentconversionvalue: null,
            conversiontimerange: null,
            conversioncapvalue: null,
          };
        }

        if (
          currentGoal?.isconversioncapactive &&
          !currentGoal.currentconversionvalue
        ) {
          return { ...currentGoal, currentconversionvalue: 0 };
        }

        if (!currentGoal.goalcompletioninstructions) {
          currentGoal.goalcompletioninstructions = null;
        }
        return currentGoal;
      }
    );
  }

  if (cloneOfUpdatedOffer.category?.length === 0) {
    cloneOfUpdatedOffer.category = [];
  }

  return cloneOfUpdatedOffer;
}

function reformatOfferForFrontend(fetchedOffer: any) {
  const cloneOfFetchedOffer = _.clone(fetchedOffer);
  if (!cloneOfFetchedOffer.audiences) {
    cloneOfFetchedOffer.audiences = [];
  }
  if (cloneOfFetchedOffer.successurl) {
    const countSuccessUrls = (
      cloneOfFetchedOffer.successurl.match(/http/g) || []
    ).length;
    const isArray = cloneOfFetchedOffer.successurl[0] === '[';
    if (countSuccessUrls === 1 && !isArray) {
      cloneOfFetchedOffer.successurl = [cloneOfFetchedOffer.successurl];
    } else {
      cloneOfFetchedOffer.successurl = JSON.parse(
        cloneOfFetchedOffer.successurl
      );
    }
  } else {
    cloneOfFetchedOffer.successurl = [null];
  }
  if (cloneOfFetchedOffer.onofferseenurltofire) {
    const countUrls = (
      cloneOfFetchedOffer.onofferseenurltofire.match(/http/g) || []
    ).length;
    const isArray = cloneOfFetchedOffer.onofferseenurltofire[0] === '[';
    if (countUrls === 1 && !isArray) {
      cloneOfFetchedOffer.onofferseenurltofire = [
        cloneOfFetchedOffer.onofferseenurltofire,
      ];
    } else {
      cloneOfFetchedOffer.onofferseenurltofire = JSON.parse(
        cloneOfFetchedOffer.onofferseenurltofire
      );
    }
  } else {
    cloneOfFetchedOffer.onofferseenurltofire = [null];
  }
  if (cloneOfFetchedOffer.zips && cloneOfFetchedOffer.zips.length) {
    cloneOfFetchedOffer.zips = cloneOfFetchedOffer.zips.join('\n');
  } else {
    cloneOfFetchedOffer.zips = '';
  }
  if (cloneOfFetchedOffer.states && cloneOfFetchedOffer.states.length) {
    cloneOfFetchedOffer.states = cloneOfFetchedOffer.states.join('\n');
  } else {
    cloneOfFetchedOffer.states = '';
  }
  if (!cloneOfFetchedOffer.devices) {
    cloneOfFetchedOffer.devices = 'ALL_DEVICES';
  }
  if (!cloneOfFetchedOffer.pricingmodel) {
    cloneOfFetchedOffer.pricingmodel = '';
  }

  if (!cloneOfFetchedOffer.revenueconversionevent) {
    cloneOfFetchedOffer.revenueconversionevent = '';
  }
  if (!cloneOfFetchedOffer.zipCount) {
    cloneOfFetchedOffer.zipCount = 0;
  }
  if (!cloneOfFetchedOffer.zip_upload_status) {
    cloneOfFetchedOffer.zip_upload_status = UploadStates.DONE;
  }

  if (cloneOfFetchedOffer.category?.length === 0) {
    cloneOfFetchedOffer.category = [];
  }

  if (cloneOfFetchedOffer.placementid?.length === 0) {
    cloneOfFetchedOffer.placementid = ['1'];
  }

  if (!cloneOfFetchedOffer.offertunegoals) {
    const newGoal = {
      goalid: 0,
      pointsawarded: undefined,
      goalcompletioninstructions: '',
      status: 'insert',
    };
    cloneOfFetchedOffer.offertunegoals = [];
    cloneOfFetchedOffer.offertunegoals.push(newGoal, newGoal);
  }
  return cloneOfFetchedOffer;
}

function* watchCreateOffer(action: PayloadAction<any>) {
  const { updatedOffer, currentGroup } = action.payload;
  try {
    yield put(setOfferLoadingState({ state: 'loading' }));
    const offerForAPI = reformatOfferForAPI(updatedOffer);
    const result = yield call(handleCreateOffer, offerForAPI);
    const offer = result.data;
    const offerid = result.data.offerid;
    window.history.pushState(
      null,
      document.title,
      `${window.location.href}/${offerid}`
    );
    const offerForFrontend = reformatOfferForFrontend(offer);
    yield put(loadOriginalOffer(offerForFrontend));
    yield put(updateCurrentOffer(offerForFrontend));
    yield put(updateOffers(offerForFrontend));
    const updatedGroup = {
      ...currentGroup,
      offers: _.union(currentGroup.offers, [offerForFrontend.offerid]),
    };
    yield put(updateCurrentGroup(updatedGroup));
    yield put(updateGroups(updatedGroup));
    yield put(setOfferLoadingState({ state: 'done' }));
    yield put(
      addNotification({
        state: 'done',
        message: `Successfully Created Offer ${offerForFrontend.offername}`,
      })
    );
  } catch (e) {
    const { updatedOffer } = action.payload;
    yield put(setOfferLoadingState({ state: 'done' }));
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to Create Offer ${updatedOffer.offername}`,
      })
    );
  }
}

function* watchFetchOffer(action: PayloadAction<Offer>) {
  try {
    yield put(loadOriginalOffer(undefined));
    yield put(setOfferLoadingState({ state: 'loading' }));
    const offer = action.payload;
    yield put(updateCurrentOffer(offer));
    const result = yield call(handleFetchOffer, offer);
    const fetchedOffer = result.data;
    const offerForFrontend = reformatOfferForFrontend(fetchedOffer);
    yield put(updateCurrentOffer(offerForFrontend));
    yield put(updateOffers(offerForFrontend));
    yield put(loadOriginalOffer(offerForFrontend));
    yield put(setOfferLoadingState({ state: 'done' }));
  } catch (e) {
    yield put(
      setUIState({
        value: true,
        uiPropertyName: 'errorFetching',
      })
    );
    yield put(setOfferLoadingState({ state: 'done' }));
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to Retrieve Offer ${action.payload.offername}`,
      })
    );
  }
}

function* watchFetchOffers(
  action: PayloadAction<{ isActive: boolean; placementId: number }>
) {
  try {
    yield put(setOffersLoadingState({ state: 'loading' }));

    const { isActive, placementId } = action.payload;
    const result = yield call(handleFetchOffers, isActive, placementId);
    const modifiedOffers = result.data
      .filter((offer) => {
        const timestamp = Date.now();
        if (offer.startdate && offer.startdate > timestamp) return false;
        if (offer.enddate && offer.enddate < timestamp) return false;
        return true;
      })
      .map(reformatOfferForFrontend);
    yield put(updateOffers(modifiedOffers));
    yield put(updateCurrentActiveOffers(modifiedOffers.map((x) => x.offerid)));
    yield put(setOffersLoadingState({ state: 'done' }));
  } catch (e) {
    yield put(setOffersLoadingState({ state: 'done' }));
  }
}

function* watchUploadZipsFile(action: PayloadAction<ZipUploadPayload>) {
  try {
    const data = action.payload;
    yield call(handleUploadZipsFile, data);
  } catch (e) {
    console.error(e);
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to upload ${action.payload.file.name}`,
      })
    );
    yield put(setZipsFileUploadingState(UploadStates.DONE));
  }
}

function* watchDeleteZipCodes(action: PayloadAction<{ offerId: number }>) {
  try {
    const result = yield call(handleDeleteZipCodes, action.payload);
    if (result.status === 200) {
      yield put(setZipCount(0));
    }
  } catch (err) {
    console.error(err);
    yield put(
      addNotification({ state: 'error', message: 'Failed to delete zipCodes' })
    );
  }
}

function* watchUpdateOffer(action: { payload: { offername: any } }) {
  try {
    yield put(setOfferLoadingState({ state: 'loading' }));
    const offer = action.payload;
    const offerForAPI = reformatOfferForAPI(offer);

    const result = yield call(handleUpdateOffer, offerForAPI);
    const fetchedOffer = result.data;
    const offerForFrontend = reformatOfferForFrontend(fetchedOffer);
    yield put(updateCurrentOffer(offerForFrontend));
    yield put(updateOffers(offerForFrontend));
    yield put(loadOriginalOffer(offerForFrontend));
    yield put(setOfferLoadingState({ state: 'done' }));

    if (fetchedOffer.errors?.length) {
      const errorMessage = fetchedOffer.errors.join('<br/>');
      yield put(
        addNotification({
          state: 'error',
          message: errorMessage,
        })
      );
    } else {
      yield put(
        addNotification({
          state: 'done',
          message: `Successfully Updated Offer ${offerForAPI.offername}`,
        })
      );
    }
  } catch (e) {
    let errorMessage = null;
    if (e.errors && e.errors.length)
      errorMessage = e.errors.map((error) => error.message).join('<br/>');
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to Update Offer ${action.payload.offername}${
          errorMessage && errorMessage.length ? `<br/>${errorMessage}` : null
        }`,
      })
    );
    yield put(setOfferLoadingState({ state: 'done' }));
  }
}

function* watchDeleteOffer(action) {
  try {
    yield put(updateStates(action.payload.offerid));
    yield call(handleDeleteOffer, action.payload);
    yield put(removeOffer(action.payload));
    yield put(removeFromStates(action.payload.offerid));
    yield put(
      addNotification({
        state: 'done',
        message: `Successfully Deleted Offer ${action.payload.offername}`,
      })
    );
  } catch (e) {
    yield put(removeFromStates(action.payload.offerid));
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to Delete Offer ${action.payload.offername}`,
      })
    );
  }
}

function* updateOffersNoTargeting() {
  yield put(clearOffers());
  const fetchOfferPromises = [1, 2, 3, 4].map((placementid) =>
    call(handleFetchOffers, true, placementid)
  );
  const fetchOfferPromisesResults = yield all(fetchOfferPromises);
  let offers = [];
  for (const result of fetchOfferPromisesResults) {
    const { data } = result;
    if (data && data.length) {
      offers = offers.concat(data);
    }
  }
  for (const offer of offers) {
    yield put(updateOffers(offer));
  }
  yield put(setAllOffers(offers));
  const offerids = offers.map((offer) => offer.offerid);
  yield put(updateCurrentActiveOffers(offerids));
}

function* watchMoveOffer(action) {
  try {
    const results = yield call(handleReorderOffers, action.payload);
    const offerids = results.data;
    for (let i = 0; i < offerids.length; i++) {
      const offerid = offerids[i];
      yield put(updateOfferZindex({ zindex: i, offerid }));
    }
  } catch (e) {
    console.log(e);
  }
}

function reformatCurrentTargetingForAPI(object) {
  const cloneOfObject = _.clone(object);
  if (cloneOfObject.device == 'None') cloneOfObject.device = '';
  if (cloneOfObject.zip) cloneOfObject.zip = parseInt(cloneOfObject.zip);
  return cloneOfObject;
}

function* watchDuplicateOffer(action) {
  const { offerToDuplicate, offerGroupId, groupName } = action.payload;
  const body = {};
  body.offerid = offerToDuplicate.offerid;
  if (offerGroupId) {
    body.groupid = offerGroupId;
  } else {
    body.groupid = offerToDuplicate.offergroupid;
  }
  if (groupName) {
    delete body.groupid;
    body.groupname = groupName;
  }
  yield put(toggleDuplicateOfferDialog({ open: false }));
  try {
    yield put(setOfferLoadingState({ state: 'loading' }));
    const result = yield call(handleDuplicateOffer, body);
    const copiedOffer = result.data;
    const newOfferId = copiedOffer.offerid;
    window.history.pushState(
      null,
      document.title,
      `/main/offer-wall-manager/editor/${newOfferId}`
    );
    const offerForFrontend = reformatOfferForFrontend(copiedOffer);
    yield put(updateCurrentOffer(offerForFrontend));
    yield put(updateOffers(offerForFrontend));
    yield put(loadOriginalOffer(offerForFrontend));
    yield put(setOfferLoadingState({ state: 'done' }));
    yield put(
      addNotification({
        state: 'done',
        message: `Successfully Duplicated Offer ${offerToDuplicate.label}`,
      })
    );
  } catch (e) {
    console.log('Error:', e);
    yield put(setOfferLoadingState({ state: 'error' }));
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to Duplicate Offer ${offerToDuplicate.label}`,
      })
    );
  }
}

function* watchUpdateCurrentTargeting() {
  try {
    yield put(setOffersLoadingState({ state: 'loading' }));
    const currentTargetingFunction = (state) =>
      state.offerWallReducer.currentTargeting;
    const currentTargeting = yield select(currentTargetingFunction);
    let offers = [];
    if (_.isEmpty(currentTargeting)) {
      yield call(updateOffersNoTargeting);
    } else {
      yield put(clearOffers());
      const reformattedObject =
        reformatCurrentTargetingForAPI(currentTargeting);
      const results = yield call(handleFetchTargetedOffers, reformattedObject);
      offers = results.data;
      for (const offer of offers) {
        yield put(updateOffers(offer));
      }
      yield put(setAllOffers(offers));
      const offerids = offers.map((offer) => offer.offerid);
      yield put(updateCurrentActiveOffers(offerids));
    }
  } catch (e) {
    console.log(e);
  }
  yield put(setOffersLoadingState({ state: 'done' }));
}

function* watchSearchOffers(action) {
  try {
    yield put(setOffersLoadingState({ state: 'loading' }));
    const result = yield call(handleSearchOffers, action.payload);
    const offers = result.data;
    yield put(updateOffers(offers));
    yield put(setOffersLoadingState({ state: 'done' }));
  } catch (e) {
    yield put(
      addNotification({
        state: 'error',
        message: `Error while fetching Offers`,
      })
    );
    yield put(setOffersLoadingState({ state: 'error' }));
  }
}

function* watchSetInitialPlacementOffers() {
  try {
    yield put(setOffersLoadingState({ state: 'loading' }));
    yield call(updateOffersNoTargeting);
  } catch (e) {
    console.log(e);
  }
  yield put(setOffersLoadingState({ state: 'done' }));
}

export default function* watchAll() {
  yield takeLatest<any>(moveOffer.type, watchMoveOffer);
  yield takeLatest<any>(createOffer.type, watchCreateOffer);
  yield takeLatest<any>(fetchOffer.type, watchFetchOffer);
  yield takeLatest<any>(fetchOffers.type, watchFetchOffers);
  yield takeLatest<any>(saveOffer.type, watchUpdateOffer);
  yield takeLatest<any>(searchOffers.type, watchSearchOffers);
  yield takeLatest<any>(deleteOffer.type, watchDeleteOffer);
  yield takeLatest<any>(duplicateOffer.type, watchDuplicateOffer);
  yield takeLatest<any>(
    updateCurrentTargeting.type,
    watchUpdateCurrentTargeting
  );
  yield takeLatest<any>(
    setInitialPlacementOffers.type,
    watchSetInitialPlacementOffers
  );
  yield takeLatest<any>(uploadZipsFile.type, watchUploadZipsFile);
  yield takeLatest<any>(deleteZipCodes.type, watchDeleteZipCodes);
}
