import {
  AUDIENCE_EXPORT_TYPES,
  LIVERAMP_SEGMENT_ADVERTISER_SELECTION,
  LIVERAMP_SEGMENT_DETAILS,
  LIVERAMP_SEGMENT_PRICING_DETAILS,
} from '../../constants';

import { LIVERAMP_SEGMENT_FIELD_NAME } from '../../content';

// Material UI Components
import tokens from 'tokens';
import { Grid, TextField } from '@klover/attain-design-system';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import GhostLoader from 'react-ghost-loader';
import TextArea from 'components/TextArea';
import TextFieldSearch from 'components/TextFieldSearch/TextFieldSearch';
import { CaretDown } from '@phosphor-icons/react';
import { Divider } from 'antd';
import { useEffect, useState } from 'react';
import { useLiveRampIntigrations } from '../../graphql/hooks/useLiveRampExportData';
import * as Styled from './index.styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    informationText: {
      marginTop: '1rem',
      fontFamily: 'Calibre-Regular,sans-serif',
      fontSize: 16,
    },
    segmentDivider: {
      background: tokens.colorDividerLgBorder,
      height: '1px',
      border: 'none',
    },
    segmentInputFieldsContainer: {
      display: 'flex',
      padding: '1rem 1rem 0rem 1rem',
    },
    segmentInputFieldsLeft: {
      flex: 1,
      marginTop: 60,
      paddingRight: '1rem',
    },
    segmentInputFieldsRight: {
      flex: 1,
      marginTop: 19,
      paddingLeft: '1rem',
      borderLeft: '1px solid' + tokens.colorDividerLgBorder,
    },
    liveRampDataSegmentLabel: {
      paddingBottom: '0.5rem',
      fontSize: 20,
      fontWeight: 500,
      fontFamily: 'Calibre-Regular,sans-serif',
    },
    OrLabel: {
      fontSize: 18,
      fontWeight: 500,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Calibre-Regular,sans-serif',
    },
    pricingDetailsLabel: {
      paddingBottom: '1rem',
      fontSize: 18,
      fontWeight: 500,
      fontFamily: 'Calibre-Regular,sans-serif',
    },
  })
);

const LiveRampExport = (props: any) => {
  const classes = useStyles();
  const {
    handleSegmentName,
    segmentName,
    handleDescription,
    description,
    handleCpm,
    cpm,
    handlePercentageOfMedia,
    percentageOfMedia,
    isSegmentNameValid,
    inValidSegmentNameErrorMessage,
    platformDestination,
    setPlatformDestination,
    advertiserDestination,
    setAdvertiserDestination,
    tvTargeting,
    handleTvTargeting,
    advertiserDirect,
    handleAdvertiserDirect,
    selectedExportType,
  } = props;

  const {
    loading: isLiveRampIntegrationsLoading,
    liveRampIntegrations = [],
    liveRampDistributionManagers = [],
    refetch: refetchLiveRampIntegrations,
  } = useLiveRampIntigrations({
    distributionManagerId: '',
    distributionManagerName: '',
    integrationId: '',
    integrationName: '',
  });

  const [filteredIntegrations, setFilteredIntegrations] = useState([]);
  const [filteredDistributionManagers, setFilteredDistributionManagers] =
    useState([]);

  useEffect(() => {
    refetchLiveRampIntegrations();
  }, []);

  useEffect(() => {
    if (
      !isLiveRampIntegrationsLoading &&
      filteredIntegrations.length === 0 &&
      liveRampIntegrations.length > 0
    ) {
      setFilteredIntegrations(liveRampIntegrations);
    }
    if (
      !isLiveRampIntegrationsLoading &&
      filteredDistributionManagers.length === 0 &&
      liveRampDistributionManagers.length > 0
    ) {
      setFilteredDistributionManagers(liveRampDistributionManagers);
    }
  }, [liveRampIntegrations, liveRampDistributionManagers]);

  /**
   * handle change for advertiser destination
   */
  const handleIntegrationChange = (newValue) => {
    if (newValue !== platformDestination) {
      setPlatformDestination(newValue);
      const records = liveRampDistributionManagers
        .filter((manager: any) => manager.integrationId === newValue)
        .map((x) => {
          return {
            label: x.distributionManagerName,
            id: x.distributionManagerId,
          };
        });
      setFilteredDistributionManagers(records);
      setAdvertiserDestination(null);
    }
  };

  /**
   * handle change for distribution manager
   */
  const handleDistributionManagerChange = (newValue) => {
    if (newValue !== advertiserDestination) {
      setAdvertiserDestination(newValue);
      // will impliment in future
      // const selectedDistributionManager = liveRampDistributionManagers.find(
      //   (manager: any) => manager.id === newValue
      // );
      // if (selectedDistributionManager) {
      //   setPlatformDestination(selectedDistributionManager.integrationId);
      // }
    }
  };

  /**
   * clear advertiser destination and its depended fields
   */
  const clearIntegrationSelection = () => {
    setPlatformDestination('');
    setAdvertiserDestination('');
    setFilteredDistributionManagers(liveRampDistributionManagers);
  };

  /**
   * clear distribution manager selection
   */
  const clearDistributionManagerSelection = () => {
    setAdvertiserDestination('');
  };

  return (
    <>
      <Grid container spacing={0} display="flex">
        <Grid item xs={12} className={classes.liveRampDataSegmentLabel}>
          {LIVERAMP_SEGMENT_DETAILS}
        </Grid>
        <Grid
          container
          xs={6}
          display="flex"
          flexDirection="column"
          className={classes.segmentInputFieldsLeft}
        >
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <TextField
                onChange={handleSegmentName}
                value={segmentName}
                fullWidth
                id="segmentName"
                label={LIVERAMP_SEGMENT_FIELD_NAME.CUSTOM_SEGMENT_NAME}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={!isSegmentNameValid}
                helperText={inValidSegmentNameErrorMessage}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} style={{ marginTop: 36 }}>
            <Grid item xs={12}>
              <TextArea
                rows={3}
                onChange={handleDescription}
                value={description}
                fullWidth
                id="description"
                label={LIVERAMP_SEGMENT_FIELD_NAME.DESCRIPTION}
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          xs={6}
          display="flex"
          flexDirection="column"
          className={classes.segmentInputFieldsRight}
        >
          <Grid className={classes.pricingDetailsLabel}>
            {LIVERAMP_SEGMENT_PRICING_DETAILS}
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={5}>
              <TextField
                onChange={handleCpm}
                value={cpm}
                fullWidth
                id="cpm"
                label={LIVERAMP_SEGMENT_FIELD_NAME.CPM}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <TextField
                onChange={handleTvTargeting}
                value={tvTargeting}
                fullWidth
                id="tvTargeting"
                label={LIVERAMP_SEGMENT_FIELD_NAME.TV_TARGETING}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} style={{ marginTop: 36 }}>
            <Grid item xs={5}>
              <TextField
                onChange={handlePercentageOfMedia}
                value={percentageOfMedia}
                fullWidth
                id="percentageOfMedia"
                label={
                  LIVERAMP_SEGMENT_FIELD_NAME.PROGRAMMATIC_PERCENT_OF_MEDIA
                }
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <TextField
                onChange={handleAdvertiserDirect}
                value={advertiserDirect}
                fullWidth
                id="advertiserDirect"
                label={LIVERAMP_SEGMENT_FIELD_NAME.ADVERTISER_DIRECT}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          {selectedExportType ===
            AUDIENCE_EXPORT_TYPES.LIVERAMP_CUSTOM_SEGMENT && (
            <>
              <Divider />
              <Grid className={classes.pricingDetailsLabel}>
                {LIVERAMP_SEGMENT_ADVERTISER_SELECTION}
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  {isLiveRampIntegrationsLoading ? (
                    <GhostLoader
                      height="48px"
                      heightRandom={0}
                      width="100%"
                      widthRandom={0}
                    />
                  ) : (
                    <Styled.SelectAutoCompleteGroup
                      disablePortal
                      id="platformDestination"
                      options={filteredIntegrations}
                      placeholder={
                        LIVERAMP_SEGMENT_FIELD_NAME.CHOOSE_INTEGRATION
                      }
                      popupIcon={<CaretDown />}
                      value={
                        filteredIntegrations?.find(
                          (a) => a.id === platformDestination
                        )?.label || ''
                      }
                      renderInput={(params) => (
                        <TextFieldSearch
                          {...params}
                          placeholder={
                            LIVERAMP_SEGMENT_FIELD_NAME.CHOOSE_INTEGRATION
                          }
                          variant="outlined"
                        />
                      )}
                      onChange={(e, newValue) => {
                        //setPlatformDestination(newValue);
                        if (newValue) handleIntegrationChange(newValue.id);
                        else clearIntegrationSelection();
                      }}
                      renderOption={(props, option) => (
                        <Styled.SelectAutoCompleteGroupOptions>
                          <li {...props} key={option.id}>
                            {option.label}
                          </li>
                        </Styled.SelectAutoCompleteGroupOptions>
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={12} paddingTop={1}>
                  {isLiveRampIntegrationsLoading ? (
                    <GhostLoader
                      height="48px"
                      heightRandom={0}
                      width="100%"
                      widthRandom={0}
                    />
                  ) : (
                    <Styled.SelectAutoCompleteGroup
                      disablePortal
                      id="distribution_manager"
                      disabled={!platformDestination}
                      options={filteredDistributionManagers}
                      placeholder={
                        LIVERAMP_SEGMENT_FIELD_NAME.CHOOSE_ADVERTISER
                      }
                      popupIcon={<CaretDown />}
                      value={
                        filteredDistributionManagers?.find(
                          (a) => a?.id == advertiserDestination
                        )?.label || ''
                      }
                      renderInput={(params) => (
                        <TextFieldSearch
                          {...params}
                          placeholder={
                            LIVERAMP_SEGMENT_FIELD_NAME.CHOOSE_ADVERTISER
                          }
                          variant="outlined"
                        />
                      )}
                      onChange={(e, newValue) => {
                        //setAdvertiserDestination(newValue);
                        if (newValue)
                          handleDistributionManagerChange(newValue.id);
                        else clearDistributionManagerSelection();
                      }}
                      renderOption={(props, option) => (
                        <Styled.SelectAutoCompleteGroupOptions>
                          <li {...props} key={option.id}>
                            {option.label}
                          </li>
                        </Styled.SelectAutoCompleteGroupOptions>
                      )}
                    />
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default LiveRampExport;
