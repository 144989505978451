import { AUDIENCE_EXPORT_TYPES } from '../../../constants/index';

import {
  LIVERAMP_SEGMENT_FIELD_NAME,
  TRADE_DESK_SEGMENT_FIELD_NAME,
} from '../../../content';
import { nanoid } from 'nanoid';

/**
 * Configuration for Trade Desk Custom Segment fields.
 */
const tradeDeskCustomSegmentConfig = [
  {
    type: 'textbox',
    id: 'partnerID',
    column: 'partnerID',
    label: TRADE_DESK_SEGMENT_FIELD_NAME.PARTNER_ID,
    variant: 'outlined',
    required: true,
    gridProps: { xs: 5 },
    value: '',
    disabled: false,
    isVisible: true,
    InputLabelProps: { shrink: true },
  },
  {
    type: 'label',
    label: 'Or',
    gridProps: { xs: 2 },
    isVisible: true,
  },
  {
    type: 'textbox',
    id: 'advertiserID',
    column: 'advertiserID',
    label: TRADE_DESK_SEGMENT_FIELD_NAME.ADVERTISER_ID,
    variant: 'outlined',
    required: true,
    gridProps: { xs: 5 },
    value: '',
    disabled: false,
    isVisible: true,
    InputLabelProps: { shrink: true },
  },
];

/**
 * Configuration for LiveRamp Custom Segment fields.
 */
const liveRampCustomSegmentConfig = [
  {
    type: 'textbox',
    id: 'tvTargeting',
    column: 'tvTargeting',
    label: LIVERAMP_SEGMENT_FIELD_NAME.TV_TARGETING,
    regex: '^[0-9]*$', // Only numeric values are allowed.
    variant: 'outlined',
    required: true,
    gridProps: { xs: 5 },
    value: '',
    isVisible: true,
    InputLabelProps: { shrink: true },
  },
  {
    id: 'space', // Placeholder for spacing between fields.
    gridProps: { xs: 2 },
    isVisible: true,
  },
  {
    type: 'textbox',
    id: 'advertiserDirect',
    column: 'advertiserDirect',
    label: LIVERAMP_SEGMENT_FIELD_NAME.ADVERTISER_DIRECT,
    variant: 'outlined',
    gridProps: { xs: 5 },
    value: '',
    isVisible: true,
    InputLabelProps: { shrink: true },
  },
  {
    type: 'autocomplete',
    id: 'platformDestination',
    column: 'platformDestination',
    placeholder: LIVERAMP_SEGMENT_FIELD_NAME.CHOOSE_INTEGRATION,
    variant: 'outlined',
    options: [],
    required: true,
    gridProps: { xs: 12 },
    value: '',
    isVisible: true,
  },
  {
    type: 'autocomplete',
    id: 'advertiserDestination',
    column: 'advertiserDestination',
    placeholder: LIVERAMP_SEGMENT_FIELD_NAME.CHOOSE_ADVERTISER,
    variant: 'outlined',
    options: [],
    required: true,
    gridProps: { xs: 12 },
    value: '',
    disabled: true,
    isVisible: true,
  },
];

/**
 * Configuration for LiveRamp OTS Segment fields.
 */
const liveRampOtsSegmentConfig = [
  {
    type: 'textbox',
    id: 'tvTargeting',
    column: 'tvTargeting',
    label: LIVERAMP_SEGMENT_FIELD_NAME.TV_TARGETING,
    regex: '^[0-9]*$', // Only numeric values are allowed.
    variant: 'outlined',
    required: true,
    gridProps: { xs: 5 },
    value: '',
    isVisible: true,
    InputLabelProps: { shrink: true },
  },
  {
    id: 'space', // Placeholder for spacing between fields.
    gridProps: { xs: 2 },
    isVisible: true,
  },
  {
    type: 'textbox',
    id: 'advertiserDirect',
    column: 'advertiserDirect',
    label: LIVERAMP_SEGMENT_FIELD_NAME.ADVERTISER_DIRECT,
    variant: 'outlined',
    gridProps: { xs: 5 },
    value: '',
    isVisible: true,
    InputLabelProps: { shrink: true },
  },
];

/**
 * Configuration for Trade Desk OTS Segment fields.
 */
const tradeDeskOTSSegmentConfig: any[] = [];

/**
 * Fetches the form fields for a specific audience export type.
 *
 * @param exportType - The type of audience export (e.g., CUSTOM_SEGMENT, LIVERAMP_CUSTOM_SEGMENT).
 * @returns The array of form fields that are visible for the given export type.
 */
const getAudienceExportField = (exportType: string) => {
  switch (exportType) {
    case AUDIENCE_EXPORT_TYPES.OTS_SEGMENT:
    case AUDIENCE_EXPORT_TYPES.TTD_OTS_SEGMENT_SEED_AUDIENCE:
      return tradeDeskOTSSegmentConfig.filter((field) => field.isVisible);

    case AUDIENCE_EXPORT_TYPES.CUSTOM_SEGMENT:
    case AUDIENCE_EXPORT_TYPES.TTD_CUSTOM_SEGMENT_SEED_AUDIENCE:
      return tradeDeskCustomSegmentConfig.filter((field) => field.isVisible);

    case AUDIENCE_EXPORT_TYPES.LIVERAMP_CUSTOM_SEGMENT:
      return liveRampCustomSegmentConfig.filter((field) => field.isVisible);

    case AUDIENCE_EXPORT_TYPES.LIVERAMP_OTS_SEGMENT:
      return liveRampOtsSegmentConfig.filter((field) => field.isVisible);
    default:
      return [];
  }
};

/**
 * Generates the audience export fields based on the provided export types.
 *
 * @param exportTypes - An array of export types to generate fields for.
 * @returns An array of objects containing the export type and its corresponding form fields.
 */
export const getAudienceExportFields = (exportTypes: string[] = []) => {
  const fields = [];

  for (const exportType of exportTypes) {
    fields.push({
      id: nanoid(), // Unique identifier for each field.
      exportType,
      formJSON: getAudienceExportField(exportType),
    });
  }

  return fields;
};
