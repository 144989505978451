import { Dialog, Grid } from '@klover/attain-design-system';
import { createContext, useContext, useEffect, useMemo } from 'react';

// Material UI Components
import Box from '@material-ui/core/Box';
import CircleIcon from '@mui/icons-material/Circle';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import CopyPopover from 'components/CopyPopover';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import useLiveRampIntigrations from '../../../graphql/hooks/useLiveRampExportData';
import {
  AUDIENCE_EXPORT_TYPES,
  AUDIENCE_LAL_MODAL_TYPE,
  AllowSegmentDetailsExports,
  AudienceAllExports,
  AudienceDefaultExports,
  ENUM_SYSTEM_USED,
  UploadStates,
} from '../../../constants/index';
import { CaretUp, ClockCounterClockwise, Users } from '@phosphor-icons/react';
import {
  ENUM_AUDIENCE_EXPORT_STATUS,
  ENUM_EXPORT_STATUS,
  ENUM_EXPORT_TYPE,
  convertToDateTime,
  getExportLatestStatus,
  getFileUploadingStatus,
  getSegmentDetails,
  getStatusDetails,
  modifyProps,
} from './exportHistoryUtils';
import { IN_HOUSE_LABEL, TRANSUNION_LABEL } from '../../../content';
import { Tag } from 'antd';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { getAudienceExportStatus } from 'utils/audienceExportStatus';

/**
 * Define custom styles using makeStyles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close: {
      height: 24,
      width: 24,
    },
    columnTitles: {
      color: 'black',
      display: 'flex',
      fontSize: 16,
      padding: 5,
      paddingBottom: 0,
      fontFamily: 'Calibre-Regular,sans-serif',
    },
    header: {
      padding: '10px 12px 10px 24px',
    },
    noLogs: {
      display: 'flex',
      justifyContent: 'center',
      color: '#413762',
      fontSize: '22px',
      fontWeight: 500,
    },
    icon: {
      height: 24,
      width: 24,
    },
    root: {
      margin: theme.spacing(3),
    },
    lineDivider: {
      margin: '2px 0px',
    },
    accordion: {
      height: 55,
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
    },
    titleText: {
      fontFamily: 'Calibre-Semibold,sans-serif',
      fontWeight: 600,
      fontSize: 22,
      lineHeight: '32px',
    },
    titleDate: {
      paddingLeft: 33,
      fontSize: 18,
    },
    titleStatus: {
      paddingLeft: 29,
      fontSize: 18,
    },
    titleExportedBy: {
      paddingLeft: 24,
      fontSize: 18,
    },
    titleDateData: {
      paddingLeft: 33,
    },
    titleStatusData: {
      paddingLeft: 29,
    },
    titleExportedByData: {
      paddingLeft: 24,
    },
    histroryTitleContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'grey',
      fontSize: 16,
      padding: '5px 24px 5px 0px',
      paddingBottom: 0,
      fontFamily: 'Calibre-Regular,sans-serif',
    },
    historyTitle: {
      color: '#413762',
      fontWeight: 500,
      fontSize: 22,
      paddingLeft: 14,
    },
    histroryDetailContainer: {
      marginTop: '10px',
      display: 'flex',
    },
    tableContainer: {
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'Calibre-Regular,sans-serif',
    },
    tableTitle: {
      color: '#413762',
      fontSize: '22px',
      fontWeight: 500,
      float: 'left',
      paddingBottom: '5px',
    },
    rowContainer: {
      fontSize: '16px',
      color: '#1A171C',
    },
    historyMainContainer: {
      display: 'flex',
      padding: '18px',
      border: '1px solid gray',
      borderRadius: '25px',
    },
    fileStatusIcon: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '5px',
      color: '#1A171C',
    },
    fileName: {
      wordBreak: 'break-all',
    },
    historyDateTime: {
      paddingLeft: '23px',
      color: '#1A171C',
    },
    exportedBy: {
      fontSize: 16,
      color: '#1A171C',
    },
  })
);

const AudienceExportHistoryContext = createContext(null);

/**
 * ExportHistoryDialog component definition
 */
function ExportHistroryDialog(props: any) {
  const {
    isOpen = false,
    setOpenDialog,
    data = [],
    customAudience = null,
    normalAudience = null,
  } = props || {};
  const classes = useStyles();
  const historyData = useMemo(() => {
    const filteredItem = data?.filter((x: any) =>
      AudienceAllExports.includes(x.exportType)
    );
    return modifyProps(filteredItem);
  }, [data]);
  const {
    liveRampIntegrations = [],
    liveRampDistributionManagers = [],
    refetch: refetchLiveRampIntegrations,
  } = useLiveRampIntigrations({
    distributionManagerId: '',
    distributionManagerName: '',
    integrationId: '',
    integrationName: '',
  });

  useEffect(() => {
    if (isOpen) {
      const isAnyLiveRampCustom = historyData.some(
        (x) => x.exportType === AUDIENCE_EXPORT_TYPES.LIVERAMP_CUSTOM_SEGMENT
      );
      if (isAnyLiveRampCustom) {
        refetchLiveRampIntegrations();
      }
    }
  }, [isOpen]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="lg"
      onClose={handleClose}
      open={isOpen}
      disableEnforceFocus
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      headerID="audienceExportHeader"
      header={<Title />}
      bodyID="audienceExportBody"
      body={
        <AudienceExportHistoryContext.Provider
          value={{
            liveRampIntegrations,
            liveRampDistributionManagers,
          }}
        >
          <ExportHistroryWithDetails
            historyData={historyData}
            customAudience={customAudience}
            normalAudience={normalAudience}
          />
        </AudienceExportHistoryContext.Provider>
      }
    />
  );
}

/**
 * Export History component defination to define individual UI of Export History
 */
const ExportHistory = (props: any) => {
  const { exportData, itemIndex, normalAudience } = props || {};
  const classes = useStyles();

  const statusList = getStatusDetails(
    exportData.exportType,
    exportData?.lalModelType
  );
  const isInHouseExport =
    exportData?.lalModelType === AUDIENCE_LAL_MODAL_TYPE.ATTAIN;
  const segmentDetailsList = getSegmentDetails(exportData.exportType);
  const isTagVisible =
    exportData.systemUsed == ENUM_SYSTEM_USED.STANDARD_AUDIENCE;

  return (
    <Accordion defaultExpanded={itemIndex === 0} key={`accordion_${itemIndex}`}>
      <AccordionSummary
        expandIcon={<CaretUp size={24} />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={classes.accordion}
      >
        <Grid className={classes.histroryTitleContainer} xs={12}>
          <Grid item xs={1}>
            <HistoryStatusIcon
              exportItem={exportData}
              isInHouseExport={isInHouseExport}
            />
          </Grid>
          <Grid item xs={2} className={classes.historyDateTime}>
            {convertToDateTime(exportData.createdAt?.value, true)}
          </Grid>
          <Grid item xs={6} className={classes.historyTitle}>
            {getExportLatestStatus(exportData, isInHouseExport)}
            {isTagVisible && (
              <Tag
                color={isInHouseExport ? 'gold' : 'geekblue'}
                style={{ marginLeft: 15 }}
              >
                {isInHouseExport ? IN_HOUSE_LABEL : TRANSUNION_LABEL}
              </Tag>
            )}
          </Grid>
          <Grid item xs={3} className={classes.exportedBy}>
            {exportData.exportBy}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <>
          <Grid className={classes.histroryDetailContainer}>
            <Grid item xs={3} />
            <Grid item xs={8} style={{ paddingLeft: 5 }}>
              <ExportDetailTable
                details={statusList}
                exportData={exportData}
                labelXS={8}
                valueXS={4}
                type="export_details"
              />
            </Grid>
          </Grid>
          {AllowSegmentDetailsExports.includes(exportData?.exportType) && (
            <Grid className={classes.histroryDetailContainer}>
              <Grid item xs={1} />
              <Grid item xs={11} className={classes.historyMainContainer}>
                <ExportDetailTable
                  details={segmentDetailsList}
                  exportData={exportData}
                  type="segment_details"
                  labelXS={3}
                  valueXS={9}
                  title={ENUM_EXPORT_TYPE[exportData?.exportType]}
                />
              </Grid>
            </Grid>
          )}
        </>
      </AccordionDetails>
    </Accordion>
  );
};

/**
 * ExportDetailTable component defination to show table in label/value pair
 */
const ExportDetailTable = (props: any) => {
  const classes = useStyles();
  const { liveRampIntegrations = [], liveRampDistributionManagers = [] } =
    useContext(AudienceExportHistoryContext);

  const {
    details,
    exportData,
    labelXS = 6,
    valueXS = 6,
    type,
    title,
  } = props || {};

  /**
   * Retun custom label based on key value
   */
  const getLabel = (item: any, data: any) => {
    if (item.key === 'timestampStatusExportStarted') {
      item.label = `${ENUM_EXPORT_TYPE[exportData.exportType]} ${
        ENUM_EXPORT_STATUS['export_started']
      }`;
    }

    if (item.key == 'timestampStatusSentToTransunion') {
      return (
        <div
          style={{
            fontFamily: 'Calibre-Regular,sans-serif',
            wordBreak: 'break-all',
          }}
        >
          <span>{item.label}</span>
          <div style={{ paddingTop: '5px' }}>
            File Name : {data?.fileName || ''}
          </div>
        </div>
      );
    }

    return item.label;
  };

  /**
   * Retun custom value based on key value
   */
  const getValue = (item: any, data: any) => {
    if (item.key == 'platformDestination') {
      const liveRamp = liveRampIntegrations?.find(
        (x) => x.id == data[item.key]
      );
      if (liveRamp) return liveRamp.label;
    }

    if (item.key == 'advertiserDestination') {
      const liveRamp = liveRampDistributionManagers?.find(
        (x) => x.distributionManagerId == data[item.key]
      );
      if (liveRamp) return liveRamp.distributionManagerName;
    }

    if (item.type == 'datetime') {
      return convertToDateTime(exportData[item.key]?.value);
    }

    return data[item.key];
  };

  const table = details?.map((item: any, index: number) => {
    return (
      <>
        {index !== 0 && (
          <Grid xs={12}>
            <Grid item xs={12}>
              <Divider className={classes.lineDivider} />
            </Grid>
          </Grid>
        )}
        <Grid
          container
          item
          xs={12}
          className={classes.rowContainer}
          key={`export_detail_table_${index}_${type}}`}
        >
          <Grid item xs={labelXS}>
            {getLabel(item, exportData)}
          </Grid>
          <Grid
            item
            xs={valueXS}
            style={{ paddingLeft: type === 'export_details' ? '24px' : '0px' }}
          >
            {item.key === 'advertiserDirect' && 'Comming Soon'}
            {getValue(item, exportData)}
          </Grid>
        </Grid>
      </>
    );
  });

  return (
    <Grid
      className={classes.tableContainer}
      xs={12}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      {title && <div className={classes.tableTitle}>{title}</div>}
      {table}
    </Grid>
  );
};

/**
 * Title component defination to show Title of modal
 */
const Title = () => {
  const classes = useStyles();

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid item className={classes.title}>
        <ClockCounterClockwise size={25} />
        <Typography component="h2" className={classes.titleText}>
          Audience History
        </Typography>
      </Grid>
    </Grid>
  );
};

/**
 * HistoryStatusIcon component defination to show status icon based on export status
 */
const HistoryStatusIcon = (props: any) => {
  const classes = useStyles();
  const { exportItem, isInHouseExport } = props || {};

  const isDefaultAudienceExport = AudienceDefaultExports.includes(
    exportItem?.exportType
  );
  let status = exportItem?.exportStatus || ENUM_AUDIENCE_EXPORT_STATUS.NOT_SENT;
  if (isInHouseExport) {
    if (
      exportItem?.exportStatus?.toLowerCase() ===
      ENUM_AUDIENCE_EXPORT_STATUS.ERROR.toLowerCase()
    ) {
      status = ENUM_AUDIENCE_EXPORT_STATUS.ERROR;
    } else {
      status = ENUM_AUDIENCE_EXPORT_STATUS.SENT;
    }
  } else if (!isDefaultAudienceExport) {
    status =
      status === ENUM_AUDIENCE_EXPORT_STATUS.ERROR
        ? ENUM_AUDIENCE_EXPORT_STATUS.ERROR
        : ENUM_AUDIENCE_EXPORT_STATUS.SENT;
  }

  const { iconColor } = getAudienceExportStatus(status, isInHouseExport);

  return iconColor ? (
    <span className={classes.fileStatusIcon}>
      <CircleIcon style={{ fontSize: '14px', color: iconColor }} />
      {status || ''}
    </span>
  ) : null;
};

/**
 * Export History Details including Header and details based on Audience Manager/Custom Audience
 */
const ExportHistroryWithDetails = (props: any) => {
  const classes = useStyles();
  const { historyData = [], customAudience } = props || {};

  const header = (
    <Grid className={classes.columnTitles}>
      <Grid item xs={1} />
      <Grid item xs={2} className={classes.titleDate}>
        Date
      </Grid>
      <Grid item xs={6} className={classes.titleStatus}>
        Status
      </Grid>
      <Grid item xs={4} className={classes.titleExportedBy}>
        Email
      </Grid>
    </Grid>
  );

  //For Audience Manager | With No History Data
  // if (!isHistoryData && !customAudience) {
  //   return (
  //     <Box style={{ margin: '24px' }}>
  //       <h3 className={classes.noLogs}>Audience is not exported yet !</h3>
  //     </Box>
  //   );
  // }

  return (
    <Box style={{ margin: '24px' }}>
      {header}
      <Divider className={classes.lineDivider} />
      {customAudience ? (
        <CustomAudienceFileUploadingStatus {...props} />
      ) : (
        <AudienceManagerStatus {...props} />
      )}
      {historyData?.map((exportItem: any, i: number) => (
        <ExportHistory
          exportData={exportItem}
          itemIndex={i}
          key={`history_${i}`}
          {...props}
        />
      ))}
    </Box>
  );
};

/**
 * Custom Audience | Show uploaded file status
 */
const CustomAudienceFileUploadingStatus = (props: any) => {
  const classes = useStyles();
  const {
    status: loadingStatus,
    email,
    createdat,
    updatedat,
    filename,
  } = props?.customAudience || {};
  const { status, statusIcon } = getFileUploadingStatus(loadingStatus);
  const isFileLoading = loadingStatus === UploadStates.LOADING;

  const statusTime = convertToDateTime(
    isFileLoading ? Number(createdat) : Number(updatedat),
    true
  );

  return (
    <Box style={{ marginBottom: '10px' }}>
      <Grid className={classes.columnTitles}>
        <Grid item xs={1} className={classes.fileStatusIcon}>
          {statusIcon}
        </Grid>
        <Grid item xs={2} className={classes.titleDate}>
          {statusTime}
        </Grid>
        <Grid item xs={6} className={classes.titleStatus}>
          {status}
          <div className={classes.fileName}>
            <b>File Name</b>: <CopyPopover value={filename} />{' '}
          </div>
        </Grid>
        <Grid item xs={4} className={classes.titleExportedBy}>
          <CopyPopover value={email} />
        </Grid>
      </Grid>
    </Box>
  );
};

/**
 * Audience Manager | Show audience created status
 */
const AudienceManagerStatus = (props: any) => {
  const classes = useStyles();
  const { createdAt, createdByEmail } = props?.normalAudience || {};
  const statusTime = convertToDateTime(Number(createdAt), true);
  return (
    <Box style={{ marginBottom: '10px' }}>
      <Grid className={classes.columnTitles}>
        <Grid
          item
          xs={1}
          className={classes.fileStatusIcon}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <Users size={24} />
        </Grid>
        <Grid item xs={2} className={classes.titleDateData}>
          {statusTime}
        </Grid>
        <Grid item xs={6} className={classes.titleStatusData}>
          Audience Created
        </Grid>
        <Grid item xs={4} className={classes.titleExportedByData}>
          <CopyPopover value={createdByEmail} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExportHistroryDialog;
