import {
  ACTIVE_AUDIENCE,
  AUDIENCE_EXPORT_TYPES,
  ENUM_SYSTEM_USED,
  EXPORT_MODELED_AUDIENCES,
  LoadingStates,
  REQUIRED_FIELDS_ARE_MISSING,
} from '../../../constants';
import { Button } from '@klover/attain-design-system';
import {
  ToggleExportDialogAction,
  addNotification,
  exportAudience,
  fetchAudience,
  toggleAudienceExporting,
  toggleExportDialog,
} from '../slice';

import { fetchCustomAudience } from '../../customAudiences/slice';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useUser } from 'reactfire';
// Material UI Components
import AudienceExport from '../../../components/AudienceExport/InhouseAudienceExport';
import SeedAudienceExport from '../../../components/AudienceExport/InHouseSeedAudienceExport';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InlineExportHistory from './InlineExportHistory';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import tokens from 'tokens';
import { API_BASE_ADDRESS } from '../variables';
import {
  ACTIVE_AUDIENCES,
  AUDIENCE_EXPORT_FAILED_MESSAGE,
  AUDIENCE_EXPORT_SUCCESS_MESSAGE,
} from '../../../content';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { getAudienceExportFields } from './audienceExportSegmentConfig';
import { isFloat, isSegmentNameValid } from '../../../helper/helper';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    divider: {
      borderLeft: '0.2em solid ' + tokens.colorDividerLgBorder,
    },
    email: {
      marginTop: '24px',
      marginBottom: theme.spacing(3),
      marginLeft: '6px',
    },
    title: {
      fontFamily: 'Calibre-Semibold,sans-serif',
      fontWeight: 600,
      fontSize: 22,
      lineHeight: '32px',
      minWidth: 1000,
    },
    exportTitle: {
      fontFamily: 'Calibre-Semibold,sans-serif',
      fontWeight: 600,
      fontSize: 22,
      lineHeight: '32px',
      minWidth: 1000,
      margin: '1rem 0 0 1.5rem',
    },
    selectExport: {
      width: '100%',
    },
    controlLabel: {
      fontFamily: 'Calibre-Semibold,sans-serif',
      fontSize: 16,
      fontWeight: 500,
      paddingBottom: 4,
    },
    emailLabel: {
      fontFamily: 'Calibre-Semibold,sans-serif',
      fontSize: 16,
      fontWeight: 500,
      paddingBottom: 10,
      paddingTop: 40,
    },
    exportSeedAudienceContainer: {
      paddingTop: 20,
      backgroundColor: 'rgb(240, 239, 241)',
      padding: 15,
      marginTop: 36,
      borderRadius: 8,
      width: '100%',
    },
    divider: {
      border: '1px solid #e4e4e4',
      marginTop: 20,
      width: '100%',
    },
    exportExportButton: {
      textAlign: 'right',
      marginTop: 15,
    },
  })
);

interface Props {
  lalModelType: string;
  lalModelPrecision?: any;
  audienceName: string;
  audienceCountTotals: any;
  audienceType: string;
  isSelectAudienceVisible: boolean;
}

const InlineAudienceExport = ({
  lalModelType = 'transunion',
  lalModelPrecision,
  audienceName,
  audienceCountTotals,
  audienceType,
  isSelectAudienceVisible,
}: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { uiAudienceManagerExportSeedAudienceVisible = false } = useFlags();

  // Retrieve the current audience details
  const audience = useAppSelector((state) => {
    if (audienceType === ENUM_SYSTEM_USED.STANDARD_AUDIENCE) {
      if (state.audienceReducer && state.audienceReducer.currentAudience) {
        return state.audienceReducer.currentAudience;
      } else {
        return null;
      }
    } else {
      if (
        state.customAudienceReducer &&
        state.customAudienceReducer.currentAudience
      ) {
        return state.customAudienceReducer.currentAudience;
      } else {
        return null;
      }
    }
  });

  const lalModelPrecisionUsersCount = audienceCountTotals
    ? audienceCountTotals[lalModelPrecision]
    : null;

  const { data: user } = useUser();
  const currentUser = user || { email: '' };
  const [emails, setEmails] = useState(currentUser.email);
  const [isExporting, setIsExporting] = useState(false);
  const [selectedExportTypes, setSelectedExportTypes] = useState([]);
  const [selectedSeedAudienceExportTypes, setSelectedSeedAudienceExportTypes] =
    useState([]);
  const [exportDefaultFields, setExportDefaultFields] = useState({
    segmentName: audienceName,
    description: '',
    cpm: '',
    percentageOfMedia: '',
  });
  const [exportDynamicFields, setExportDynamicFields] = useState([]);
  const [exportDynamicValues, setExportDynamicValues] = useState([]);

  const { segmentName, description, cpm, percentageOfMedia } =
    exportDefaultFields;

  useEffect(() => {
    // Prepare UI fields based on the selected export types
    const allExports = [
      ...selectedExportTypes,
      ...selectedSeedAudienceExportTypes,
    ];
    const preparedUIFields = getAudienceExportFields(allExports);
    setExportDynamicFields(preparedUIFields);

    // Prepare form data for each UI field
    const formDataFields = initializeFormDataFields(preparedUIFields);
    setExportDynamicValues(formDataFields);
  }, [selectedExportTypes, selectedSeedAudienceExportTypes]);

  /* Handler functions | Start */

  const dispatchClose = () => {
    const actionProps: ToggleExportDialogAction = {
      open: false,
    };
    dispatch(toggleExportDialog(actionProps));
  };

  const handleSelectedExportType = (exportTypes: Array<string>) => {
    setSelectedExportTypes(exportTypes);
  };

  const handleSelectedSeedAudienceExportType = (exportTypes: Array<string>) => {
    setSelectedSeedAudienceExportTypes(exportTypes);
  };

  const handleChangeField = (id: string, value: any, segmentId: string) => {
    if (segmentId) {
      // If segmentId exists, update the export dynamic fields
      const updatedForm = updateFormData(
        exportDynamicValues,
        id,
        value,
        segmentId
      );
      setExportDynamicValues(updatedForm);
    } else {
      setExportDefaultFields({ ...exportDefaultFields, [id]: value });
    }
  };

  const updateFormData = (
    formData: any[],
    id: string,
    value: any,
    segmentId: string
  ) => {
    // Find the existing form data by segmentId
    const existingFormData = formData.find((x) => x.id === segmentId);
    if (existingFormData) {
      existingFormData[id] = value; // Update the field value

      switch (id) {
        case 'platformDestination':
          existingFormData.advertiserDestination = '';
          break;
        case 'advertiserDestination':
          if (!value) existingFormData.platformDestination = '';
          break;
        default:
          break;
      }

      return [...formData]; // Return a new array with the updated form data
    }

    return formData;
  };

  const startExport = () => {
    if (audience) {
      if (cpm !== '' && !isFloat(cpm)) {
        dispatch(
          addNotification({
            state: LoadingStates.ERROR,
            message: 'Please enter valid CPM',
          })
        );
        return;
      }
      if (percentageOfMedia !== '' && !isFloat(percentageOfMedia)) {
        dispatch(
          addNotification({
            state: LoadingStates.ERROR,
            message: 'Please enter valid % of media',
          })
        );
        return;
      }
      handleExportToTransunion();
    }
  };

  /* Handler functions | End */

  /* Util functions | Start */

  const initializeFormDataFields = (fields: any[]) => {
    return fields.map((field) => {
      // Get column names from the field form JSON
      const columnNames =
        field.formJSON
          ?.filter((column) => column.column)
          .map((column) => column.column) || [];

      // Initialize form data with the field id and export type
      const formData = { id: field.id, exportType: field.exportType };

      // Add each column as a field in the form data
      if (columnNames.length > 0) {
        columnNames.forEach((column) => {
          formData[column] = ''; // Initialize column value as empty
        });
      }

      return formData;
    });
  };

  const clearData = (isSuccess: boolean) => {
    setIsExporting(false);
    dispatchClose();
    if (isSuccess) {
      setExportDynamicFields([]);
      setSelectedExportTypes([]);
      setSelectedSeedAudienceExportTypes([]);
      setExportDynamicValues([]);
      setExportDefaultFields({
        segmentName: audienceName,
        description: '',
        cpm: '',
        percentageOfMedia: '',
      });
    }
  };

  const prepareExportPayload = () => {
    const resultPayload = [];
    const allExports = [
      ...selectedExportTypes,
      ...selectedSeedAudienceExportTypes,
    ];
    for (const exportType of allExports) {
      const payload = {
        id: audience.id,
        name: audience.name,
        emails: currentUser.email,
        segmentName,
        description,
        cpm: cpm !== '' ? parseFloat(cpm) : '',
        percentageOfMedia:
          percentageOfMedia !== '' ? parseFloat(percentageOfMedia) : '',
        lalModelType: lalModelType,
        lalModelPrecisionUsersCount: lalModelPrecisionUsersCount,
        lalModelPrecision:
          lalModelPrecision !== '' ? parseFloat(lalModelPrecision) : '',
      };
      const currentFormData = exportDynamicValues.find(
        (x) => x.exportType === exportType
      );
      if (currentFormData) {
        payload['partnerID'] = currentFormData?.partnerID || '';
        payload['advertiserID'] = currentFormData?.advertiserID || '';
        payload['tvCpm'] = currentFormData?.tvTargeting
          ? parseFloat(currentFormData?.tvTargeting)
          : '';
      }

      switch (exportType) {
        case AUDIENCE_EXPORT_TYPES.OTS_SEGMENT:
        case AUDIENCE_EXPORT_TYPES.CUSTOM_SEGMENT:
        case AUDIENCE_EXPORT_TYPES.TTD_OTS_SEGMENT_SEED_AUDIENCE:
        case AUDIENCE_EXPORT_TYPES.TTD_CUSTOM_SEGMENT_SEED_AUDIENCE: {
          const otsPayload = {
            ...payload,
            exportType,
            platformIntegrated: 'trade_desk',
          };
          resultPayload.push(otsPayload);
          break;
        }
        case AUDIENCE_EXPORT_TYPES.LIVERAMP_CUSTOM_SEGMENT: {
          const customPayload = {
            ...payload,
            exportType,
            platformIntegrated: 'liveramp',
            platformDestination: currentFormData?.platformDestination?.id || '',
            advertiserDestination:
              currentFormData?.advertiserDestination?.id || '',
            advertiserDirect: currentFormData?.advertiserDirect || '',
          };
          resultPayload.push(customPayload);
          break;
        }
        case AUDIENCE_EXPORT_TYPES.LIVERAMP_OTS_SEGMENT: {
          const customPayload = {
            ...payload,
            exportType,
            platformIntegrated: 'liveramp',
            platformDestination: '',
            advertiserDestination: '',
            advertiserDirect: currentFormData?.advertiserDirect || '',
          };
          resultPayload.push(customPayload);
          break;
        }
        case AUDIENCE_EXPORT_TYPES.TRANSUNION:
        case AUDIENCE_EXPORT_TYPES.TRANSUNION_TTD:
        case AUDIENCE_EXPORT_TYPES.TRANSUNION_HEMS: {
          const customPayload = {
            ...payload,
            exportType,
            platformIntegrated: '',
          };
          resultPayload.push(customPayload);
          break;
        }
        case AUDIENCE_EXPORT_TYPES.PUBMATIC_OTS_SEGMENT:
        case AUDIENCE_EXPORT_TYPES.PUBMATIC_CUSTOM_SEGMENT: {
          const pbPayload = {
            ...payload,
            exportType,
            percentageOfMedia: '',
            platformIntegrated: 'pubmatic',
          };
          resultPayload.push(pbPayload);
          break;
        }
        case AUDIENCE_EXPORT_TYPES.OPENX_OTS_SEGMENT:
        case AUDIENCE_EXPORT_TYPES.OPENX_CUSTOM_SEGMENT: {
          const opxPayload = {
            ...payload,
            exportType,
            description: '',
            cpm: '',
            platformIntegrated: 'openx',
            percentageOfMedia: '',
          };
          resultPayload.push(opxPayload);
          break;
        }
        case AUDIENCE_EXPORT_TYPES.EXPERIAN_OTS_SEGMENT: {
          const opxPayload = {
            ...payload,
            exportType,
            platformIntegrated: 'experian',
          };
          resultPayload.push(opxPayload);
          break;
        }
        case AUDIENCE_EXPORT_TYPES.X_OTS_SEGMENT:
        case AUDIENCE_EXPORT_TYPES.X_CUSTOM_SEGMENT: {
          const opxPayload = {
            ...payload,
            exportType,
            platformIntegrated: 'x',
          };
          resultPayload.push(opxPayload);
          break;
        }
        case AUDIENCE_EXPORT_TYPES.META_OTS_SEGMENT:
        case AUDIENCE_EXPORT_TYPES.META_CUSTOM_SEGMENT: {
          const opxPayload = {
            ...payload,
            exportType,
            platformIntegrated: 'meta',
          };
          resultPayload.push(opxPayload);
          break;
        }
        case AUDIENCE_EXPORT_TYPES.MAGNITE_OTS_SEGMENT:
        case AUDIENCE_EXPORT_TYPES.MAGNITE_CUSTOM_SEGMENT: {
          const opxPayload = {
            ...payload,
            exportType,
            platformIntegrated: 'magnite',
          };
          resultPayload.push(opxPayload);
          break;
        }
        case AUDIENCE_EXPORT_TYPES.YAHOO_DSP_OTS_SEGMENT:
        case AUDIENCE_EXPORT_TYPES.YAHOO_DSP_CUSTOM_SEGMENT: {
          const opxPayload = {
            ...payload,
            exportType,
            platformIntegrated: 'yahoo_dsp',
          };
          resultPayload.push(opxPayload);
          break;
        }
        default:
          break;
      }
    }

    return resultPayload;
  };

  const handleExportToTransunion = () => {
    setIsExporting(true);
    const payload = prepareExportPayload();
    const moduleName =
      audienceType === ENUM_SYSTEM_USED.STANDARD_AUDIENCE
        ? 'audience-manager'
        : 'custom-audience-manager';
    axios
      .post(`${API_BASE_ADDRESS}/${moduleName}/export/transunion`, payload)
      .then(() => {
        clearData(true);
        dispatch(
          addNotification({
            state: 'done',
            message: AUDIENCE_EXPORT_SUCCESS_MESSAGE,
          })
        );

        setTimeout(() => {
          if (audienceType === ENUM_SYSTEM_USED.STANDARD_AUDIENCE) {
            dispatch(fetchAudience({ id: audience.id }));
          } else {
            dispatch(fetchCustomAudience({ id: audience.id }));
          }
        }, 3000);
      })
      .catch(() => {
        clearData(false);
        dispatch(
          addNotification({
            state: 'error',
            message: AUDIENCE_EXPORT_FAILED_MESSAGE,
          })
        );
      });
  };

  /* Util functions | End */

  const {
    isValid: isValidSegmentName,
    validationMessage: inValidSegmentNameErrorMessage,
  } = isSegmentNameValid(
    [...selectedExportTypes, ...selectedSeedAudienceExportTypes],
    segmentName
  );

  const isRequiredFieldMissing = useMemo(() => {
    if (!selectedExportTypes.length && !selectedSeedAudienceExportTypes.length)
      return true;
    const allExports = [
      ...selectedExportTypes,
      ...selectedSeedAudienceExportTypes,
    ];
    return allExports.some((exportType) => {
      const currentFormData = exportDynamicValues.find(
        (x) => x.exportType === exportType
      );
      if (!currentFormData) return false;
      const {
        partnerID,
        advertiserID,
        tvTargeting,
        platformDestination,
        advertiserDestination,
      } = currentFormData;
      switch (exportType) {
        case AUDIENCE_EXPORT_TYPES.OTS_SEGMENT:
        case AUDIENCE_EXPORT_TYPES.TTD_OTS_SEGMENT_SEED_AUDIENCE:
          return (
            !exportDefaultFields.segmentName ||
            !exportDefaultFields.cpm ||
            !exportDefaultFields.percentageOfMedia
          );
        case AUDIENCE_EXPORT_TYPES.CUSTOM_SEGMENT:
        case AUDIENCE_EXPORT_TYPES.TTD_CUSTOM_SEGMENT_SEED_AUDIENCE:
          return (
            !exportDefaultFields.segmentName ||
            !exportDefaultFields.cpm ||
            !exportDefaultFields.percentageOfMedia ||
            (!partnerID && !advertiserID)
          );
        case AUDIENCE_EXPORT_TYPES.LIVERAMP_CUSTOM_SEGMENT:
          return (
            !exportDefaultFields.segmentName ||
            !exportDefaultFields.cpm ||
            !tvTargeting ||
            !platformDestination ||
            !advertiserDestination
          );
        case AUDIENCE_EXPORT_TYPES.LIVERAMP_OTS_SEGMENT:
          return (
            !exportDefaultFields.segmentName ||
            !exportDefaultFields.cpm ||
            !tvTargeting
          );
        case AUDIENCE_EXPORT_TYPES.EXPERIAN_OTS_SEGMENT:
        case AUDIENCE_EXPORT_TYPES.X_OTS_SEGMENT:
        case AUDIENCE_EXPORT_TYPES.X_CUSTOM_SEGMENT:
        case AUDIENCE_EXPORT_TYPES.META_OTS_SEGMENT:
        case AUDIENCE_EXPORT_TYPES.META_CUSTOM_SEGMENT:
        case AUDIENCE_EXPORT_TYPES.MAGNITE_OTS_SEGMENT:
        case AUDIENCE_EXPORT_TYPES.MAGNITE_CUSTOM_SEGMENT:
        case AUDIENCE_EXPORT_TYPES.YAHOO_DSP_OTS_SEGMENT:
        case AUDIENCE_EXPORT_TYPES.YAHOO_DSP_CUSTOM_SEGMENT:
          return (
            !exportDefaultFields.segmentName ||
            !exportDefaultFields.cpm ||
            !exportDefaultFields.percentageOfMedia
          );
        case AUDIENCE_EXPORT_TYPES.PUBMATIC_OTS_SEGMENT:
        case AUDIENCE_EXPORT_TYPES.PUBMATIC_CUSTOM_SEGMENT:
          return (
            !exportDefaultFields.segmentName ||
            !exportDefaultFields.cpm ||
            !exportDefaultFields.description
          );
        case AUDIENCE_EXPORT_TYPES.OPENX_OTS_SEGMENT:
        case AUDIENCE_EXPORT_TYPES.OPENX_CUSTOM_SEGMENT:
          return !exportDefaultFields.segmentName;
        default:
          return false;
      }
    });
  }, [
    exportDynamicValues,
    exportDefaultFields,
    selectedExportTypes,
    selectedSeedAudienceExportTypes,
  ]);

  return (
    <>
      <div>
        <Box
          sx={{
            flex: 3,
            border: '1px solid #ccc',
            borderRadius: 2,
            margin: '1rem 1rem 2rem 1rem',
            background: 'white',
          }}
        >
          <Typography variant="h2" className={classes.exportTitle}>
            {ACTIVE_AUDIENCES}
          </Typography>
          <DialogContent style={{ overflow: 'hidden' }}>
            <>
              <AudienceExport
                formData={exportDynamicValues}
                exportDynamicFields={exportDynamicFields}
                selectedExportType={selectedExportTypes}
                handleSelectedExportType={handleSelectedExportType}
                segmentName={segmentName}
                isSegmentNameValid={isValidSegmentName}
                inValidSegmentNameErrorMessage={inValidSegmentNameErrorMessage}
                description={description}
                cpm={cpm}
                percentageOfMedia={percentageOfMedia}
                onChange={handleChangeField}
                isSelectAudienceVisible={isSelectAudienceVisible}
              />
              {uiAudienceManagerExportSeedAudienceVisible && (
                <>
                  <Box className={classes.exportSeedAudienceContainer}>
                    <SeedAudienceExport
                      selectedSeedAudienceExportTypes={
                        selectedSeedAudienceExportTypes
                      }
                      handleSelectedSeedAudienceExportType={
                        handleSelectedSeedAudienceExportType
                      }
                      lalModelType={lalModelType}
                      audienceType={audienceType}
                    />
                  </Box>
                </>
              )}
              <div className={classes.exportExportButton}>
                <Tooltip
                  title={
                    isRequiredFieldMissing ? REQUIRED_FIELDS_ARE_MISSING : ''
                  }
                  placement="top"
                  arrow
                >
                  <span>
                    <Button
                      color="primary"
                      disableElevation
                      onClick={() => startExport()}
                      variant="contained"
                      style={{ marginBottom: 15 }}
                      disabled={
                        !selectedExportTypes ||
                        isExporting ||
                        (selectedExportTypes.includes(
                          AUDIENCE_EXPORT_TYPES.KLOVER_IDS
                        ) &&
                          !emails) ||
                        (selectedExportTypes.includes(
                          AUDIENCE_EXPORT_TYPES.RAMP_ID
                        ) &&
                          !emails) ||
                        isRequiredFieldMissing ||
                        !isValidSegmentName
                      }
                    >
                      {isExporting ? <CircularProgress size={20} /> : 'Export'}
                    </Button>
                  </span>
                </Tooltip>
              </div>
            </>
          </DialogContent>
        </Box>
        <Box
          sx={{
            flex: 3,
            border: '1px solid #ccc',
            borderRadius: 2,
            margin: '0rem 1rem 6rem 1rem',
            background: 'white',
          }}
        >
          <InlineExportHistory audience={audience} />
        </Box>
      </div>
    </>
  );
};

export default InlineAudienceExport;
