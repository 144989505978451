import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, { useMemo, useState } from 'react';
import { Button } from '@klover/attain-design-system';
import { ENUM_SYSTEM_USED } from '../../constants';
import { Menu, MenuItem } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

const CreateAudienceDropdown: any = (props: any) => {
  const {
    uiAudienceManagerLalModelingVisible = false,
    uiCustomAudienceManagerLalModelingVisible = false,
  } = useFlags();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { handleMenuItemClick, audienceType } = props;
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showInHouseMenuItem = useMemo(() => {
    if (
      audienceType === ENUM_SYSTEM_USED.STANDARD_AUDIENCE &&
      uiAudienceManagerLalModelingVisible
    ) {
      return true;
    } else if (
      audienceType === ENUM_SYSTEM_USED.CUSTOM_AUDIENCE &&
      uiCustomAudienceManagerLalModelingVisible
    ) {
      return true;
    }
    return false;
  }, [audienceType]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        endIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
      >
        Create Audience
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            handleMenuItemClick('transunion');
          }}
        >
          Create with TransUnion
        </MenuItem>
        {showInHouseMenuItem && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              handleMenuItemClick('attain');
            }}
          >
            Create with In-House Modeling
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default CreateAudienceDropdown;
