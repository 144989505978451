import React, { useMemo } from 'react';
import Switch from '@material-ui/core/Switch';
import tokens from 'tokens';
import {
  AUDIENCE_MODEL_SKIP_MESSAGE,
  MODEL_YOUR_AUDIENCE,
  RECOMMANDED,
} from '../../content';
import { Box, CircularProgress } from '@material-ui/core';
import { Button } from '@klover/attain-design-system';
import { PaperPlaneRight } from '@phosphor-icons/react';
import { Tag } from 'antd';
import {
  Theme,
  createStyles,
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Define prop types
interface ModelAudienceButtonProps {
  modelYourAudience: () => void;
  isModeling: boolean;
  lalModelStatus: string;
  lalModelCompletedAt: any;
  isModelingSkipped: boolean;
  setIsModelingSkipped: (arg0: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 50,
      marginBottom: 100,
      marginLeft: 50,
      marginRight: 50,
      gap: 10,
      padding: 50,
      border: '1px solid rgb(205, 199, 209)',
      borderRadius: '8px',
      background: '#fff',
    },
    recommandedContainer: {
      display: 'flex',
      gap: 5,
      alignItems: 'center',
    },
    recommandedHeaderText: {
      fontFamily: 'Calibre-Regular',
      fontWeight: 600,
      fontSize: 22,
    },
    recommandedSwitchContainer: {
      display: 'flex',
      gap: 15,
      alignItems: 'center',
      paddingTop: 5,
    },
    skippableContentText: {
      fontSize: 16,
    },
    skippableContentSubText: {
      fontSize: 12,
    },
    skippableContentContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    modelAudienceBtnContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 2,
      padding: '50px 0 20px',
    },
    text: {
      textTransform: 'none',
      fontFamily: 'Calibre-Regular',
      fontWeight: 600,
      fontSize: 18,
      marginRight: 8,
    },
    icon: {
      marginLeft: 8,
    },
    caption: {
      marginTop: theme.spacing(1),
      fontFamily: 'Calibre-Regular',
      fontSize: 14,
    },
  })
);

const ModelAudienceButton: React.FC<ModelAudienceButtonProps> = (props) => {
  const classes = useStyles();
  const {
    modelYourAudience,
    isModeling,
    lalModelStatus,
    lalModelCompletedAt,
    isModelingSkipped = true,
    setIsModelingSkipped,
  } = props;
  const { uiAudienceManagerExportSeedAudienceVisible = false } = useFlags();
  const handleToggle = (event: any) => {
    setIsModelingSkipped(event.target.checked);
  };

  const lastDateTimeModeledAt = useMemo(() => {
    if (lalModelCompletedAt) {
      const date = new Date(lalModelCompletedAt); // Convert to a Date object
      // Format the date as desired
      return date.toLocaleString();
    }
    return 'Audience never modeled';
  }, [lalModelCompletedAt]);

  return (
    <Box className={classes.container}>
      {uiAudienceManagerExportSeedAudienceVisible && (
        <>
          <div className={classes.recommandedContainer}>
            <span className={classes.recommandedHeaderText}>
              {MODEL_YOUR_AUDIENCE}
            </span>
            <Tag color="geekblue" style={{ marginLeft: 15 }}>
              {RECOMMANDED}
            </Tag>
          </div>
          <div className={classes.recommandedSwitchContainer}>
            <div>
              <ModelAudienceSwitch
                onChangeHandler={handleToggle}
                checked={isModelingSkipped}
              />
            </div>
            <div className={classes.skippableContentContainer}>
              <div className={classes.skippableContentText}>
                {isModelingSkipped ? 'On' : 'Off'}
              </div>
              <div className={classes.skippableContentSubText}>
                {AUDIENCE_MODEL_SKIP_MESSAGE}
              </div>
            </div>
          </div>
        </>
      )}
      <div className={classes.modelAudienceBtnContainer}>
        <div>
          <Button
            variant="contained"
            onClick={modelYourAudience}
            disabled={isModeling || lalModelStatus === 'IN_PROGRESS'} // Disable button while modeling
          >
            <>
              {MODEL_YOUR_AUDIENCE}
              {isModeling || lalModelStatus === 'IN_PROGRESS' ? (
                <CircularProgress
                  size={24}
                  color="inherit"
                  style={{ marginLeft: 8 }}
                />
              ) : (
                <PaperPlaneRight size={22} className={classes.icon} />
              )}
            </>
          </Button>
        </div>

        <div className={classes.caption}>
          Last Modified: {lastDateTimeModeledAt}
        </div>
      </div>
    </Box>
  );
};

const ModelAudienceSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: tokens.colorButtonSuccess,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: tokens.colorButtonSuccess,
      border: '6px solid ' + tokens.colorButtonDefault,
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, checked, onChangeHandler }) => {
  return (
    <Switch
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      onChange={onChangeHandler}
      checked={checked}
    />
  );
});

export default ModelAudienceButton;
