import { makeStyles } from '@material-ui/core/styles';

import KloverPiggyLogo from 'assets/images/KloverPiggyLogo.png';
import OpenLinkIcon from 'assets/images/open-new-window.png';
import WhiteBar from 'assets/images/white.png';
import tokens from 'tokens';

const circularLogoRadius = 60;
const dashBoardWidth = 415;
const textShift = -83;
const emojiDiameter = 40;
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: tokens.colorSecondary,
    color: tokens.colorDefault,
    position: 'relative',
    borderRadius: 12,
    border: '1px solid ' + tokens.colorSuccess,
    boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.07)',
    width: dashBoardWidth,
    height: 388,
  },
  imageCropper: {
    height: 'auto',
    overflow: 'hidden',
    '& img': {
      height: 'auto',
      width: dashBoardWidth,
      borderRadius: 10,
    },
  },
  comparisonOfPricesContainerPadding: {
    width: '50%',
    paddingBottom: '1.4em',
  },
  textNextToEmoji: {
    fontSize: 20,
    fontFamily: ['roboto', 'helvetica', 'arial', 'sans-serif'].join(','),
  },
  actionTextContainer: {
    display: 'flex',
    paddingLeft: '1em',
    paddingRight: '1em',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  comparisonOfPricesContainer: {
    color: tokens.colorDanger,
    width: '100%',
    padding: '1em',
    paddingRight: '1.8em',
    fontFamily: ['roboto', 'helvetica', 'arial', 'sans-serif'].join(','),
    display: 'flex',
    justifyContent: 'space-between',
  },
  comparedValueText: {
    color: 'white',
    fontFamily: ['roboto', 'helvetica', 'arial', 'sans-serif'].join(','),
    fontSize: 17,
  },
  actionTextPadding: {
    paddingTop: '1em',
    paddingBottom: '1.2em',
    borderTop: '3px solid ' + tokens.colorLight,
    width: dashBoardWidth,
  },
  openLinkIconPadding: {
    paddingTop: 5,
    paddingRight: 5,
  },
  containerWithBackgroundAndLogo: {
    overflow: 'hidden',
    paddingBottom: '1em',
  },
  containerWithComparisons: {
    display: 'flex',
    alignItems: 'center',
  },
  comparisonEmojiAndTextContainer: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
  },
  actionText: {
    marginLeft: 5,
    fontSize: 17,
    fontFamily: ['roboto', 'helvetica', 'arial', 'sans-serif'].join(','),
  },
  circularLogo: {
    width: circularLogoRadius - 10,
    height: 'auto',
    backgroundColor: tokens.colorDefault,
  },
  circularLogoContainer: {
    display: 'flex',
    height: circularLogoRadius,
    width: circularLogoRadius,
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: tokens.colorDefault,
    overflow: 'hidden',
  },
  circularLogoContainerPadding: {
    paddingRight: '0.8em',
    paddingLeft: '0.8em',
    paddingTop: '0.4em',
  },
  comparisonEmoji: {
    textAlign: 'center',
    fontSize: 23,
  },
  comparisonEmojiContainer: {
    display: 'flex',
    width: emojiDiameter,
    height: emojiDiameter,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: tokens.colorDark,
    borderRadius: '50%',
  },
  comparisonEmojiPadding: {
    paddingLeft: '1em',
    paddingRight: '0.5em',
  },
  pictureAndTextContainer: {
    position: 'relative',
    top: textShift,
    marginBottom: textShift,
    width: dashBoardWidth,
    display: 'flex',
    overflow: 'hidden',
    height: 70,
  },
  openLinkIcon: {
    height: 17,
  },
  comparisonText: {
    fontFamily: ['roboto', 'helvetica', 'arial', 'sans-serif'].join(','),
    fontSize: 17,
  },
  catcherText: {
    width: '260px',
    fontSize: 15,
    lineHeight: '1.5em',
    fontFamily: ['roboto', 'helvetica', 'arial', 'sans-serif'].join(','),
  },
  headingBodyDesign: {
    textShadow: '1px 1px 1.5px ' + tokens.colorLight,
  },
  offerTitle: {
    fontFamily: ['roboto', 'helvetica', 'arial', 'sans-serif'].join(','),
    fontSize: 20,
  },
  offerNameStyling: {
    textAlign: 'right',
  },
}));

interface DashboardPreviewCardProps {
  logourl: string;
  headline: string;
  offername: string;
  ctatext: string;
  points: number;
  backgroundimgurl: string;
  comparisontext: string;
  comparisonemoji: number;
  price: number;
}

function DashboardDynamicPreviewCard({
  logourl,
  headline,
  offername,
  ctatext,
  points,
  backgroundimgurl,
  comparisontext,
  comparisonemoji,
  price,
}: DashboardPreviewCardProps) {
  const classes = useStyles();

  const getDisplayComparisonEmoji = () => {
    try {
      return String.fromCodePoint(comparisonemoji);
    } catch (e) {}
  };

  return (
    <div className={classes.root}>
      <div className={classes.containerWithBackgroundAndLogo}>
        <div className={classes.imageCropper}>
          <img src={backgroundimgurl || WhiteBar} alt={`${offername}-logo`} />
        </div>
        <div className={classes.pictureAndTextContainer}>
          <div className={classes.circularLogoContainerPadding}>
            <div className={classes.circularLogoContainer}>
              <img
                className={classes.circularLogo}
                src={logourl || KloverPiggyLogo}
                alt={`${offername}-logo-1`}
              />
            </div>
          </div>
          <div className={classes.headingBodyDesign}>
            <div className={classes.offerTitle}>{offername}</div>
            <div className={classes.catcherText}>{headline}</div>
          </div>
        </div>
      </div>
      <div className={classes.containerWithComparisons}>
        {/* comparison Empoji and Comparision text */}
        <div className={classes.comparisonEmojiAndTextContainer}>
          <div className={classes.comparisonEmojiPadding}>
            <div className={classes.comparisonEmojiContainer}>
              <div className={classes.comparisonEmoji}>
                {<div>{getDisplayComparisonEmoji()}</div>}
              </div>
            </div>
          </div>
          <div className={classes.textNextToEmoji}>{comparisontext}</div>
        </div>
        {/* comparison of current vs potential phone bill*/}
        <div className={classes.comparisonOfPricesContainerPadding}>
          <div className={classes.comparisonOfPricesContainer}>
            <div>
              Current
              <br />
              <div className={classes.comparedValueText}>--</div>
            </div>
            <div className={classes.offerNameStyling}>
              {offername}
              <br />
              <div className={classes.comparedValueText}>${price}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.actionTextPadding}>
        <div className={classes.actionTextContainer}>
          <div className={classes.actionText}>
            {ctatext ? `${ctatext} ` : null}
            {ctatext ? `🏆 ` : null}
            {points ? `${ctatext ? ' ' : ' Get'} ${points} points ` : null}
          </div>
          <div className={classes.openLinkIconPadding}>
            {points || ctatext ? (
              <img
                className={classes.openLinkIcon}
                src={OpenLinkIcon}
                alt="open-link-icon"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardDynamicPreviewCard;
