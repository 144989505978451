import _ from 'lodash';
import { useAppSelector } from 'redux/hooks';
import * as Styled from './index.styles';
const checkMark = String.fromCodePoint(9989);
const xMark = String.fromCodePoint(10060);

// Define fields for Offer Wall placement
const offerWallFields = [
  { key: 'label', label: 'Offer Label' },
  { key: 'offername', label: 'Offer Name' },
  { key: 'logourl', label: 'Image Url' },
  { key: 'backgroundimgurl', label: 'Background Image Url' },
  { key: 'fullscreenimageurl', label: 'Full Screen Image Url' },
  { key: 'headline', label: 'Subline' },
  { key: 'description', label: 'Body' },
  { key: 'ctatext', label: 'Call To Action' },
  { key: 'category', label: 'Category' },
];

// Define fields for Dashboard placement
const dashboardFields = [
  { key: 'label', label: 'Offer Label' },
  { key: 'offername', label: 'Offer Name' },
  { key: 'logourl', label: 'Image Url' },
  { key: 'backgroundimgurl', label: 'Background Image Url' },
  { key: 'headline', label: 'Subline' },
  { key: 'description', label: 'Body' },
  { key: 'ctatext', label: 'Call To Action' },
];

// Define fields for Klover Plus placement
const kloverPlusFields = [
  { key: 'label', label: 'Offer Label' },
  { key: 'logourl', label: 'Image Url' },
  { key: 'headline', label: 'Subline' },
  { key: 'description', label: 'Body' },
  { key: 'ctatext', label: 'Call To Action' },
];

// Define fields for Onboarding placement
const onBoardingFields = [
  { key: 'label', label: 'Offer Label' },
  { key: 'logourl', label: 'Image Url' },
  { key: 'backgroundimgurl', label: 'Background Image Url' },
  { key: 'offername', label: 'Headline' },
  { key: 'headline', label: 'Subline' },
  { key: 'description', label: 'Body' },
  { key: 'ctatext', label: 'Call To Action' },
];

/***
 * Get validation fields based on the placement type of the current offer.
 * @param currentOffer - The current offer object.
 * @returns Array of validation fields for the current offer placement.
 */
function getValidationFields(currentOffer) {
  const placementIds = currentOffer?.placementid || [];

  const placementFieldMapping = {
    '1': offerWallFields,
    '2': dashboardFields,
    '3': kloverPlusFields,
    '4': onBoardingFields,
  };

  const allFields = placementIds.flatMap(
    (id) => placementFieldMapping[id] || []
  );
  const validationFields = Array.from(
    new Map(allFields.map((item) => [item.key, item])).values()
  );

  if (
    (placementIds.includes('1') || placementIds.includes('2')) &&
    currentOffer?.comparisonemoji
  ) {
    validationFields.push({
      key: 'comparisontext',
      label: 'Comparison Text',
    });
  }

  return validationFields;
}

const OfferPropertyComponent = ({ currentOffer, keyName, label }) => {
  let isValue = currentOffer && keyName && currentOffer[keyName];
  if (
    currentOffer &&
    keyName == 'category' &&
    Array.isArray(isValue) &&
    isValue.length === 0
  ) {
    isValue = false;
  }
  return (
    <Styled.RequiredRow>
      <Styled.RequiredRowEmoji>
        {isValue ? checkMark : xMark}
      </Styled.RequiredRowEmoji>
      <div>{label ? _.startCase(label) : null}</div>
    </Styled.RequiredRow>
  );
};

const SaveOfferDisabledButtonMessage = () => {
  const currentOffer = useAppSelector(
    (state) => state.offerWallReducer.currentOffer
  );
  const fieldsToValidate = getValidationFields(currentOffer);

  return (
    <Styled.MessageTextAlignment>
      <div>{"Hi! Looks like you're trying to save"}</div>
      <br />
      <div>To save make sure you have</div>
      <br />
      {currentOffer ? (
        <>
          {fieldsToValidate?.map((field) => {
            return (
              <OfferPropertyComponent
                currentOffer={currentOffer}
                keyName={field.key}
                label={field.label}
              />
            );
          })}
        </>
      ) : null}
    </Styled.MessageTextAlignment>
  );
};

/**
 * Component to display validation properties of the current offer.
 * @returns JSX element representing the offer properties for validation.
 */
export const OfferAllProperties = () => {
  const currentOffer = useAppSelector(
    (state) => state.offerWallReducer.currentOffer
  );

  // Get validation fields for the current offer placement
  const fieldsToValidate = getValidationFields(currentOffer);

  return (
    <Styled.MessageTextAlignment>
      {fieldsToValidate?.map((field) => {
        return (
          <OfferPropertyComponent
            currentOffer={currentOffer}
            keyName={field.key}
            label={field.label}
          />
        );
      })}
    </Styled.MessageTextAlignment>
  );
};

export default SaveOfferDisabledButtonMessage;
