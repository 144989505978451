import React, { useEffect, useState } from 'react';
import { IconButton, Menu, MenuItem } from '@klover/attain-design-system';
import {
  createAudience,
  saveCustomAudience,
  updateCurrentCustomAudience,
} from '../slice';

import { nanoid } from 'nanoid';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useUser } from 'reactfire';
// Material UI Components
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  ClockCounterClockwise,
  DotsThreeVertical,
  Export,
  FloppyDiskBack,
  PencilSimple,
  X,
} from '@phosphor-icons/react';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

// Components
import AudienceDeleteDialog from '../../audienceManager/audienceDeleteDialog';

import BootstrapTooltip from 'components/bootstrapTooltip';
import ExportDialog from '../exportDialog';
import ExportHistroryDialog from '../../audienceManager/exportHistoryDialog';
import tokens from 'tokens';
import { AUDIENCE_NAME_LENGTH_VALIDATION_MESSAGE } from '../../../content';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    audienceName: {
      fontFamily: 'Calibre-Semibold, sans-serif',
      fontSize: 24,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: 400,
      marginRight: 10,
    },
    groupName: {
      fontFamily: 'Calibre-Regular,sans-serif',
      fontSize: 16,
    },
    editAudienceName: {
      fontFamily: 'Calibre-Regular,sans-serif',
      width: 400,
    },
    button: {
      margin: `${theme.spacing(0, 1)} !important`,
    },
    exportSpinner: {
      margin: '0.15em 1.2em',
    },
    editButtonBox: {
      marginLeft: theme.spacing(4),
    },
    form: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    menuItem: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 5,
    },
    audienceNameContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2px',
      marginTop: 1,
    },
    saveBtn: {
      color: 'red',
      fontSize: '13px',
    },
  })
);

const inputs = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        maxHeight: 48,
      },
    },
    MuiFormLabel: {
      root: {
        maxHeight: 48,
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: -5,

        '&$focused': {
          marginTop: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: 48,
      },
      input: {
        borderRadius: 10,
        alignSelf: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
      input: {
        padding: '12px 14px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 10,
      },
    },
  },
});

interface AudienceNameProps {
  isAudienceLoading: boolean;
  isCustomAudienceCreated: boolean;
  audienceid: number;
  name: string;
  setName: any;
}

// we need to know when audience is loading to determine if we should disable some text fields and buttons
const AudienceName: React.FC<AudienceNameProps> = ({
  isAudienceLoading,
  isCustomAudienceCreated,
  audienceid,
  name,
  setName,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [notChanging, setNotChanging] = useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openExportHistoryDialog, setOpenExportHistoryDialog] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { data: user } = useUser();
  const currentUser = user || { email: '' };

  const currentGroup = useAppSelector((state) => {
    return state.customAudienceReducer.currentGroup;
  });

  const currentAudience = useAppSelector((state) => {
    return state.customAudienceReducer.currentAudience;
  });

  const lalModelType = currentAudience?.lalModelType;
  const isExportDisabled = currentAudience?.size === 0;
  const openAudienceAction = Boolean(anchorEl);

  const isError = name?.length > 175 || false;

  useEffect(() => {
    setName(currentAudience?.name || '');
    const isNameBlank = currentAudience?.name
      ? currentAudience.name === ''
      : true;
    setEditMode(isNameBlank);
  }, [currentAudience]);

  const exportHandler = () => {
    setOpenModal(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    if (currentAudience) {
      setName(currentAudience?.name);
    }
  };

  const handleEditName = () => {
    setEditMode(true);
  };

  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const name = event.target.value as string;
    setName(name);
  };

  const handleSave = () => {
    if (currentAudience) {
      const updatedAudience = {
        ...currentAudience,
        name: name,
        updated_by_email: currentUser.email,
      };

      const audienceExists = currentAudience.id;
      if (audienceExists) {
        dispatch(saveCustomAudience(updatedAudience));
      } else {
        dispatch(createAudience(updatedAudience));
      }
      dispatch(updateCurrentCustomAudience(updatedAudience));
    }
  };

  const isCustomAudienceFileProcessing = currentAudience.status === 'LOADING';

  return (
    <>
      <ThemeProvider theme={inputs}>
        {editMode ? (
          <Box className={classes.form}>
            <div className={classes.audienceNameContainer}>
              <TextField
                disabled={isAudienceLoading}
                key={currentAudience?.name && notChanging ? nanoid() : null} // setting the key to the audience name to force confimation of render so that there is no overlap between audience name and label.
                onFocus={() => setNotChanging(false)} // but on focus we want to switch off the forced render
                id="audience-name"
                label="Audience Name"
                onChange={handleNameChange}
                defaultValue={currentAudience?.name}
                value={name}
                variant="outlined"
                style={{ marginRight: 8 }}
                className={classes.editAudienceName}
                InputProps={
                  isCustomAudienceCreated && {
                    endAdornment: (
                      <InputAdornment position="end">
                        <BootstrapTooltip title={'Save Audience'}>
                          <span>
                            <IconButton
                              color="default"
                              disabled={
                                name.length === 0 ||
                                isError ||
                                isAudienceLoading
                              }
                              onClick={handleSave}
                              size="small"
                            >
                              <FloppyDiskBack size={24} />
                            </IconButton>
                          </span>
                        </BootstrapTooltip>
                      </InputAdornment>
                    ),
                  }
                }
              />
              {isError && (
                <span className={classes.saveBtn}>
                  {AUDIENCE_NAME_LENGTH_VALIDATION_MESSAGE}
                </span>
              )}
            </div>
            {isCustomAudienceCreated && (
              <BootstrapTooltip title={'Exit Save Audience'}>
                <span>
                  <IconButton
                    disabled={isAudienceLoading}
                    onClick={handleCancel}
                  >
                    <X size={24} />
                  </IconButton>
                </span>
              </BootstrapTooltip>
            )}
          </Box>
        ) : (
          <>
            <Box flexDirection="column">
              <BootstrapTooltip title={currentAudience?.name}>
                <Typography
                  className={classes.audienceName}
                  component="h2"
                  variant="h6"
                >
                  {currentAudience?.name}
                </Typography>
              </BootstrapTooltip>

              <Typography
                component="h3"
                variant="body2"
                className={classes.groupName}
              >
                {currentGroup?.name}
              </Typography>
            </Box>
            <BootstrapTooltip
              title={
                isAudienceLoading
                  ? 'Wait for Audience Load'
                  : 'Edit Audience Name'
              }
            >
              <IconButton
                color="default"
                disabled={isAudienceLoading}
                onClick={handleEditName}
              >
                <PencilSimple size={24} />
              </IconButton>
            </BootstrapTooltip>
            <BootstrapTooltip title="Actions" placement="top">
              <span aria-describedby="audience-action-btn">
                <IconButton
                  color="default"
                  disabled={isAudienceLoading}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  <DotsThreeVertical size={24} weight="bold" />
                </IconButton>

                <Menu
                  id="audience-action-btn"
                  open={openAudienceAction}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  onBlur={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  {lalModelType !== 'attain' && (
                    <MenuItem
                      onClick={exportHandler}
                      disabled={
                        isExportDisabled || isCustomAudienceFileProcessing
                      }
                    >
                      <span
                        title={'Save to Export'}
                        className={classes.menuItem}
                      >
                        <Export size={22} />
                        Export
                      </span>
                    </MenuItem>
                  )}
                  <MenuItem onClick={() => setOpenExportHistoryDialog(true)}>
                    <span
                      title="Show Audience History"
                      className={classes.menuItem}
                    >
                      <ClockCounterClockwise size={22} />
                      Audience History
                    </span>
                  </MenuItem>
                </Menu>
              </span>
            </BootstrapTooltip>
          </>
        )}
      </ThemeProvider>
      <AudienceDeleteDialog
        isOpen={openDeleteDialog}
        setOpenDialog={setOpenDeleteDialog}
        user={currentUser.email}
      />
      <ExportHistroryDialog
        isOpen={openExportHistoryDialog}
        setOpenDialog={setOpenExportHistoryDialog}
        data={currentAudience?.exportHistory || []}
        normalAudience={currentAudience}
      />
      <ExportDialog
        isOpen={openModal}
        setIsOpen={setOpenModal}
        audienceId={audienceid}
        audienceName={currentAudience?.name}
      />
    </>
  );
};

export default AudienceName;
