import AudienceExport from '../../../components/AudienceExport';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import getAuthorizedAxiosInstance from 'utils/getAuthorizedAxiosInstance';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import {
  Button,
  Dialog,
  Grid,
  MenuItem,
  Select,
} from '@klover/attain-design-system';

import {
  AUDIENCE_EXPORT_FAILED_MESSAGE,
  AUDIENCE_EXPORT_SUCCESS_MESSAGE,
} from '../../../content';
import {
  AUDIENCE_EXPORT_TYPES,
  ExportAudience,
  SELECT_EXPORT_TYPE,
} from '../../../constants';
import { CircularProgress, Tooltip } from '@mui/material';
import {
  KDSPageNames,
  LoadingStates,
  REQUIRED_FIELDS_ARE_MISSING,
} from 'constants/index';
import { SelectChangeEvent } from '@mui/material/Select';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { addNotification } from 'pages/audienceManager/slice';
import { isFloat, isSegmentNameValid } from '../../../helper/helper';
import { useAppDispatch } from 'redux/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo, useState } from 'react';
import { useUser } from 'reactfire';

interface Props {
  audienceId: number;
  audienceName: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isValidSegmentName: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    title: {
      fontFamily: 'Calibre-Semibold,sans-serif',
      fontWeight: 600,
      fontSize: 22,
      lineHeight: '32px',
      minWidth: 1000,
    },
    selectExport: {
      width: '100%',
    },
    selectExportTypeLabel: {
      fontFamily: 'Calibre-Semibold,sans-serif',
      fontSize: 16,
      fontWeight: 500,
      paddingBottom: 4,
    },
  })
);

const ExportDialog = (props: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const {
    uiAudienceManagerExportLiveRampVisible = false,
    uiAudienceManagerTransunionExportLiveRampOtsVisible = false,
  } = useFlags();
  const { data: user } = useUser();
  const currentUser = user || { email: '' };
  const { audienceId, audienceName, isOpen, setIsOpen } = props;
  const [isExporting, setIsExporting] = useState(false);

  const selectOptions = useMemo(() => {
    const options = [
      { text: ExportAudience.TRANSUNION, value: 'transunion' },
      { text: ExportAudience.CUSTOM_SEGMENT, value: 'custom_segment' },
      { text: ExportAudience.OTS_SEGMENT, value: 'ots_segment' },
    ];

    if (uiAudienceManagerExportLiveRampVisible) {
      options.push({
        text: ExportAudience.LIVERAMP_CUSTOM_SEGMENT,
        value: 'liveramp_custom_segment',
      });
    }

    if (uiAudienceManagerTransunionExportLiveRampOtsVisible) {
      options.push({
        text: ExportAudience.LIVERAMP_OTS_SEGMENT,
        value: 'liveramp_ots_segment',
      });
    }

    return options;
  }, [
    uiAudienceManagerExportLiveRampVisible,
    uiAudienceManagerTransunionExportLiveRampOtsVisible,
  ]);

  const [selectedExportType, setSelectedExportType] = useState('');
  const [segmentName, setSegmentName] = useState('');
  const [description, setDescription] = useState('');
  const [cpm, setCpm] = useState('');
  const [percentageOfMedia, setPercentageOfMedia] = useState('');
  const [partnerID, setPartnerID] = useState('');
  const [advertiserID, setAdvertiserID] = useState('');
  const [platformDestination, setPlatformDestination] = useState('');
  const [advertiserDestination, setAdvertiserDestination] = useState('');
  const [tvTargeting, setTvTargeting] = useState('');
  const [advertiserDirect, setAdvertiserDirect] = useState('');

  const handleSegmentName = (event: React.ChangeEvent<{ value: unknown }>) => {
    const data = event.target.value as string;
    setSegmentName(data);
  };

  const handleDescription = (event: React.ChangeEvent<{ value: unknown }>) => {
    const data = event.target.value as string;
    setDescription(data);
  };

  const handleCpm = (event: any) => {
    setCpm(event.target.value);
  };

  const handlePercentageOfMedia = (event: any) => {
    setPercentageOfMedia(event.target.value);
  };

  const handlePartnerID = (event: any) => {
    setPartnerID(event.target.value);
  };

  const handleAdvertiserID = (event: any) => {
    setAdvertiserID(event.target.value);
  };

  const handleTvTargeting = (event: any) => {
    setTvTargeting(event.target.value);
  };

  const handleAdvertiserDirect = (event: any) => {
    setAdvertiserDirect(event.target.value);
  };

  const handleExportToTransunion = async (
    exportType: string,
    platformIntegrated: string
  ) => {
    try {
      if (cpm !== '' && !isFloat(cpm)) {
        dispatch(
          addNotification({
            state: LoadingStates.ERROR,
            message: 'Please enter valid CPM',
          })
        );
        return;
      }
      if (percentageOfMedia !== '' && !isFloat(percentageOfMedia)) {
        dispatch(
          addNotification({
            state: LoadingStates.ERROR,
            message: 'Please enter valid % of media',
          })
        );
        return;
      }
      setIsExporting(true);
      const payload = [
        {
          id:
            typeof audienceId === 'string'
              ? parseInt(audienceId, 10)
              : audienceId,
          name: audienceName,
          emails: currentUser.email,
          exportType,
          segmentName,
          description,
          cpm: cpm !== '' ? parseFloat(cpm) : '',
          percentageOfMedia:
            percentageOfMedia !== '' ? parseFloat(percentageOfMedia) : '',
          partnerID,
          advertiserID,
          platformIntegrated,
          platformDestination: platformDestination,
          advertiserDestination: advertiserDestination,
          tvCpm: tvTargeting !== '' ? parseFloat(tvTargeting) : '',
          lalModelType: 'transunion',
        },
      ];
      const axiosInstance = await getAuthorizedAxiosInstance();
      if (axiosInstance) {
        await axiosInstance.post(
          `${API_BASE_ADDRESS}/${KDSPageNames.CUSTOMAUDIENCES}/export/transunion`,
          payload
        );
        dispatch(
          addNotification({
            state: LoadingStates.DONE,
            message: AUDIENCE_EXPORT_SUCCESS_MESSAGE,
          })
        );
      } else {
        throw { message: 'Failed to authorize axios instance' };
      }
    } catch (e) {
      console.log(e);
      dispatch(
        addNotification({
          state: LoadingStates.ERROR,
          message: AUDIENCE_EXPORT_FAILED_MESSAGE,
        })
      );
    }
    handleClose();
  };

  const startExport = () => {
    if (selectedExportType === 'transunion') {
      handleExportToTransunion('transunion', '');
    } else if (selectedExportType === 'transunion_ttd') {
      handleExportToTransunion('transunion_ttd', '');
    } else if (selectedExportType === 'transunion_hems') {
      handleExportToTransunion('transunion_hems', '');
    } else if (selectedExportType === 'custom_segment') {
      handleExportToTransunion('custom_segment', 'trade_desk');
    } else if (selectedExportType === 'ots_segment') {
      handleExportToTransunion('ots_segment', 'trade_desk');
    } else if (selectedExportType === 'liveramp_custom_segment') {
      handleExportToTransunion('liveramp_custom_segment', 'liveramp');
    } else if (selectedExportType === 'liveramp_ots_segment') {
      handleExportToTransunion('liveramp_ots_segment', 'liveramp');
    } else if (selectedExportType === 'experian_ots_segment') {
      handleExportToTransunion('experian_ots_segment', 'experian');
    }
  };

  const clearData = () => {
    setSegmentName('');
    setDescription('');
    setCpm('');
    setPercentageOfMedia('');
    setPartnerID('');
    setAdvertiserID('');
  };

  const handleClose = () => {
    setSelectedExportType('');
    clearData();
    setIsExporting(false);
    setIsOpen(false);
  };

  const isRequiredFieldMissing = useMemo(() => {
    if (selectedExportType === 'custom_segment') {
      if (
        segmentName === '' ||
        cpm === '' ||
        percentageOfMedia === '' ||
        (partnerID === '' && advertiserID === '')
      ) {
        return true;
      }
    } else if (selectedExportType === 'ots_segment') {
      if (segmentName === '' || cpm === '' || percentageOfMedia === '') {
        return true;
      }
    } else if (selectedExportType === 'liveramp_custom_segment') {
      if (
        segmentName === '' ||
        cpm === '' ||
        tvTargeting === '' ||
        platformDestination === '' ||
        advertiserDestination === ''
      ) {
        return true;
      }
    } else if (
      selectedExportType === AUDIENCE_EXPORT_TYPES.LIVERAMP_OTS_SEGMENT
    ) {
      if (segmentName === '' || cpm === '' || tvTargeting === '') {
        return true;
      }
    } else if (
      selectedExportType === AUDIENCE_EXPORT_TYPES.EXPERIAN_OTS_SEGMENT
    ) {
      if (segmentName === '' || cpm === '' || percentageOfMedia === '') {
        return true;
      }
    }
    return false;
  }, [
    selectedExportType,
    segmentName,
    cpm,
    percentageOfMedia,
    partnerID,
    advertiserID,
    platformDestination,
    advertiserDestination,
    tvTargeting,
  ]);

  const {
    isValid: isValidSegmentName,
    validationMessage: inValidSegmentNameErrorMessage,
  } = isSegmentNameValid([selectedExportType], segmentName);

  return (
    <Dialog
      className={classes.root}
      onClose={handleClose}
      open={isOpen}
      maxWidth="lg"
      disableEnforceFocus
      headerID="customAudienceExportHeader"
      header={
        <Typography variant="h2" className={classes.title}>
          Export Custom Audience
        </Typography>
      }
      bodyID="customAudienceExportBody"
      body={
        <DialogContent style={{ overflow: 'hidden' }}>
          <Grid item xs={12}>
            <FormControl className={classes.selectExport}>
              <div className={classes.selectExportTypeLabel}>
                {SELECT_EXPORT_TYPE}
              </div>
              <Select
                labelId="export-type-label"
                id="export-type"
                value={selectedExportType}
                onChange={(event: SelectChangeEvent) => {
                  clearData();
                  setSelectedExportType(event.target.value as string);
                }}
              >
                {selectOptions.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          {(selectedExportType === 'custom_segment' ||
            selectedExportType === 'ots_segment' ||
            selectedExportType === 'liveramp_custom_segment' ||
            selectedExportType ===
              AUDIENCE_EXPORT_TYPES.LIVERAMP_OTS_SEGMENT) && (
            <div>
              <AudienceExport
                selectedExportType={selectedExportType}
                handleSegmentName={handleSegmentName}
                segmentName={segmentName}
                isSegmentNameValid={isValidSegmentName}
                inValidSegmentNameErrorMessage={inValidSegmentNameErrorMessage}
                handleDescription={handleDescription}
                description={description}
                handleCpm={handleCpm}
                cpm={cpm}
                handlePercentageOfMedia={handlePercentageOfMedia}
                percentageOfMedia={percentageOfMedia}
                handlePartnerID={handlePartnerID}
                partnerID={partnerID}
                advertiserID={advertiserID}
                handleAdvertiserID={handleAdvertiserID}
                platformDestination={platformDestination}
                setPlatformDestination={setPlatformDestination}
                advertiserDestination={advertiserDestination}
                setAdvertiserDestination={setAdvertiserDestination}
                tvTargeting={tvTargeting}
                handleTvTargeting={handleTvTargeting}
                advertiserDirect={advertiserDirect}
                handleAdvertiserDirect={handleAdvertiserDirect}
              />
            </div>
          )}
        </DialogContent>
      }
      footer={
        <DialogActions>
          <Button color="primary" onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Tooltip
            title={isRequiredFieldMissing ? REQUIRED_FIELDS_ARE_MISSING : ''}
            placement="top"
            arrow
          >
            <span>
              <Button
                color="primary"
                disableElevation
                onClick={() => startExport()}
                variant="contained"
                disabled={
                  !selectedExportType ||
                  isExporting ||
                  isRequiredFieldMissing ||
                  !isValidSegmentName
                }
              >
                {isExporting ? <CircularProgress size={20} /> : 'Export'}
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      }
    />
  );
};

export default ExportDialog;
