import {
  AUDIENCE_EXPORT_TYPES,
  ENUM_SYSTEM_USED,
  EXPORT_SEED_AUDIENCES,
  LoadingStates,
} from '../../constants';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import TTDIcon from '../../assets/images/ttd_icon.png';

// Material UI Components
import AudienceExportDialog from 'pages/audienceManager/export/AudienceExportDialog';
import tokens from 'tokens';
import { Button, Grid } from '@klover/attain-design-system';
import { LoadingAudience } from 'pages/audienceManager/editor';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { toggleExportDialog } from 'pages/audienceManager/slice';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    informationText: {
      marginTop: '1rem',
      fontFamily: 'Calibre-Regular,sans-serif',
      fontSize: 16,
    },
    segmentDivider: {
      background: tokens.colorDividerLgBorder,
      height: '1px',
      border: 'none',
    },
    segmentInputFieldsContainer: {
      display: 'flex',
      padding: '1rem 1rem 0rem 1rem',
    },
    segmentInputFieldsLeft: {
      flex: 1,
      borderRight: '1px solid' + tokens.colorDividerLgBorder,
      paddingRight: '1rem',
    },
    segmentInputFieldsRight: {
      flex: 1,
      paddingLeft: '1rem',
    },
    tradeDeskDataSegmentLabel: {
      paddingBottom: '1.5rem',
      fontSize: 18,
      fontWeight: 500,
      fontFamily: 'Calibre-Bold,sans-serif',
    },
    subtitles: {
      paddingTop: 12,
      paddingBottom: '1.5rem',
      fontSize: 20,
      fontWeight: 500,
      fontFamily: 'Calibre-Regular,sans-serif',
      borderTop: '1px solid' + tokens.colorDividerLgBorder,
    },
    OrLabel: {
      fontSize: 18,
      fontWeight: 500,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Calibre-Regular,sans-serif',
    },
    destinations: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 10,
    },
    destinationChip: {
      fontSize: 16,
      height: 28,
      border: '1px solid black',
      backgroundColor: '#ffffff',
      color: 'black',
      fontFamily: 'Calibre-Regular,sans-serif',
      padding: '15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '16px',
      cursor: 'pointer',
      gap: '5px',
      borderColor: 'rgb(89 89 89)',
    },
    destinationSelectedChip: {
      fontSize: 16,
      height: 28,
      border: '1px solid black',
      backgroundColor: 'rgb(225, 244, 234)',
      color: 'black',
      fontFamily: 'Calibre-Regular,sans-serif',
      padding: '15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '16px',
      cursor: 'pointer',
      gap: '5px',
      borderColor: 'rgb(89 89 89)',
    },
    destinationLabel: {
      fontFamily: 'Calibre-Regular,sans-serif',
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    audienceFieldTitle: {
      fontSize: 18,
      fontWeight: 500,
      fontFamily: 'Calibre-Regular,sans-serif',
    },
  })
);

const otsSeedAudienceOptions = [
  { id: 'all', label: 'Toggle All' },
  {
    id: AUDIENCE_EXPORT_TYPES.TTD_OTS_SEGMENT_SEED_AUDIENCE,
    label: 'The Trade Desk',
    icon: TTDIcon,
  },
];

const customSeedAudienceOptions = [
  {
    id: AUDIENCE_EXPORT_TYPES.TTD_CUSTOM_SEGMENT_SEED_AUDIENCE,
    label: 'The Trade Desk Custom',
    icon: TTDIcon,
  },
];

const InSeedAudienceExport = (props: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const openExportDialog = useAppSelector(
    (state) => state.audienceReducer.ui.openExportDialog
  );
  const { uiAudiencesSeedAudienceOtsExportsVisible = false } = useFlags();

  const exportHandler = () => {
    const actionProps: any = {
      open: true,
    };
    dispatch(toggleExportDialog(actionProps));
  };
  const {
    selectedSeedAudienceExportTypes = [],
    handleSelectedSeedAudienceExportType,
    lalModelType,
    audienceType,
  } = props;

  const audienceLoading = useAppSelector(
    (state) => state.audienceReducer.ui.audienceLoading
  );

  /**
   * Handle click on destination chip (either select or deselect)
   */
  const toggleDestinationChip = (chipId: string) => {
    const otsOptionNames = otsSeedAudienceOptions
      .map((x) => x.id)
      .filter((x) => x !== 'all');

    // Check if the "Toggle All" option is selected
    if (chipId === 'all') {
      // Determine if all OTS options are currently selected
      const selectedOTS =
        selectedSeedAudienceExportTypes?.filter((x) =>
          otsOptionNames.includes(x)
        ) || [];
      const isAllOTSSelected = selectedOTS.length === otsOptionNames.length;

      if (isAllOTSSelected) {
        // If all OTS options are selected, deselect all OTS options except 'all'
        const updatedSelection = selectedSeedAudienceExportTypes
          .filter(
            (selectedChip: string) =>
              !otsSeedAudienceOptions.some(
                (option) => option.id === selectedChip
              )
          )
          .filter((selectedChip) => selectedChip); // Ensure there are no empty values

        // Update the selected export types without the OTS options
        handleSelectedSeedAudienceExportType(updatedSelection);
      } else {
        // If not all OTS options are selected, select all OTS options (except 'all' itself)
        // Add all OTS options to the selection (ensure no duplicates)
        handleSelectedSeedAudienceExportType([
          ...new Set([...selectedSeedAudienceExportTypes, ...otsOptionNames]),
        ]);
      }
      return;
    }

    // Handle selecting/unselecting specific destination options
    const isDestinationSelected = selectedSeedAudienceExportTypes.find(
      (selectedChip: string) => selectedChip === chipId
    );

    if (isDestinationSelected) {
      // If the chip is already selected, deselect it by filtering it out
      handleSelectedSeedAudienceExportType(
        selectedSeedAudienceExportTypes.filter(
          (selectedChip: string) => selectedChip !== chipId
        )
      );
    } else {
      // If the chip is not selected, we need to ensure only one custom option is active at a time
      const customOpt = customSeedAudienceOptions.map((x) => x.id);
      const currentlySelectedCustomExport =
        selectedSeedAudienceExportTypes.find((selectedChip) =>
          customOpt.includes(selectedChip)
        );

      let updatedExportTypes = selectedSeedAudienceExportTypes;

      // If a custom export type is already selected, remove it before adding the new one
      if (currentlySelectedCustomExport) {
        updatedExportTypes = updatedExportTypes.filter(
          (selectedChip: string) =>
            selectedChip !== currentlySelectedCustomExport
        );
      }

      // Add the new destination chip to the selected export types
      handleSelectedSeedAudienceExportType([...updatedExportTypes, chipId]);
    }
  };

  /**
   * Retrive className if a destination chip is selected or not
   */
  const getDestinationChipClass = (id: string) => {
    if (id === 'all') {
      const otsOpt = otsSeedAudienceOptions
        .map((x) => x.id)
        .filter((x) => x !== 'all');
      const selectedOTS =
        selectedSeedAudienceExportTypes?.filter((x) => otsOpt.includes(x)) ||
        [];
      const isAllOTSSelected = selectedOTS.length === otsOpt.length;

      return isAllOTSSelected
        ? classes.destinationSelectedChip
        : classes.destinationChip;
    }
    return selectedSeedAudienceExportTypes.includes(id)
      ? classes.destinationSelectedChip
      : classes.destinationChip;
  };

  const isAudienceLoading = audienceLoading?.state === LoadingStates.LOADING;
  if (isAudienceLoading)
    return (
      <>
        <LoadingAudience />
        <LoadingAudience />
      </>
    );

  return (
    <>
      <Grid container spacing={0} display="flex">
        <Grid item xs={12} className={classes.tradeDeskDataSegmentLabel}>
          {EXPORT_SEED_AUDIENCES}
        </Grid>
        {audienceType === ENUM_SYSTEM_USED.STANDARD_AUDIENCE && (
          <Grid item xs={12}>
            <Button
              color="primary"
              disableElevation
              variant="contained"
              style={{ marginBottom: 15 }}
              onClick={exportHandler}
            >
              Download Klover IDs
            </Button>
          </Grid>
        )}
        <Grid container spacing={0}>
          {uiAudiencesSeedAudienceOtsExportsVisible && (
            <Grid item xs={12} className={classes.destinations}>
              <div className={classes.destinationLabel}>
                <b>OTS:</b>
              </div>
              {otsSeedAudienceOptions.map((x) => (
                <div
                  key={x.id}
                  onClick={() => toggleDestinationChip(x.id)}
                  className={getDestinationChipClass(x.id)}
                >
                  {x.icon && (
                    <img
                      src={x.icon}
                      alt="ttd-icon"
                      style={{ height: 15, width: 15 }}
                    />
                  )}
                  <span>{x.label}</span>
                </div>
              ))}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            className={classes.destinations}
            style={{
              marginTop: uiAudiencesSeedAudienceOtsExportsVisible ? 25 : 0,
            }}
          >
            <div className={classes.destinationLabel}>
              <b>Custom:</b>
            </div>
            {customSeedAudienceOptions.map((x) => (
              <div
                key={x.id}
                onClick={() => toggleDestinationChip(x.id)}
                className={getDestinationChipClass(x.id)}
              >
                <img
                  src={x.icon}
                  alt="liveramp-icon"
                  style={{ height: 15, width: 15 }}
                  className=""
                />

                <span>{x.label}</span>
              </div>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <AudienceExportDialog
        open={openExportDialog}
        lalModelType={lalModelType}
        defaultExportTypeSelected="klover_ids"
      />
    </>
  );
};

export default InSeedAudienceExport;
