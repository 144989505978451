import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { API_BASE_ADDRESS } from '../variables';
import {
  Dimension,
  addNotification,
  clearCurrentAudience,
  fetchAudience,
  loadOriginalAudience,
  setModeled,
  setUIState,
  toggleDraftStateDialog,
} from '../slice';
import { Grid, IconButton, theme } from '@klover/attain-design-system';
import { buildAudienceObject } from '../sagas/audiences';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useUser } from 'reactfire';
import * as Styled from './index.styles';
// Material UI Components
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import GhostLoader from 'react-ghost-loader';
import Typography from '@material-ui/core/Typography';
import { ArrowLeft } from '@phosphor-icons/react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

// Page Components
import AddDimensionCategory from '../addDimensionCategory';
import AudienceDeleteDialog from '../audienceDeleteDialog';
import AudienceName from '../audienceName';
import DimensionContainer from '../dimension';
import DimensionDialog from '../dimensionDialog';
import DraftStateDialog from '../draftStateDialog';
import DraftStateSvg from '../draftStateIndicator';
import DuplicateAudienceDialog from '../duplicateAudienceDialog';
import TotalAudienceSize from '../totalAudienceSize';
import compareAudiences from 'utils/compareAudiences';

import AudienceExportDialog from '../export/AudienceExportDialog';
import ExportYourAudience from '../../../components/AudienceExport/ExportYourAudience';
import InHouseModelStepper from '../../../components/AudienceExport/InHouseModelStepper.tsx';
import ModelAudienceButton from '../../../components/AudienceExport/ModelAudienceButton.tsx';
import UnsavedChangesPrompt from 'components/AudienceExport/UnsavedChangesPrompt';
import tokens from 'tokens';
import {
  AUDIENCE_LAL_MODAL_TYPE,
  ENUM_SYSTEM_USED,
  LoadingStates,
} from '../../../constants';
import {
  EST_AUDIENCE_SIZE_MODELED_VALIDATION_MESSAGE,
  EST_AUDIENCE_SIZE_MODELED_WARNING_MESSAGE,
  YOUR_AUDIENCE_FAILED_TO_GET_MODELED,
  YOUR_AUDIENCE_IS_BEING_MODELED,
} from '../../../content';
import { groupModeledAudienceCountByIdType } from '../../../helper/helper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    draftIndicatorAndTotalAudience: {
      display: 'flex',
    },
    draftIndicator: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: 10,
    },
    noAudiences: {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 12,
      boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.07)',
      zIndex: 1,
      background: 'white',
    },
    dimensions: {
      margin: theme.spacing(2),
    },
    noDimensions: {
      minHeight: '70vh',
    },
    modeledButtonContainer: {
      position: 'fixed',
      right: 0,
      top: 0,
      paddingTop: '0.3em',
      paddingRight: '1.2em',
    },
    countButton: {
      cursor: 'pointer',
      height: 16,
      fontSize: 10,
      fontWeight: 'bold',
      border: '1px solid ' + tokens.colorButtonPrimary,
      borderRight: 'none',
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      borderWidth: 'thin',
      outline: 'none',
      backgroundColor: tokens.colorButtonDefault,
      color: tokens.colorButtonPrimary,
      opacity: 1,
      '&:hover': {
        opacity: 0.8,
      },
    },
    modeledButton: {
      cursor: 'pointer',
      height: 16,
      fontSize: 10,
      fontWeight: 'bold',
      border: '1px solid ' + tokens.colorButtonPrimary,
      borderLeft: 'none',
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      borderWidth: 'thin',
      outline: 'none',
      borderColor: tokens.colorButtonPrimary,
      backgroundColor: tokens.colorButtonDefault,
      color: tokens.colorButtonPrimary,
      opacity: 1,
      '&:hover': {
        opacity: 0.8,
      },
    },
    selectedButton: {
      backgroundColor: tokens.colorButtonPrimary,
      color: tokens.colorButtonDefault,
    },
  })
);

const AudienceManager = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { audienceid } = useParams();
  const [isModeling, setIsModeling] = useState(false);
  const { data: user } = useUser();
  const currentUser = user || { email: '' };

  const [openDialog, setOpenDialog] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [openUnsavedStateDialog, setOpenUnsavedStateDialog] = useState(false);
  const [lalModelPrecision, setLalModelPrecision] = useState(1);
  const [isModelingSkipped, setIsModelingSkipped] = useState(true);

  const errorFetching = useAppSelector(
    (state) => state.audienceReducer.ui.errorFetching
  );

  const audienceLoading = useAppSelector(
    (state) => state.audienceReducer.ui.audienceLoading
  );

  const dimensions = useAppSelector((state) => {
    const audience = state.audienceReducer.currentAudience;
    if (audience?.dimensions) {
      return audience.dimensions.map((id) => {
        return state.audienceReducer.currentDimensions.byId[id];
      });
    }

    return [];
  });

  const openDimensionDialog = useAppSelector(
    (state) => state.audienceReducer.ui.openDimensionDialog
  );
  const originalAudience = useAppSelector(
    (state) => state.audienceReducer.originalAudience
  );

  const openExportDialog = useAppSelector(
    (state) => state.audienceReducer.ui.openExportDialog
  );

  const openDuplicateAudienceDialog = useAppSelector(
    (state) => state.audienceReducer.ui.openDuplicateAudienceDialog
  );

  const openDraftStateDialog = useAppSelector(
    (state) => state.audienceReducer.ui.openDraftStateDialog
  );

  const currentAudience = useAppSelector(
    (state) => state.audienceReducer.currentAudience
  );

  const currentConditions = useAppSelector((state) => {
    return state.audienceReducer.currentConditions;
  });

  const currentDimensions = useAppSelector((state) => {
    return state.audienceReducer.currentDimensions;
  });

  const currentDimensionGroups = useAppSelector((state) => {
    return state.audienceReducer.currentDimensionGroups;
  });

  const currentGroup = useAppSelector((state) => {
    return state.audienceReducer.currentGroup;
  });

  const modeled = useAppSelector((state) => {
    return state.audienceReducer.ui.modeled;
  });

  const lalModelType = currentAudience?.lalModelType;
  const isAudienceLoading = audienceLoading?.state === 'loading';
  const lalModelStatus = currentAudience?.lalModelStatus;
  const lalModelCompletedAt = currentAudience?.lalModelCompletedAt;

  useEffect(() => {
    dispatch(setModeled(false));
    dispatch(setUIState({ value: false, uiPropertyName: 'errorFetching' }));
    if (audienceid) {
      dispatch(
        fetchAudience({
          id: audienceid,
        })
      );
    } else if (!audienceid && currentGroup) {
      dispatch(loadOriginalAudience(null));
    } else {
      history.push('/main/audience-manager');
    }
    return () => {
      dispatch(setModeled(false));
      dispatch(
        setUIState({
          value: false,
          uiPropertyName: 'errorFetching',
        })
      );
    };
  }, []);

  const updatedAudience = {
    ...currentAudience,
    updatedAt: Date.now(),
    groupId: originalAudience?.group_id,
  };

  const audienceObject = buildAudienceObject({
    updatedAudience,
    currentDimensions,
    currentDimensionGroups,
    currentConditions,
  });

  const isUnchanged = compareAudiences(originalAudience, audienceObject);

  const handleClearAudience = (e) => {
    if (originalAudience) {
      if (isUnchanged) {
        dispatch(clearCurrentAudience());
      } else {
        e.preventDefault();
        dispatch(
          toggleDraftStateDialog({ open: true, path: '/main/audience-manager' })
        );
      }
    } else {
      dispatch(clearCurrentAudience());
    }

    history.push(`/main/audience-manager?groupId=${currentGroup?.id || 'all'}`);
  };

  const modelYourAudience = async () => {
    const totalAudienceSize = currentAudience?.totalSize || 0;
    if (totalAudienceSize <= 300) {
      dispatch(
        addNotification({
          state: LoadingStates.ERROR,
          message: EST_AUDIENCE_SIZE_MODELED_VALIDATION_MESSAGE,
        })
      );
      return;
    }
    if (totalAudienceSize > 300 && totalAudienceSize < 2000) {
      dispatch(
        addNotification({
          state: LoadingStates.WARNING,
          message: EST_AUDIENCE_SIZE_MODELED_WARNING_MESSAGE,
        })
      );
    }
    const payload = [
      {
        id: currentAudience.id,
        name: currentAudience.name,
        emails: currentUser.email,
        exportType: 'in_house_lal_model',
        lalModelType: 'attain',
      },
    ];
    setIsModeling(true);
    axios
      .post(`${API_BASE_ADDRESS}/audience-manager/export/transunion`, payload)
      .then(() => {
        dispatch(
          addNotification({
            state: 'done',
            message: YOUR_AUDIENCE_IS_BEING_MODELED,
          })
        );
      })
      .catch(() => {
        setIsModeling(false);
        dispatch(
          addNotification({
            state: 'error',
            message: YOUR_AUDIENCE_FAILED_TO_GET_MODELED,
          })
        );
      });
  };

  const audienceCountTotals = useMemo(() => {
    const totals = {};
    const groupedAudienceCount = groupModeledAudienceCountByIdType(
      currentAudience?.lalAudienceCount
    );
    Object.values(groupedAudienceCount).forEach((idTypeArray: any) => {
      idTypeArray.forEach(({ percentileBreakpoint, audienceCount }) => {
        if (!totals[percentileBreakpoint]) {
          totals[percentileBreakpoint] = 0;
        }
        totals[percentileBreakpoint] += audienceCount;
      });
    });
    return totals;
  }, [currentAudience?.lalAudienceCount]);

  return (
    <>
      {!errorFetching && (
        <>
          <Grid item container className={classes.root}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconButton
                color="default"
                disabled={isAudienceLoading}
                onClick={handleClearAudience}
              >
                <ArrowLeft size={theme.icons.lg} />
              </IconButton>
              <div style={{ marginLeft: 8 }} />
              <AudienceName
                isAudienceLoading={isAudienceLoading}
                audienceid={audienceid}
              />
            </div>
            <Grid className={classes.draftIndicatorAndTotalAudience}>
              {!isAudienceLoading && !isUnchanged ? (
                <div className={classes.draftIndicator}>
                  <DraftStateSvg />
                </div>
              ) : null}
              <TotalAudienceSize
                lalModelType={lalModelType}
                lalModelStatus={lalModelStatus}
                isModeling={isModeling}
                lalModelPrecision={lalModelPrecision}
                audienceCountTotals={audienceCountTotals}
              />
            </Grid>
          </Grid>
          {/* Top Divider */}
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          {activeStep === 0 && (
            <>
              {/* Add Dimension Category */}
              <AddDimensionCategory isAudienceLoading={isAudienceLoading} />

              {/* Dimension Categories */}
              {isAudienceLoading ? (
                <Box className={classes.noDimensions}>
                  <LoadingAudience />
                </Box>
              ) : (
                <React.Fragment>
                  <Box
                    className={classes.dimensions}
                    style={{
                      marginBottom:
                        lalModelType === AUDIENCE_LAL_MODAL_TYPE.ATTAIN
                          ? '6rem'
                          : '16px',
                    }}
                  >
                    {dimensions.length > 0 ? (
                      <DimensionContainers dimensions={dimensions} />
                    ) : (
                      <NoDimensions />
                    )}
                  </Box>

                  <DimensionDialog
                    open={openDimensionDialog}
                    isUnchanged={isUnchanged}
                  />
                  <AudienceExportDialog
                    open={openExportDialog}
                    lalModelType={lalModelType}
                  />
                  <DuplicateAudienceDialog open={openDuplicateAudienceDialog} />
                </React.Fragment>
              )}
            </>
          )}

          <DraftStateDialog openDraftStateDialog={true} />

          <AudienceDeleteDialog
            isOpen={openDialog}
            setOpenDialog={setOpenDialog}
          />
          {activeStep === 1 && (
            <ModelAudienceButton
              modelYourAudience={modelYourAudience}
              isModeling={isModeling}
              lalModelStatus={lalModelStatus}
              lalModelCompletedAt={lalModelCompletedAt}
              isModelingSkipped={isModelingSkipped}
              setIsModelingSkipped={setIsModelingSkipped}
            />
          )}
          {activeStep === 2 && (
            <ExportYourAudience
              currentAudience={currentAudience}
              lalModelPrecision={lalModelPrecision}
              setLalModelPrecision={setLalModelPrecision}
              audienceCountTotals={audienceCountTotals}
              audienceType={ENUM_SYSTEM_USED.STANDARD_AUDIENCE}
              isModelingSkipped={isModelingSkipped}
            />
          )}
          {lalModelType === 'attain' && (
            <InHouseModelStepper
              dimensionCount={dimensions.length}
              activeStep={activeStep}
              setActiveStep={(step) => {
                if (step === 1 && !isUnchanged) {
                  setOpenUnsavedStateDialog(true);
                } else {
                  setActiveStep(step);
                }
              }}
              lalModelStatus={lalModelStatus}
              audienceName={currentAudience.name}
              audienceType={ENUM_SYSTEM_USED.STANDARD_AUDIENCE}
              isModelingSkipped={isModelingSkipped}
            />
          )}
          <UnsavedChangesPrompt
            openUnsavedStateDialog={openUnsavedStateDialog}
            setOpenUnsavedStateDialog={setOpenUnsavedStateDialog}
            setActiveStep={setActiveStep}
          />
        </>
      )}
      {errorFetching && (
        <Styled.AudienceContainer>
          <Styled.AudienceUnknown>
            <Styled.AudienceUnknownText>
              404 Error: This page does not exist.
            </Styled.AudienceUnknownText>
          </Styled.AudienceUnknown>
        </Styled.AudienceContainer>
      )}
    </>
  );
};

interface Props {
  dimensions: Dimension[];
}

const DimensionContainers = ({ dimensions }: Props) => {
  return (
    <>
      <Grid container spacing={2}>
        {dimensions.map((dimension) => (
          <DimensionContainer key={dimension.id} dimension={dimension} />
        ))}
      </Grid>
    </>
  );
};

const NoDimensions = () => {
  const classes = useStyles();

  return (
    <Box className={classes.noDimensions}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <FilterNoneIcon />
        </Grid>
        <Grid item>
          <Typography component="h3" variant="h6">
            This audience hasn&apos;t been filtered by any conditions yet.
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h4" variant="caption">
            Start by adding a dimension category.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export const LoadingAudience = () => {
  return (
    <Styled.Wrapper>
      <Styled.CardBody>
        <GhostLoader
          height="42px"
          heightRandom={0}
          width="42px"
          widthRandom={0}
        />

        <GhostLoader
          height="42px"
          heightRandom={0}
          width="100%"
          widthRandom={0}
        />
      </Styled.CardBody>
      <Styled.CardBody>
        <GhostLoader
          height="70px"
          heightRandom={0}
          width="10%"
          widthRandom={0}
        />
        <GhostLoader
          height="70px"
          heightRandom={0}
          width="80%"
          widthRandom={0}
        />
        <GhostLoader
          height="70px"
          heightRandom={0}
          width="10%"
          widthRandom={0}
        />
      </Styled.CardBody>
      <Styled.CardBody>
        <GhostLoader
          height="42px"
          heightRandom={0}
          width="10%"
          widthRandom={0}
        />
        <GhostLoader
          height="42px"
          heightRandom={0}
          width="10%"
          widthRandom={0}
        />
        <GhostLoader
          height="42px"
          heightRandom={0}
          width="70%"
          widthRandom={0}
        />
        <GhostLoader
          height="42px"
          heightRandom={0}
          width="10%"
          widthRandom={0}
        />
      </Styled.CardBody>
    </Styled.Wrapper>
  );
};

export default AudienceManager;
