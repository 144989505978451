export const PlacementId = {
  '1': 'offerWall',
  '2': 'dashboard',
  '3': 'kloverPlus',
  '4': 'onBoarding',
};

export const PlacementLabelId = {
  '1': 'Offer Wall',
  '2': 'Dashboard',
  '3': 'Klover Plus',
  '4': 'On Boarding',
};
