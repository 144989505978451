import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ErrorIcon from '@mui/icons-material/Error';
import convertTimeStampToDateTime from 'utils/dateTimestampConversion';
import {
  AUDIENCE_EXPORT_TYPES,
  AUDIENCE_LAL_MODAL_TYPE,
  AudienceAllExports,
  AudienceDefaultExports,
  UploadStates,
} from 'constants/index';
import { CircularProgress } from '@material-ui/core';
import {
  ERROR_PROCESSING_FILE,
  IMPORTING_FILE,
  SUCCESS_FILE,
} from '../../../content/index';

/**
 * Enum for types mapping to their string representations
 */
export const ENUM_EXPORT_TYPE: { [key: string]: string } = {
  transunion: 'TransUnion',
  transunion_hems: 'TransUnion HEMs Only',
  custom_segment: 'TTD Custom Segment',
  ots_segment: 'TTD OTS Segment',
  liveramp_custom_segment: 'LiveRamp Custom Segment',
  liveramp_ots_segment: 'LiveRamp OTS Segment',
  experian_ots_segment: 'Experian OTS Segment',
  ttd_data_alliance: 'Trade Desk Data Alliance',
  klover_ids: 'Klover IDs',
  pubmatic_ots_segment: 'Pubmatic OTS Segment',
  pubmatic_custom_segment: 'Pubmatic Custom Segment',
  openx_ots_segment: 'OpenX OTS Segment',
  openx_custom_segment: 'OpenX Custom Segment',
  ttd_ots_segment_seed_audience: 'TTD OTS Segment (Seed)',
  ttd_custom_segment_seed_audience: 'TTD Custom Segment (Seed)',
  meta_ots_segment: 'Meta OTS',
  meta_custom_segment: 'Meta Custom',
  yahoo_dsp_ots_segment: 'Yahoo DSP OTS',
  yahoo_dsp_custom_segment: 'Yahoo DSP Custom',
  x_ots_segment: 'X OTS',
  x_custom_segment: 'X Custom',
  magnite_ots_segment: 'Magnite OTS',
  magnite_custom_segment: 'Magnite Custom',
};

/**
 * Enum for status mapping to their string representations
 */
export const ENUM_EXPORT_STATUS: { [key: string]: string } = {
  export_started: 'export started',
  sent_to_transunion: 'Audience sent to TransUnion',
  received_from_transunion: 'Lookalike received from TransUnion',
  created_platform: 'Created the Trade Desk segment',
};

/**
 * Enum status for audience export status
 */
export const ENUM_AUDIENCE_EXPORT_STATUS: { [key: string]: string } = {
  NOT_SENT: 'Not sent',
  SENT: 'Sent',
  MODELING_COMPLETED: 'Modeling Complete',
  LATE: 'Late',
  ERROR: 'Error',
  LIVE_IN_TTD: 'Live In TTD',
};

/**
 * Enum status for audience type status
 */
const ENUM_AUDIENCE_TYPE_STATUS: { [key: string]: string } = {
  PENDING: 'Pending',
  OTS: 'OTS Audience',
  CUSTOM_AUDIENCE: 'Custom Audience',
  KLOVER_ID: 'Klover IDs',
  DATA_ALLIANCE: 'TTD Data Alliance',
  OTS_SEED: 'OTS Audience (Seed)',
  CUSTOM_SEED_AUDIENCE: 'Custom Audience (Seed)',
};

/**
 * Enum status for platform type status
 */
const ENUM_PLATFORM_TYPE_STATUS: { [key: string]: string } = {
  OTS_PFT: 'Trade Desk + LiveRamp',
  CUSTOM_PFT: 'Trade Desk',
  TRANSUNION_PFT: 'LiveRamp',
  KLOVER_ID_PFT: '-',
  PUBMATIC: 'Pubmatic',
  OPENX: 'OpenX',
  DATA_ALLIANCE_PFT: 'Trade Desk',
  LIVERAMP_CUSTOM_PFT: 'LiveRamp Custom',
  LIVERAMP_OTS_PFT: 'LiveRamp OTS',
  EXPERIAN_OTS_PFT: 'Experian OTS',
  X_OTS_PFT: 'X OTS',
  META_OTS_PFT: 'Meta OTS',
  MAGNITE_OTS_PFT: 'Magnite OTS',
  YAHOO_DSP_OTS_PFT: 'Yahoo DSP OTS',
  X_CUSTOM_PFT: 'X Custom',
  META_CUSTOM_PFT: 'Meta Custom',
  MAGNITE_CUSTOM_PFT: 'Magnite Custom',
  YAHOO_DSP_CUSTOM_PFT: 'Yahoo DSP Custom',
  OTS_SEED_PFT: 'Trade Desk + LiveRamp',
  CUSTOM_SEED_PFT: 'Trade Desk',
};

/**
 * Export status column mapping with data key & datatype
 */

export const getStatusDetails = (exportType: string, lalModelType: string) => {
  const segmentStartedDetail = {
    label: ENUM_EXPORT_STATUS['export_started'],
    key: 'timestampStatusExportStarted',
    type: 'datetime',
  };

  if (
    lalModelType === AUDIENCE_LAL_MODAL_TYPE.ATTAIN ||
    !AudienceDefaultExports.includes(exportType)
  ) {
    return [segmentStartedDetail];
  }

  const statusDetails = [
    segmentStartedDetail,
    {
      label: ENUM_EXPORT_STATUS['sent_to_transunion'],
      key: 'timestampStatusSentToTransunion',
      type: 'datetime',
    },
    {
      label: ENUM_EXPORT_STATUS['received_from_transunion'],
      key: 'timestampStatusReceivedFromTransunion',
      type: 'datetime',
    },
  ];

  if (exportType !== AUDIENCE_EXPORT_TYPES.TRANSUNION) {
    statusDetails.push({
      label: ENUM_EXPORT_STATUS['created_platform'],
      key: 'timestampStatusPlatformSegmentCreated',
      type: 'datetime',
    });
  }

  return statusDetails;
};

/**
 * Segment Details column mapping with datakey & datatype
 */
export const segmentDetails = [
  { label: 'Segment Name', key: 'segmentName' },
  { label: 'Description', key: 'description' },
  { label: 'CPM', key: 'cpm' },
  { label: '% of media', key: 'percentOfMedia' },
  { label: 'Partner ID', key: 'publisherId' },
  { label: 'Advertiser ID', key: 'advertiserId' },
];

/**
 * Segment Details column mapping with datakey & datatype for LiveRamp Custom Segment
 */
export const liveRampSegmentDetails = [
  { label: 'Segment Name', key: 'segmentName' },
  { label: 'Description', key: 'description' },
  { label: 'CPM', key: 'cpm' },
  { label: 'Programatic % of media', key: 'percentOfMedia' },
  { label: 'TV Targeting', key: 'tvCpm' },
  { label: 'Advertiser Direct', key: 'advertiserDirect' },
  { label: 'Integration', key: 'platformDestination' },
  { label: 'Advertiser', key: 'advertiserDestination' },
];

/**
 * Segment Details column mapping with datakey & datatype for LiveRamp OTS Segment
 */
export const liveRampOTSSegmentDetails = [
  { label: 'Segment Name', key: 'segmentName' },
  { label: 'Description', key: 'description' },
  { label: 'CPM', key: 'cpm' },
  { label: 'Programatic % of media', key: 'percentOfMedia' },
  { label: 'TV Targeting', key: 'tvCpm' },
  { label: 'Advertiser Direct', key: 'advertiserDirect' },
];

/**
 * Segment Details column mapping with datakey & datatype for Experian OTS Segment
 */
export const experianOTSSegmentDetails = [
  { label: 'Segment Name', key: 'segmentName' },
  { label: 'Description', key: 'description' },
  { label: 'CPM', key: 'cpm' },
  { label: '% of media', key: 'percentOfMedia' },
];

/**
 * OpenX segment Details column mapping with datakey & datatype
 */
export const openxSegmentDetails = [
  { label: 'Segment Name', key: 'segmentName' },
];

/**
 * Pubmatic segment Details column mapping with datakey & datatype
 */
export const pubmaticSegmentDetails = [
  { label: 'Segment Name', key: 'segmentName' },
  { label: 'Description', key: 'description' },
  { label: 'CPM', key: 'cpm' },
];

/**
 * Klover IDs segment Details column mapping with datakey & datatype
 */
export const kloverIdsSegmentDetails = [
  { label: "Receiver's Email", key: 'receiverEmail' },
];

/**
 * Help to get latest status value of current export
 * @param exportItem : Export object
 * @returns : string
 */
export const getExportLatestStatus = (
  exportItem: any,
  isInHouseExport = false
) => {
  const {
    statusExportStarted = false,
    statusSentToTransunion = false,
    statusReceivedFromTransunion = false,
    statusPlatformSegmentCreated = false,
    exportType,
  } = exportItem || {};

  if (isInHouseExport || !AudienceDefaultExports.includes(exportType))
    return `${ENUM_EXPORT_TYPE[exportType]} ${ENUM_EXPORT_STATUS['export_started']}`;
  if (statusPlatformSegmentCreated)
    return ENUM_EXPORT_STATUS['created_platform'];
  if (statusReceivedFromTransunion)
    return ENUM_EXPORT_STATUS['received_from_transunion'];
  if (statusSentToTransunion) return ENUM_EXPORT_STATUS['sent_to_transunion'];
  if (statusExportStarted)
    return `${ENUM_EXPORT_TYPE[exportType]} ${ENUM_EXPORT_STATUS['export_started']}`;
  return '';
};

/**
 * Convert current timestamp to specific 'MM/DD/YYYY HH:mm A' format along with split (date-time) functionality
 * @param timestamp : number
 * @param isSplitTime : boolean
 * @returns : render object
 */
export const convertToDateTime = (timestamp: number, isSplitTime = false) => {
  if (!timestamp) return '';
  const dateTime = convertTimeStampToDateTime(timestamp, 'MM/DD/YYYY HH:mm A');
  if (dateTime) {
    if (!isSplitTime) return dateTime;
    const splitedTime = dateTime.split(' ');
    return (
      <>
        <span>{splitedTime[0]}</span>
        <br />
        <span>{`${splitedTime[1]} ${splitedTime[2]}`}</span>
      </>
    );
  }

  return '';
};

/**
 * Convert all props to valid format
 * @param arrayOfObjects : array objects
 * @returns new array with valid props
 */
export const modifyProps = (arrayOfObjects: any[]): any[] => {
  return arrayOfObjects.map((obj: any) => {
    const newObj: any = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (!key.includes('_')) {
          newObj[key] = obj[key];
        } else {
          const propParts: string[] = key.split('_');
          const propName: string = propParts
            .map((part: string, index: number) => {
              if (index !== 0) {
                return part.charAt(0).toUpperCase() + part.slice(1);
              }
              return part;
            })
            .join('');
          newObj[propName] = obj[key];
        }
      }
    }
    return newObj;
  });
};

/**
 * Return file uploading status Icon and Status for Custom Audience
 * @param status : uploaded file status
 * @returns status and statusIcon
 */
export const getFileUploadingStatus = (status: string) => {
  const { DONE, LOADING, ERROR } = UploadStates;
  switch (status) {
    case LOADING:
      return {
        status: IMPORTING_FILE,
        statusIcon: <CircularProgress size={24} />,
      };
    case ERROR:
      return {
        status: ERROR_PROCESSING_FILE,
        statusIcon: <ErrorIcon style={{ color: '#ff2c2c' }} />,
      };
    case DONE:
      return {
        status: SUCCESS_FILE,
        statusIcon: (
          <CloudDoneIcon
            style={{ color: '#413762', marginLeft: '16px', fontSize: '26px' }}
          />
        ),
      };
    default:
      return { status: '', statusIcon: '' };
  }
};

/**
 * Help to retrive audience_type & platform_type from export history
 * @param exportHistory : array of export history
 * @returns object with audience_type & platform_type
 */
export const getAudienceAndPlatformTypeStatus = (
  exportHistory: any[],
  isInlineExport = false
) => {
  const {
    PENDING,
    OTS,
    CUSTOM_AUDIENCE,
    KLOVER_ID,
    DATA_ALLIANCE,
    OTS_SEED,
    CUSTOM_SEED_AUDIENCE,
  } = ENUM_AUDIENCE_TYPE_STATUS;
  const {
    OTS_PFT,
    CUSTOM_PFT,
    TRANSUNION_PFT,
    KLOVER_ID_PFT,
    PUBMATIC,
    OPENX,
    DATA_ALLIANCE_PFT,
    LIVERAMP_CUSTOM_PFT,
    LIVERAMP_OTS_PFT,
    EXPERIAN_OTS_PFT,
    X_OTS_PFT,
    META_OTS_PFT,
    MAGNITE_OTS_PFT,
    YAHOO_DSP_OTS_PFT,
    X_CUSTOM_PFT,
    META_CUSTOM_PFT,
    MAGNITE_CUSTOM_PFT,
    YAHOO_DSP_CUSTOM_PFT,
    OTS_SEED_PFT,
    CUSTOM_SEED_PFT,
  } = ENUM_PLATFORM_TYPE_STATUS;

  if (exportHistory.length === 0)
    return {
      audience_type: PENDING,
      platform_type: '',
    };

  const allowedExports = isInlineExport
    ? AudienceAllExports
    : AudienceDefaultExports;
  const { exportType } =
    exportHistory?.find((x) => allowedExports.includes(x.exportType)) || {};

  switch (exportType) {
    case AUDIENCE_EXPORT_TYPES.KLOVER_IDS:
      return {
        audience_type: KLOVER_ID,
        platform_type: KLOVER_ID_PFT,
      };
    case AUDIENCE_EXPORT_TYPES.OTS_SEGMENT:
      return {
        audience_type: OTS,
        platform_type: OTS_PFT,
      };
    case AUDIENCE_EXPORT_TYPES.TRANSUNION:
      return {
        audience_type: CUSTOM_AUDIENCE,
        platform_type: TRANSUNION_PFT,
      };
    case AUDIENCE_EXPORT_TYPES.TTD_DATA_ALLIANCE:
      return {
        audience_type: DATA_ALLIANCE,
        platform_type: DATA_ALLIANCE_PFT,
      };
    case AUDIENCE_EXPORT_TYPES.CUSTOM_SEGMENT:
      return {
        audience_type: CUSTOM_AUDIENCE,
        platform_type: CUSTOM_PFT,
      };
    case AUDIENCE_EXPORT_TYPES.LIVERAMP_CUSTOM_SEGMENT:
      return {
        audience_type: CUSTOM_AUDIENCE,
        platform_type: LIVERAMP_CUSTOM_PFT,
      };
    case AUDIENCE_EXPORT_TYPES.LIVERAMP_OTS_SEGMENT:
      return {
        audience_type: OTS,
        platform_type: LIVERAMP_OTS_PFT,
      };
    case AUDIENCE_EXPORT_TYPES.OPENX_OTS_SEGMENT:
      return {
        audience_type: OTS,
        platform_type: OPENX,
      };
    case AUDIENCE_EXPORT_TYPES.OPENX_CUSTOM_SEGMENT:
      return {
        audience_type: CUSTOM_AUDIENCE,
        platform_type: OPENX,
      };
    case AUDIENCE_EXPORT_TYPES.PUBMATIC_OTS_SEGMENT:
      return {
        audience_type: OTS,
        platform_type: PUBMATIC,
      };
    case AUDIENCE_EXPORT_TYPES.PUBMATIC_CUSTOM_SEGMENT:
      return {
        audience_type: CUSTOM_AUDIENCE,
        platform_type: PUBMATIC,
      };
    case AUDIENCE_EXPORT_TYPES.EXPERIAN_OTS_SEGMENT:
      return {
        audience_type: OTS,
        platform_type: EXPERIAN_OTS_PFT,
      };
    case AUDIENCE_EXPORT_TYPES.X_OTS_SEGMENT:
      return {
        audience_type: OTS,
        platform_type: X_OTS_PFT,
      };
    case AUDIENCE_EXPORT_TYPES.X_CUSTOM_SEGMENT:
      return {
        audience_type: CUSTOM_AUDIENCE,
        platform_type: X_CUSTOM_PFT,
      };
    case AUDIENCE_EXPORT_TYPES.META_OTS_SEGMENT:
      return {
        audience_type: OTS,
        platform_type: META_OTS_PFT,
      };
    case AUDIENCE_EXPORT_TYPES.META_CUSTOM_SEGMENT:
      return {
        audience_type: CUSTOM_AUDIENCE,
        platform_type: META_CUSTOM_PFT,
      };
    case AUDIENCE_EXPORT_TYPES.MAGNITE_OTS_SEGMENT:
      return {
        audience_type: OTS,
        platform_type: MAGNITE_OTS_PFT,
      };
    case AUDIENCE_EXPORT_TYPES.MAGNITE_CUSTOM_SEGMENT:
      return {
        audience_type: CUSTOM_AUDIENCE,
        platform_type: MAGNITE_CUSTOM_PFT,
      };
    case AUDIENCE_EXPORT_TYPES.YAHOO_DSP_OTS_SEGMENT:
      return {
        audience_type: OTS,
        platform_type: YAHOO_DSP_OTS_PFT,
      };
    case AUDIENCE_EXPORT_TYPES.YAHOO_DSP_CUSTOM_SEGMENT:
      return {
        audience_type: CUSTOM_AUDIENCE,
        platform_type: YAHOO_DSP_CUSTOM_PFT,
      };
    case AUDIENCE_EXPORT_TYPES.TTD_OTS_SEGMENT_SEED_AUDIENCE:
      return {
        audience_type: OTS_SEED,
        platform_type: OTS_SEED_PFT,
      };
    case AUDIENCE_EXPORT_TYPES.TTD_CUSTOM_SEGMENT_SEED_AUDIENCE:
      return {
        audience_type: CUSTOM_SEED_AUDIENCE,
        platform_type: CUSTOM_SEED_PFT,
      };
    default:
      return {};
  }
};

/**
 * Get list of segment details
 */
export const getSegmentDetails = (exportType: string) => {
  switch (exportType) {
    case AUDIENCE_EXPORT_TYPES.KLOVER_IDS:
      return kloverIdsSegmentDetails;
    case AUDIENCE_EXPORT_TYPES.LIVERAMP_CUSTOM_SEGMENT:
      return liveRampSegmentDetails;
    case AUDIENCE_EXPORT_TYPES.LIVERAMP_OTS_SEGMENT:
      return liveRampOTSSegmentDetails;
    case AUDIENCE_EXPORT_TYPES.OTS_SEGMENT:
    case AUDIENCE_EXPORT_TYPES.TTD_DATA_ALLIANCE:
      return segmentDetails.filter(
        (x) => !['publisherId', 'advertiserId'].includes(x.key)
      );
    case AUDIENCE_EXPORT_TYPES.OPENX_OTS_SEGMENT:
    case AUDIENCE_EXPORT_TYPES.OPENX_CUSTOM_SEGMENT:
      return openxSegmentDetails;
    case AUDIENCE_EXPORT_TYPES.PUBMATIC_OTS_SEGMENT:
    case AUDIENCE_EXPORT_TYPES.PUBMATIC_CUSTOM_SEGMENT:
      return pubmaticSegmentDetails;
    case AUDIENCE_EXPORT_TYPES.EXPERIAN_OTS_SEGMENT:
      return experianOTSSegmentDetails;
    default:
      return segmentDetails;
  }
};
