import Field from 'components/Field';
import FileInput from 'components/FileInput';
import React, { useState } from 'react';
import getAuthorizedAxiosInstance from 'utils/getAuthorizedAxiosInstance';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import {
  AUDIENCE_NAME_LENGTH_VALIDATION_MESSAGE,
  IN_HOUSE_LABEL,
  TRANSUNION_LABEL,
} from '../../../content';
import {
  Alert,
  Button,
  Dialog,
  Grid,
  IconButton,
  LinearProgress,
  theme,
} from '@klover/attain-design-system';
import { AxiosResponse } from 'axios';
import { CircularProgress } from '@mui/material';
import { CustomAudienceGroup } from '..';
import { FormikProvider, useFormik } from 'formik';
import { KDSPageNames } from 'constants/index';
import { Tag } from 'antd';
import { X } from '@phosphor-icons/react';
import { useUser } from 'reactfire';
import { validateCustomAudienceCsv } from '../../../helper/helper';
import * as Styled from './index.styles';

type formikVals = {
  audienceName: string;
  file: File | null;
};
interface Props {
  show: boolean;
  setShow: (bool: boolean) => void;
  selectedGroup: CustomAudienceGroup | null;
  reFetchAudiences: () => void;
  lalModelType: string;
}

const CreateCustomAudienceDialog = ({
  show,
  setShow,
  selectedGroup,
  reFetchAudiences,
  lalModelType,
}: Props) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const initialVals: formikVals = { audienceName: '', file: null };
  const { data: user } = useUser();
  const [loaded, setLoaded] = useState(0);
  const [fileSize, setFileSize] = useState(0);
  const [validationDetails, setValidationDetails] = useState(null);

  const handleClose = () => {
    formik.resetForm();
    setIsProcessing(false);
    setShow(false);
    setValidationDetails(null);
  };

  const formik = useFormik({
    initialValues: initialVals,
    onSubmit: async (values) => {
      handleSubmit(values);
    },
  });

  const handleFileSelected = (file: File) => {
    validateCustomAudienceCsv(file, setValidationDetails);
    formik.setFieldValue('file', file);
    setFileSize(file?.size || 0);
  };

  const handleSubmit = async (values: formikVals) => {
    try {
      setIsProcessing(true);
      if (values.file && values.audienceName) {
        const file = values.file;
        const audienceName = values.audienceName;
        const groupId = selectedGroup?.id.toString() || '';
        const email = user?.email || '';
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', audienceName);
        formData.append('email', email);
        formData.append('group_id', groupId);
        formData.append('lal_model_type', lalModelType);
        formData.append('enctype', 'multipart/form-data');
        const authorizedAxios = await getAuthorizedAxiosInstance();
        const config = {
          onUploadProgress: (progressEvent: ProgressEvent) => {
            setLoaded(progressEvent.loaded);
          },
        };
        if (authorizedAxios) {
          const res: AxiosResponse<any> = await authorizedAxios.post(
            `${API_BASE_ADDRESS}/${KDSPageNames.CUSTOMAUDIENCES}/audiences`,
            formData,
            config
          );
        }
        reFetchAudiences();
        handleClose();
        setValidationDetails(null);
      }
    } catch (e) {
      console.log('the error', e);
      handleClose();
      setValidationDetails(null);
    }
  };
  const isInHouseExport = lalModelType === 'attain';
  const { file, audienceName } = formik.values;
  const isError = audienceName?.length > 175 || false;
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      aria-label="create-custom-audience"
      aria-describedby="circular-process"
      aria-busy={isProcessing}
      onClose={handleClose}
      title="Create custom audience"
      open={show}
      headerID="uploadCustomAudienceGroupHeader"
      header={
        <Styled.CardHeader>
          <Styled.CardTitle>
            Create custom audience
            <Styled.ModelType>
              <Tag
                color={isInHouseExport ? 'gold' : 'geekblue'}
                style={{ marginLeft: 15 }}
              >
                {isInHouseExport ? IN_HOUSE_LABEL : TRANSUNION_LABEL}
              </Tag>
            </Styled.ModelType>
          </Styled.CardTitle>
        </Styled.CardHeader>
      }
      bodyID="createCustomAudienceGroupBody"
      body={
        <Styled.StyledBodyContainer
          container
          spacing={theme.spacing.lg}
          direction="column"
          style={{ padding: 20 }}
        >
          {isProcessing ? (
            <Grid container direction="column" rowGap={2}>
              <Grid item>
                <LinearProgress
                  variant="determinate"
                  value={(loaded * 100) / fileSize}
                />
              </Grid>
              <Grid item>{loaded < fileSize ? 'Uploading' : 'Processing'}</Grid>
            </Grid>
          ) : (
            <FormikProvider value={formik}>
              <Grid container direction="column" rowGap={2}>
                <Grid item>
                  <Field
                    name="audienceName"
                    label={
                      <span
                        style={{
                          fontFamily: 'Calibre-Regular,sans-serif',
                          fontSize: 16,
                        }}
                      >
                        Custom Audience Name
                      </span>
                    }
                    fullWidth
                    required
                    disabled={isProcessing}
                  ></Field>
                  {isError && (
                    <p
                      style={{
                        color: 'red',
                        fontSize: 13,
                        paddingTop: 2,
                        fontFamily: 'Calibre-Regular, sans-serif !important',
                      }}
                    >
                      {`Custom ${AUDIENCE_NAME_LENGTH_VALIDATION_MESSAGE}`}
                    </p>
                  )}
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    columnGap={1}
                    alignItems="flex-end"
                  >
                    <Grid item>
                      <Field
                        disabled={true}
                        value={
                          formik.values.file
                            ? formik.values.file.name
                            : 'No file selected'
                        }
                        name="file"
                        label={
                          <span
                            style={{
                              fontFamily: 'Calibre-Regular,sans-serif',
                              fontSize: 16,
                            }}
                          >
                            Custom audience Ids file
                          </span>
                        }
                      ></Field>
                    </Grid>
                    <Grid item>
                      {file ? (
                        <IconButton
                          onClick={() => {
                            formik.setFieldValue('file', null);
                            setValidationDetails(null);
                          }}
                        >
                          <X size={22} />
                        </IconButton>
                      ) : (
                        <FileInput
                          setFile={handleFileSelected}
                          isDisabled={isProcessing}
                        />
                      )}
                    </Grid>
                  </Grid>
                  {validationDetails !== null && !validationDetails?.valid && (
                    <Grid item style={{ marginTop: 16 }}>
                      <Alert variant="outlined" severity="error">
                        {validationDetails?.message}
                      </Alert>
                    </Grid>
                  )}
                </Grid>
                <Grid item>
                  <Alert severity="warning">
                    Please remember to remove header before uploading.
                  </Alert>
                </Grid>
              </Grid>
            </FormikProvider>
          )}
        </Styled.StyledBodyContainer>
      }
      footer={
        <>
          <span>
            <Button
              variant="contained"
              type="submit"
              onClick={formik.handleSubmit}
              disabled={
                isProcessing ||
                !file ||
                !audienceName ||
                isError ||
                (validationDetails && !validationDetails?.valid)
              }
            >
              {isProcessing ? (
                <>
                  <CircularProgress size={25} id="circular-process" />
                </>
              ) : (
                'Upload'
              )}
            </Button>
          </span>
          <Button
            onClick={handleClose}
            variant="outlined"
            disabled={isProcessing}
          >
            Cancel
          </Button>
        </>
      }
    />
  );
};

export default CreateCustomAudienceDialog;
