import React, { useEffect, useMemo, useState } from 'react';
import compareAudiences from 'utils/compareAudiences';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
} from '@klover/attain-design-system';
import {
  ToggleDuplicateAudienceDialogAction,
  ToggleExportDialogAction,
  createAudience,
  fetchAudience,
  query,
  queryTotalAudienceSize,
  saveAudience,
  toggleDraftStateDialog,
  toggleDuplicateAudienceDialog,
  toggleExportDialog,
  updateCurrentAudience,
} from '../slice';
import { buildAudienceObject } from '../sagas/audiences';
import { nanoid } from 'nanoid';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useUser } from 'reactfire';
// Material UI Components
import Box from '@material-ui/core/Box';
import ChangeLogDialog from '../../../components/ChangeLogDialog';
import Chip from '@mui/material/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  ArrowsClockwise,
  ClockCounterClockwise,
  CopySimple,
  DotsThreeVertical,
  Export,
  FloppyDiskBack,
  ListMagnifyingGlass,
  PencilSimple,
  TrashSimple,
  X,
} from '@phosphor-icons/react';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

// Components
import AudienceDeleteDialog from '../audienceDeleteDialog';
import BootstrapTooltip from 'components/bootstrapTooltip';
import ExportHistroryDialog from '../exportHistoryDialog';
import tokens from 'tokens';
import {
  AUDIENCE_NAME_LENGTH_VALIDATION_MESSAGE,
  CHANGE_LOG,
} from '../../../content';
import {
  REFRESH_AUDIENCE,
  REFRESH_AUDIENCE_DISABLE_MESSAGE,
  SYSTEM_NAME,
} from '../../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    audienceName: {
      fontFamily: 'Calibre-Semibold, sans-serif',
      fontSize: 24,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: 400,
      marginRight: 10,
    },
    groupName: {
      fontFamily: 'Calibre-Regular,sans-serif',
      fontSize: 16,
    },
    editAudienceName: {
      fontFamily: 'Calibre-Regular,sans-serif',
      width: 400,
    },
    button: {
      margin: `${theme.spacing(0, 1)} !important`,
    },
    exportSpinner: {
      margin: '0.15em 1.2em',
    },
    editButtonBox: {
      marginLeft: theme.spacing(4),
    },
    form: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    menuItem: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 5,
    },
    audienceNameContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2px',
      marginTop: 1,
    },
    saveBtn: {
      color: 'red',
      fontSize: '13px',
    },
  })
);

const inputs = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        maxHeight: 48,
      },
    },
    MuiFormLabel: {
      root: {
        maxHeight: 48,
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: -5,

        '&$focused': {
          marginTop: 0,
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: 48,
      },
      input: {
        borderRadius: 10,
        alignSelf: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
      input: {
        padding: '12px 14px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 10,
      },
    },
  },
});

interface AudienceNameProps {
  isAudienceLoading: boolean;
  audienceid: number;
}

// we need to know when audience is loading to determine if we should disable some text fields and buttons
const AudienceName: React.FC<AudienceNameProps> = ({
  isAudienceLoading,
  audienceid,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState('');
  const [notChanging, setNotChanging] = useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openExportHistoryDialog, setOpenExportHistoryDialog] = useState(false);
  const [openChangeLogDialog, setOpenChangeLogDialog] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { data: user } = useUser();
  const currentUser = user || { email: '' };

  const entitiesById = useAppSelector((state) => {
    return state.audienceReducer.entities.audiences.byId;
  });

  const currentGroup = useAppSelector((state) => {
    return state.audienceReducer.currentGroup;
  });

  const currentAudience = useAppSelector((state) => {
    return state.audienceReducer.currentAudience;
  });

  const lalModelType = currentAudience?.lalModelType;
  const isSaveDisabled = currentAudience ? currentAudience.name === '' : true;
  const isExportDisabled = currentAudience?.dimensions
    ? currentAudience.dimensions.length === 0
    : true;
  const openAudienceAction = Boolean(anchorEl);

  const currentDimensions = useAppSelector((state) => {
    return state.audienceReducer.currentDimensions;
  });

  const currentDimensionGroups = useAppSelector((state) => {
    return state.audienceReducer.currentDimensionGroups;
  });

  const currentConditions = useAppSelector((state) => {
    return state.audienceReducer.currentConditions;
  });

  const originalAudience = useAppSelector((state) => {
    return state.audienceReducer.originalAudience;
  });

  const isError = name?.length > 175 || false;

  useEffect(() => {
    setName(currentAudience?.name || '');
    const isNameBlank = currentAudience?.name
      ? currentAudience.name === ''
      : true;
    setEditMode(isNameBlank);
  }, [currentAudience]);

  const exportHandler = () => {
    const actionProps: ToggleExportDialogAction = {
      open: true,
    };
    dispatch(toggleExportDialog(actionProps));
  };
  // added error handling
  const groupId = currentGroup
    ? currentGroup.id
    : originalAudience
    ? originalAudience.group_id
    : null;

  const updatedAudience = {
    ...currentAudience,
    updatedAt: Date.now(),
  };

  const audienceObject = buildAudienceObject({
    updatedAudience,
    currentDimensions,
    currentDimensionGroups,
    currentConditions,
  });

  const isTheSame = compareAudiences(originalAudience, audienceObject);

  const duplicateHandler = () => {
    const actionProps: ToggleDuplicateAudienceDialogAction = {
      open: true,
    };

    if (originalAudience) {
      if (isTheSame) {
        dispatch(toggleDuplicateAudienceDialog(actionProps));
      } else {
        dispatch(toggleDraftStateDialog({ open: true, duplicate: true }));
      }
    } else {
      dispatch(toggleDuplicateAudienceDialog(actionProps));
    }
  };

  const handleCancel = () => {
    setEditMode(false);
    if (currentAudience) {
      setName(currentAudience?.name);
    }
  };

  const handleEditName = () => {
    setEditMode(true);
  };

  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const name = event.target.value as string;
    setName(name);
  };

  const handleSave = () => {
    if (currentAudience) {
      const updatedAudience = {
        ...currentAudience,
        name: name,
        updatedAt: Date.now(),
        groupId: groupId,
        updated_by_email: currentUser.email,
        created_by_email: currentUser.email,
        lalModelType: lalModelType,
      };

      const normalizedObjects = {
        updatedAudience,
        currentDimensions,
        currentDimensionGroups,
        currentConditions,
      };

      const audienceExists = entitiesById[currentAudience.id];
      if (audienceExists) {
        dispatch(saveAudience(normalizedObjects));
      } else {
        dispatch(createAudience(normalizedObjects));
      }

      // TODO: move this to audiences saga
      dispatch(updateCurrentAudience(updatedAudience));
    }
  };

  const handleRefreshAudience = async () => {
    const updatedAudience = {
      ...currentAudience,
      name: name,
      updatedAt: Date.now(),
      groupId: groupId,
      updated_by_email: currentUser.email,
      created_by_email: currentUser.email,
      lalModelType: lalModelType,
    };

    const normalizedObjects = {
      updatedAudience,
      currentDimensions,
      currentDimensionGroups,
      currentConditions,
    };

    dispatch(queryTotalAudienceSize(normalizedObjects));
  };

  return (
    <>
      <ThemeProvider theme={inputs}>
        {editMode ? (
          <Box className={classes.form}>
            <div className={classes.audienceNameContainer}>
              <TextField
                disabled={isAudienceLoading}
                key={currentAudience?.name && notChanging ? nanoid() : null} // setting the key to the audience name to force confimation of render so that there is no overlap between audience name and label.
                onFocus={() => setNotChanging(false)} // but on focus we want to switch off the forced render
                id="audience-name"
                label="Audience Name"
                onChange={handleNameChange}
                defaultValue={currentAudience?.name}
                value={name}
                variant="outlined"
                style={{ marginRight: 8 }}
                className={classes.editAudienceName}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <BootstrapTooltip title={'Save Audience'}>
                        <span>
                          <IconButton
                            color="default"
                            disabled={
                              name.length === 0 || isError || isAudienceLoading
                            }
                            onClick={handleSave}
                            size="small"
                          >
                            <FloppyDiskBack size={24} />
                          </IconButton>
                        </span>
                      </BootstrapTooltip>
                    </InputAdornment>
                  ),
                }}
              />
              {isError && (
                <span className={classes.saveBtn}>
                  {AUDIENCE_NAME_LENGTH_VALIDATION_MESSAGE}
                </span>
              )}
            </div>

            <BootstrapTooltip title={'Exit Save Audience'}>
              <span>
                <IconButton disabled={isAudienceLoading} onClick={handleCancel}>
                  <X size={24} />
                </IconButton>
              </span>
            </BootstrapTooltip>
          </Box>
        ) : (
          <>
            <Box flexDirection="column">
              <BootstrapTooltip title={currentAudience?.name}>
                <Typography
                  className={classes.audienceName}
                  component="h2"
                  variant="h6"
                >
                  {currentAudience?.name}
                </Typography>
              </BootstrapTooltip>

              <Typography
                component="h3"
                variant="body2"
                className={classes.groupName}
              >
                {currentGroup?.name}
              </Typography>
            </Box>
            <BootstrapTooltip
              title={
                isAudienceLoading
                  ? 'Wait for Audience Load'
                  : 'Edit Audience Name'
              }
            >
              <IconButton
                color="default"
                disabled={isAudienceLoading}
                onClick={handleEditName}
              >
                <PencilSimple size={24} />
              </IconButton>
            </BootstrapTooltip>
            <BootstrapTooltip
              title={
                isAudienceLoading ? 'Wait for Audience Load' : 'Delete Audience'
              }
            >
              <IconButton
                color="default"
                disabled={isAudienceLoading}
                onClick={() => setOpenDeleteDialog(true)}
              >
                <TrashSimple size={24} />
              </IconButton>
            </BootstrapTooltip>
            <Button
              className={classes.button}
              disabled={isSaveDisabled || isAudienceLoading}
              disableElevation
              onClick={handleSave}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
            <BootstrapTooltip title="Actions" placement="top">
              <span aria-describedby="audience-action-btn">
                <IconButton
                  color="default"
                  disabled={isAudienceLoading}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  <DotsThreeVertical size={24} weight="bold" />
                </IconButton>

                <Menu
                  id="audience-action-btn"
                  open={openAudienceAction}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  onBlur={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  <MenuItem
                    onClick={handleRefreshAudience}
                    disabled={isAudienceLoading || !isTheSame}
                  >
                    <span
                      title={
                        !isTheSame
                          ? REFRESH_AUDIENCE_DISABLE_MESSAGE
                          : REFRESH_AUDIENCE
                      }
                      style={{ pointerEvents: 'auto' }}
                      className={classes.menuItem}
                    >
                      <ArrowsClockwise size={22} />
                      Refresh Audience size
                    </span>
                  </MenuItem>
                  <MenuItem onClick={duplicateHandler} disabled={!isTheSame}>
                    <span
                      title={!isTheSame ? 'Save to Duplicate' : ''}
                      className={classes.menuItem}
                    >
                      <CopySimple size={22} />
                      Duplicate
                    </span>
                  </MenuItem>
                  {lalModelType !== 'attain' && (
                    <MenuItem
                      onClick={exportHandler}
                      disabled={isExportDisabled || !isTheSame}
                    >
                      <span
                        title={
                          isExportDisabled
                            ? 'Add a Dimension'
                            : !isTheSame
                            ? 'Save to Export'
                            : ''
                        }
                        className={classes.menuItem}
                      >
                        <Export size={22} />
                        Export
                      </span>
                    </MenuItem>
                  )}
                  <MenuItem onClick={() => setOpenExportHistoryDialog(true)}>
                    <span
                      title="Show Audience History"
                      className={classes.menuItem}
                    >
                      <ClockCounterClockwise size={22} />
                      Audience History
                    </span>
                  </MenuItem>
                  <MenuItem onClick={() => setOpenChangeLogDialog(true)}>
                    <span
                      title="Show Audience Change Log"
                      className={classes.menuItem}
                    >
                      <ListMagnifyingGlass size={22} />
                      {CHANGE_LOG}
                    </span>
                  </MenuItem>
                </Menu>
              </span>
            </BootstrapTooltip>
          </>
        )}
      </ThemeProvider>
      <AudienceDeleteDialog
        isOpen={openDeleteDialog}
        setOpenDialog={setOpenDeleteDialog}
        user={currentUser.email}
      />
      <ExportHistroryDialog
        isOpen={openExportHistoryDialog}
        setOpenDialog={setOpenExportHistoryDialog}
        data={currentAudience?.exportHistory || []}
        normalAudience={currentAudience}
      />
      {openChangeLogDialog && (
        <ChangeLogDialog
          isOpen={openChangeLogDialog}
          setOpenDialog={setOpenChangeLogDialog}
          entityId={audienceid}
          systemName={SYSTEM_NAME.audience_manager}
        />
      )}
    </>
  );
};

interface NameSaveProps {
  isDisabled: boolean;
}

const NameSaveIcon = ({ isDisabled }: NameSaveProps) => {
  return (
    <>
      {isDisabled ? (
        <CircularProgress color="primary" size={20} />
      ) : (
        <SaveIcon />
      )}
    </>
  );
};

export default AudienceName;
