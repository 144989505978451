import AudienceName from 'pages/customAudiences/audienceName';
import Box from '@material-ui/core/Box';
import CreateCustomAudience from '../createCustomAudience';
import Divider from '@material-ui/core/Divider';
import ExportYourAudience from '../../../components/AudienceExport/ExportYourAudience';
import GhostLoader from 'react-ghost-loader';
import InHouseModelStepper from '../../../components/AudienceExport/InHouseModelStepper';
import ModelAudienceButton from 'components/AudienceExport/ModelAudienceButton';
import React, { useEffect, useMemo, useState } from 'react';
import TotalAudienceSize from '../totalAudienceSize';
import axios from 'axios';
import tokens from 'tokens';
import { API_BASE_ADDRESS } from '../variables';
import { ArrowLeft } from '@phosphor-icons/react';
import { ENUM_SYSTEM_USED, LoadingStates } from '../../../constants';
import {
  EST_AUDIENCE_SIZE_MODELED_VALIDATION_MESSAGE,
  EST_AUDIENCE_SIZE_MODELED_WARNING_MESSAGE,
  YOUR_AUDIENCE_FAILED_TO_GET_MODELED,
  YOUR_AUDIENCE_IS_BEING_MODELED,
} from '../../../content';
import { Grid, IconButton, theme } from '@klover/attain-design-system';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  addNotification,
  clearCurrentCustomAudience,
  fetchCustomAudience,
} from '../slice';
import { groupModeledAudienceCountByIdType } from '../../../helper/helper';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory, useParams } from 'react-router-dom';
import { useUser } from 'reactfire';
import * as Styled from './index.styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    draftIndicatorAndTotalAudience: {
      display: 'flex',
    },
    draftIndicator: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: 10,
    },
    noAudiences: {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    root: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 12,
      boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.07)',
      zIndex: 1,
    },
    dimensions: {
      margin: theme.spacing(2),
    },
    noDimensions: {
      minHeight: '70vh',
    },
    modeledButtonContainer: {
      position: 'fixed',
      right: 0,
      top: 0,
      paddingTop: '0.3em',
      paddingRight: '1.2em',
    },
    countButton: {
      cursor: 'pointer',
      height: 16,
      fontSize: 10,
      fontWeight: 'bold',
      border: '1px solid ' + tokens.colorButtonPrimary,
      borderRight: 'none',
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      borderWidth: 'thin',
      outline: 'none',
      backgroundColor: tokens.colorButtonDefault,
      color: tokens.colorButtonPrimary,
      opacity: 1,
      '&:hover': {
        opacity: 0.8,
      },
    },
    modeledButton: {
      cursor: 'pointer',
      height: 16,
      fontSize: 10,
      fontWeight: 'bold',
      border: '1px solid ' + tokens.colorButtonPrimary,
      borderLeft: 'none',
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      borderWidth: 'thin',
      outline: 'none',
      borderColor: tokens.colorButtonPrimary,
      backgroundColor: tokens.colorButtonDefault,
      color: tokens.colorButtonPrimary,
      opacity: 1,
      '&:hover': {
        opacity: 0.8,
      },
    },
    selectedButton: {
      backgroundColor: tokens.colorButtonPrimary,
      color: tokens.colorButtonDefault,
    },
  })
);

const CustomAudienceEditor = () => {
  const classes = useStyles();
  // const dispatch = useAppDispatch();
  const history = useHistory();
  const { audienceid } = useParams();
  const [isModeling, setIsModeling] = useState(false);
  const { data: user } = useUser();
  const currentUser = user || { email: '' };
  const [name, setName] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [lalModelPrecision, setLalModelPrecision] = useState(1);
  const [isModelingSkipped, setIsModelingSkipped] = useState(true);
  const dispatch = useAppDispatch();

  const audienceLoading = useAppSelector(
    (state) => state.audienceReducer.ui.audienceLoading
  );

  const currentAudience = useAppSelector(
    (state) => state.customAudienceReducer.currentAudience
  );

  const currentGroup = useAppSelector((state) => {
    return state.customAudienceReducer.currentGroup;
  });

  const lalModelType = currentAudience?.lal_model_type;
  const isAudienceLoading = audienceLoading?.state === 'loading';
  const lalModelStatus = currentAudience?.lalModelStatus;
  const lalModelCompletedAt = currentAudience?.lalModelCompletedAt;

  useEffect(() => {
    if (audienceid) {
      dispatch(
        fetchCustomAudience({
          id: audienceid,
        })
      );
    }
  }, []);

  const handleClearAudience = (e) => {
    dispatch(clearCurrentCustomAudience());

    history.push(
      `/main/custom-audience-manager?groupId=${currentGroup?.id || 'all'}`
    );
  };

  const modelYourAudience = async () => {
    const totalAudienceSize = currentAudience?.size || 0;
    if (totalAudienceSize <= 300) {
      dispatch(
        addNotification({
          state: LoadingStates.ERROR,
          message: EST_AUDIENCE_SIZE_MODELED_VALIDATION_MESSAGE,
        })
      );
      return;
    }
    if (totalAudienceSize > 300 && totalAudienceSize < 2000) {
      dispatch(
        addNotification({
          state: LoadingStates.WARNING,
          message: EST_AUDIENCE_SIZE_MODELED_WARNING_MESSAGE,
        })
      );
    }
    const payload = [
      {
        id: currentAudience.id,
        name: currentAudience.name,
        emails: currentUser.email,
        exportType: 'in_house_lal_model',
        lalModelType: 'attain',
        audienceType: ENUM_SYSTEM_USED.CUSTOM_AUDIENCE,
      },
    ];
    setIsModeling(true);
    axios
      .post(
        `${API_BASE_ADDRESS}/custom-audience-manager/export/transunion`,
        payload
      )
      .then(() => {
        dispatch(
          addNotification({
            state: 'done',
            message: YOUR_AUDIENCE_IS_BEING_MODELED,
          })
        );
      })
      .catch(() => {
        setIsModeling(false);
        dispatch(
          addNotification({
            state: 'error',
            message: YOUR_AUDIENCE_FAILED_TO_GET_MODELED,
          })
        );
      });
  };

  const audienceCountTotals = useMemo(() => {
    const totals = {};
    const groupedAudienceCount = groupModeledAudienceCountByIdType(
      currentAudience?.lalAudienceCount
    );
    Object.values(groupedAudienceCount).forEach((idTypeArray: any) => {
      idTypeArray.forEach(({ percentileBreakpoint, audienceCount }) => {
        if (!totals[percentileBreakpoint]) {
          totals[percentileBreakpoint] = 0;
        }
        totals[percentileBreakpoint] += audienceCount;
      });
    });
    return totals;
  }, [currentAudience?.lalAudienceCount]);

  const isCustomAudienceCreated = currentAudience?.filename ? true : false;

  return (
    <>
      <>
        <Grid item container className={classes.root}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <IconButton
              color="default"
              disabled={isAudienceLoading}
              onClick={handleClearAudience}
            >
              <ArrowLeft size={theme.icons.lg} />
            </IconButton>
            <div style={{ marginLeft: 8 }} />
            <AudienceName
              isAudienceLoading={isAudienceLoading}
              isCustomAudienceCreated={isCustomAudienceCreated}
              audienceid={audienceid}
              name={name}
              setName={setName}
            />
          </div>
          <Grid className={classes.draftIndicatorAndTotalAudience}>
            <TotalAudienceSize
              lalModelType={lalModelType}
              lalModelStatus={lalModelStatus}
              isModeling={isModeling}
              lalModelPrecision={lalModelPrecision}
              audienceCountTotals={audienceCountTotals}
            />
          </Grid>
        </Grid>
        {/* Top Divider */}
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </>

      {isAudienceLoading ? (
        <Box className={classes.noDimensions}>
          <LoadingAudience />
        </Box>
      ) : (
        <>
          {activeStep === 0 && (
            <CreateCustomAudience
              audienceId={currentAudience?.id}
              fileName={currentAudience?.filename}
              selectedGroupId={currentAudience.groupId}
              currentUserEmail={currentAudience?.email}
              lalModelType={lalModelType}
              name={name}
            />
          )}
          {activeStep === 1 && (
            <ModelAudienceButton
              modelYourAudience={modelYourAudience}
              isModeling={isModeling}
              lalModelStatus={lalModelStatus}
              lalModelCompletedAt={lalModelCompletedAt}
              isModelingSkipped={isModelingSkipped}
              setIsModelingSkipped={setIsModelingSkipped}
            />
          )}
          {activeStep === 2 && (
            <ExportYourAudience
              currentAudience={currentAudience}
              lalModelPrecision={lalModelPrecision}
              setLalModelPrecision={setLalModelPrecision}
              audienceCountTotals={audienceCountTotals}
              audienceType={ENUM_SYSTEM_USED.CUSTOM_AUDIENCE}
              isModelingSkipped={isModelingSkipped}
            />
          )}
        </>
      )}

      {lalModelType === 'attain' && (
        <InHouseModelStepper
          dimensionCount={0}
          activeStep={activeStep}
          setActiveStep={(step) => {
            setActiveStep(step);
          }}
          lalModelStatus={lalModelStatus}
          audienceName={currentAudience.name}
          audienceType={ENUM_SYSTEM_USED.CUSTOM_AUDIENCE}
          isCustomAudienceCreated={isCustomAudienceCreated}
          isModelingSkipped={isModelingSkipped}
        />
      )}
    </>
  );
};

export const LoadingAudience = () => {
  return (
    <Styled.Wrapper>
      <Styled.CardBody>
        <GhostLoader
          height="42px"
          heightRandom={0}
          width="42px"
          widthRandom={0}
        />

        <GhostLoader
          height="42px"
          heightRandom={0}
          width="100%"
          widthRandom={0}
        />
      </Styled.CardBody>
      <Styled.CardBody>
        <GhostLoader
          height="70px"
          heightRandom={0}
          width="10%"
          widthRandom={0}
        />
        <GhostLoader
          height="70px"
          heightRandom={0}
          width="80%"
          widthRandom={0}
        />
        <GhostLoader
          height="70px"
          heightRandom={0}
          width="10%"
          widthRandom={0}
        />
      </Styled.CardBody>
      <Styled.CardBody>
        <GhostLoader
          height="42px"
          heightRandom={0}
          width="10%"
          widthRandom={0}
        />
        <GhostLoader
          height="42px"
          heightRandom={0}
          width="10%"
          widthRandom={0}
        />
        <GhostLoader
          height="42px"
          heightRandom={0}
          width="70%"
          widthRandom={0}
        />
        <GhostLoader
          height="42px"
          heightRandom={0}
          width="10%"
          widthRandom={0}
        />
      </Styled.CardBody>
    </Styled.Wrapper>
  );
};

export default CustomAudienceEditor;
