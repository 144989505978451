import { Devices } from './devices';
import { KloverPointsActions } from './kloverPointsConversionEvents';
import { OfferCategories } from './offerGoals';
import { PlacementId } from './placementId';
import { PricingModels } from './pricingModels';
import { RevenueConversionEvents } from './revenueConversionEvents';
import { TimeRangeofConversion } from './conversionCap';

const allOptions = {
  actionforpoints: KloverPointsActions,
  devices: Devices,
  placementid: PlacementId,
  pricingmodel: PricingModels,
  revenueconversionevent: RevenueConversionEvents,
  conversiontimerange: TimeRangeofConversion,
  category: OfferCategories,
};

export default allOptions;
