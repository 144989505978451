import Field from 'components/Field';
import GhostLoader from 'react-ghost-loader';
import Stack from 'components/Stack';
import _, { debounce } from 'lodash';
import _debounce from 'lodash/debounce';
import getAuthorizedAxiosInstance from 'utils/getAuthorizedAxiosInstance';
import tokens from 'tokens';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import { AudienceList } from '../createQuickQuestion';
import { AudienceTypes, SaveStatus } from 'pages/variables';
import {
  Button,
  Dialog,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@klover/attain-design-system';
import { CircularProgress } from '@material-ui/core';
import { FormikProvider, useFormik } from 'formik';
import { MinusCircle } from '@phosphor-icons/react';
import { QuickQuestionContext } from 'context';
import { UseState } from 'interfaces';
import { nanoid } from 'nanoid';
import { useContext, useEffect, useState } from 'react';
import { useUser } from 'reactfire';
import * as Grid from 'components/Grid';
import * as Styled from './index.styles';

const EditTargeting = ({
  questionid,
  setOriginalTargeting,
  type,
  audiencesList,
}: {
  questionid: string;
  setOriginalTargeting?: UseState;
}) => {
  const { setShowEditTargeting } = useContext(QuickQuestionContext);
  const { AUDIENCE } = AudienceTypes;
  const { NEW, UPDATE } = SaveStatus;

  const [audiences, setAudiences] = useState([]);
  const [audienceType, setAudienceType] = useState(type || AUDIENCE);
  const [searchAudiences, setSearchAudiences] = useState('');
  const filteredAudiences = audiences;
  const [saving, setSaving] = useState(false);
  const [audiencesLoading, setAudiencesLoading] = useState(false);
  const [selectedAudiences, setSelectedAudiences] = useState(
    audiencesList && audiencesList.length > 0 ? audiencesList[0] : null
  );

  const { data } = useUser();
  const handleSearchChange = (
    audienceName: string,
    originalAudienceType: string
  ) => {
    const apiEndPoint =
      originalAudienceType === AUDIENCE
        ? 'audience-manager'
        : 'custom-audience-manager';
    getAudiences(apiEndPoint, audienceName);
  };

  const debouncedSearch = _debounce((audienceName, audienceType) => {
    handleSearchChange(audienceName, audienceType);
  }, 500);

  useEffect(() => {
    debouncedSearch(searchAudiences, audienceType);
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchAudiences, audienceType]);

  const getAudiences = async (apiEndPoint: string, audienceName: string) => {
    try {
      if (audienceName === '') {
        setAudiences([]);
        return;
      }
      setAudiencesLoading(true);
      const axiosInstance = await getAuthorizedAxiosInstance();
      if (axiosInstance) {
        const res = await axiosInstance.get(
          `${API_BASE_ADDRESS}/${apiEndPoint}/audiences/minimal?search=${audienceName}`
        );
        setAudiences(res.data);
        setAudiencesLoading(false);
      } else {
        throw new Error('Failed to authorize request');
      }
    } catch (e) {
      console.log('Error', e);
      setAudiencesLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      setSaving(true);
      const audienceMapStatus =
        audiencesList.length === 0 && audiences?.length >= 0 ? NEW : UPDATE;
      try {
        const axiosInstance = await getAuthorizedAxiosInstance();
        if (axiosInstance) {
          await axiosInstance.put(
            `${API_BASE_ADDRESS}/questions/${questionid}/targeting`,
            {
              audiences: selectedAudiences ? [selectedAudiences?.id] : [],
              user: data?.email,
              audienceName: selectedAudiences ? selectedAudiences?.name : '',
              type: audienceType,
              audienceMapStatus,
            }
          );
          if (setOriginalTargeting) {
            setOriginalTargeting(
              audiences.filter(({ id }) => selectedAudiences[id])
            );
          }
        } else {
          throw new Error('Failed to authorize request');
        }
      } catch (e) {
        console.log('Error from Editing Targeting', e);
      }
      setSaving(false);
      setShowEditTargeting(false);
    },
  });

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={() => {
        formik.resetForm();
        setShowEditTargeting(false);
      }}
      title="v"
      open
      headerID="editQuickQuestionTragetingHeader"
      header={
        <Styled.CardHeader>
          <Styled.CardTitle>Edit Quick Question Targeting</Styled.CardTitle>
        </Styled.CardHeader>
      }
      bodyID="editQuickQuestionTragetingBody"
      body={
        <FormikProvider value={formik}>
          <Grid.Container>
            <Stack spacing={tokens.spacing.md}>
              <Grid.Row>
                <Grid.Col>
                  <label htmlFor="audienceType" hidden>
                    Audience Type
                  </label>
                  <Select
                    name="audienceType"
                    label="Audience Type"
                    value={audienceType}
                    onChange={(e: any) => {
                      setAudienceType(e.target.value);
                      setSelectedAudiences(null);
                    }}
                  >
                    <MenuItem value="AUDIENCE">Audience Manager</MenuItem>
                    <MenuItem value="CUSTOM">Custom Audience</MenuItem>
                  </Select>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  {audiencesLoading ? (
                    <Styled.LoadingWrapper>
                      {[...Array(2)].map(() => (
                        <>
                          <Styled.LoadingBody>
                            <GhostLoader
                              height="50px"
                              heightRandom={0}
                              width="5%"
                              widthRandom={0}
                            />
                            <GhostLoader
                              height="50px"
                              heightRandom={0}
                              width="95%"
                              widthRandom={0}
                            />
                          </Styled.LoadingBody>
                        </>
                      ))}
                    </Styled.LoadingWrapper>
                  ) : (
                    <>
                      {selectedAudiences ? (
                        <>
                          <Styled.AudienceNameContainer key={nanoid()}>
                            <Styled.AudienceName>
                              {`${selectedAudiences?.name} - (ID: ${selectedAudiences?.id})`}
                            </Styled.AudienceName>
                            <div>
                              <IconButton
                                size="small"
                                onClick={() => setSelectedAudiences(null)}
                              >
                                <MinusCircle size={32} />
                              </IconButton>
                            </div>
                          </Styled.AudienceNameContainer>
                        </>
                      ) : (
                        <>
                          <label htmlFor="audiences" hidden>
                            Audiences
                          </label>
                          <span style={{ fontWeight: '700' }}>
                            Attach Audiences
                          </span>
                          <TextField
                            hiddenLabel
                            placeholder={'Search by audience name'}
                            variant="outlined"
                            style={{ width: '100%', marginTop: 8 }}
                            value={searchAudiences}
                            onChange={(e) => setSearchAudiences(e.target.value)}
                          />
                          <Styled.RadioSelectionSpace>
                            {!audiencesLoading ? (
                              <Stack spacing={tokens.spacing.sm}>
                                <AudienceList
                                  audienceType={audienceType}
                                  filteredAudiences={filteredAudiences}
                                  selectedAudiences={selectedAudiences}
                                  setSelectedAudiences={setSelectedAudiences}
                                />
                              </Stack>
                            ) : (
                              <Styled.LoadingWrapper>
                                {[...Array(2)].map(() => (
                                  <>
                                    <Styled.LoadingBody>
                                      <GhostLoader
                                        height="50px"
                                        heightRandom={0}
                                        width="5%"
                                        widthRandom={0}
                                      />
                                      <GhostLoader
                                        height="50px"
                                        heightRandom={0}
                                        width="95%"
                                        widthRandom={0}
                                      />
                                    </Styled.LoadingBody>
                                  </>
                                ))}
                              </Styled.LoadingWrapper>
                            )}
                          </Styled.RadioSelectionSpace>
                        </>
                      )}
                    </>
                  )}
                </Grid.Col>
              </Grid.Row>
            </Stack>
          </Grid.Container>
        </FormikProvider>
      }
      footer={
        <>
          <span>
            <Button
              variant="contained"
              type="submit"
              onClick={formik.handleSubmit}
            >
              {saving ? <CircularProgress size={20} /> : 'Save'}
            </Button>
          </span>
          <Button
            variant="outlined"
            onClick={() => {
              formik.resetForm();
              setShowEditTargeting(false);
            }}
          >
            Cancel
          </Button>
        </>
      }
    />
  );
};

export default EditTargeting;
