import axios from 'axios';
import { API_BASE_ADDRESS } from './variables';
import {
  Audience,
  addNotification,
  fetchCustomAudience,
  saveCustomAudience,
  setAudienceLoadingState,
  updateCurrentCustomAudience,
} from './slice';
import { call, put, takeLatest } from 'redux-saga/effects';

import _ from 'lodash';
import camelizeKeys from 'utils/camelizeKeys';
import { LoadingStates } from '../../constants';
import { PayloadAction } from '@reduxjs/toolkit';
import { auth } from 'utils/firebase';

const API_ENDPOINT = `${API_BASE_ADDRESS}/custom-audience-manager/audiences`;

function handleFetchAudience(audience: any) {
  return auth?.currentUser?.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.get(`${API_ENDPOINT}/${audience.id}`);
  });
}

function handleUpdateAudience(normalizedObjects: any) {
  const putData = normalizedObjects;
  const audienceId = putData?.id;
  return auth?.currentUser?.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.put(
      `${API_ENDPOINT}/${audienceId}`,
      _.omit(putData, 'id')
    );
  });
}

function* watchFetchAudience(action: PayloadAction<Audience>) {
  try {
    yield put(setAudienceLoadingState({ state: LoadingStates.LOADING }));
    const result = yield call(handleFetchAudience, action.payload);
    let data = _.omit(result.data, 'export_history');
    if (result.data.export_history) {
      let exportHistory = [];
      if (result.data.export_history.length > 0) {
        exportHistory = camelizeKeys(result.data.export_history);
      }
      data = {
        ...data,
        exportHistory: exportHistory,
      };
    }

    if (result.data.lalAudienceCount) {
      let lalAudienceCount = [];
      if (result.data.lalAudienceCount.length > 0) {
        lalAudienceCount = camelizeKeys(result.data.lalAudienceCount);
      }
      data = {
        ...data,
        lalAudienceCount: lalAudienceCount,
      };
    }

    data = {
      ...data,
      lalModelType: result.data.lal_model_type,
      createdAt: result.data.createdat,
      createdByEmail: result.data.email,
    };
    yield put(updateCurrentCustomAudience(data));
    yield put(setAudienceLoadingState({ state: LoadingStates.DONE }));
  } catch (e) {
    // setting loading state to finished
    yield put(setAudienceLoadingState({ state: LoadingStates.ERROR }));
    // setting a notification for failure
    yield put(
      addNotification({
        state: LoadingStates.ERROR,
        message: `Failed to Retrieve Audience ${action.payload.name}`,
      })
    );
  }
}

function* watchUpdateCustomAudience(action: any) {
  const payload = action.payload?.payload || action.payload;
  try {
    yield put(setAudienceLoadingState({ state: LoadingStates.LOADING }));
    yield call(handleUpdateAudience, {
      id: payload?.id,
      audienceName: payload?.name,
      updated_by_email: payload.updated_by_email,
    });
    yield put(setAudienceLoadingState({ state: LoadingStates.DONE }));
    // adding a notification for success
    yield put(
      addNotification({
        state: LoadingStates.DONE,
        message: `Successfully Updated Audience ${payload.name}`,
      })
    );
  } catch (e) {
    // setting loading state to done
    yield put(setAudienceLoadingState({ state: LoadingStates.ERROR }));
    // setting loading state to finished
    yield put(
      addNotification({
        state: LoadingStates.ERROR,
        message: `Failed to Update Audience ${payload.name}`,
      })
    );
  }
}

export default function* watchAll() {
  yield takeLatest<any>(fetchCustomAudience.type, watchFetchAudience);
  yield takeLatest<any>(saveCustomAudience.type, watchUpdateCustomAudience);
}
